import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import Modal from '../../../../components/Modal/Modal';
import { addFreshPhoneNumbers } from '../../../../actions/didsActions';
import { addDidsToTrunk, fetchOneProductInstance } from '../../../../actions/productActions';
import { addNotif } from '../../../../actions/notifActions';
import phone from '../../../../lib/phone';

const AddDMSNumberModal = createReactClass({
  propTypes: {
    trunkId: PropTypes.string,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
    subscriptionNumber: PropTypes.string,
    productId: PropTypes.string,
  },
  getInitialState() {
    return {
      processing: false,
      processingText: '',
      bulkMode: false,

      number: '', // non-bulk mode only
      numbers: [], // bulk mode only
      unprocessedNumbers: '', // bulk mode only
      e911Enabled: 'no',
      origin: 'Unknown',
      provider: 'UNKNOWN',
      reason: 'Trunk reconciliation',
      capabilities: {
        e911: 'no',
        cnam: 'no',
        sms: 'no',
      },
    };
  },
  onPropChange(propName) {
    return (event) => {
      const newState = { ...this.state };
      let newValue = event.target.value;
      if (propName === 'number') {
        // allow pasting phone number with formatting or leading 1, then stripping it
        newValue = newValue.replace(/[^0-9]/g, '');
        newValue = newValue.substring(newValue.length - 10);
      }
      set(newState, propName, newValue);
      this.setState(newState);
    };
  },
  addPhoneNumber() {
    const { dispatch } = this.props;
    const { number } = this.state;

    // validation
    if (!number) {
      dispatch(addNotif('Phone number is required.'));
      return;
    }

    this.createNumbers([number]);
  },
  bulkAddNumbers() {
    const { dispatch } = this.props;
    const { numbers } = this.state;

    // validation
    if (!numbers.length) {
      dispatch(addNotif('At least one phone number is required.'));
      return;
    }

    this.createNumbers(numbers);
  },
  createNumbers(numbers) {
    const { dispatch, trunkId, subscriptionNumber, productId } = this.props;
    const { e911Enabled, provider, origin, capabilities, reason } = this.state;
    const e911Bool = e911Enabled === 'yes';

    const tooShort = numbers.find((n) => n.length !== 10);
    if (tooShort) {
      dispatch(
        addNotif(`Phone number '${tooShort}' should be in the following format: '5557779999'`)
      );
      return;
    }
    if (!reason) {
      dispatch(addNotif('Reason is required.'));
      return;
    }
    if (e911Bool && provider === 'UNKNOWN') {
      dispatch(addNotif('Provider may not be UNKNOWN when E911 is enabled.'));
      return;
    }

    const doneProcessing = () => {
      this.setState({
        processing: false,
        processingText: '',
      });
    };
    this.setState({ processing: true, processingText: '' });

    this.setState({ processingText: 'Adding number(s)' });
    dispatch(
      addFreshPhoneNumbers({
        numbers,
        e911Enabled: e911Bool,
        provider,
        origin,
        capabilities: {
          e911: capabilities.e911 === 'yes',
          cnam: capabilities.cnam === 'yes',
          sms: capabilities.sms === 'yes',
        },
        reason,
        subscriptionNumber,
        productId,
      })
    )
      .then(() => {
        this.setState({ processingText: 'Putting number(s) on trunk' });
        return dispatch(addDidsToTrunk(trunkId, { numbers, reason, middleOut: true }));
      })
      .then(() => {
        this.setState({ processingText: 'Refreshing data' });
        return dispatch(fetchOneProductInstance({ subscriptionNumber }));
      })
      .then(() => {
        doneProcessing();
        this.props.closeAction();
      })
      .catch(doneProcessing);
  },
  parseNumbers() {
    const { unprocessedNumbers, numbers } = this.state;
    unprocessedNumbers.split('\n').forEach((n) => {
      if (n) {
        n = n.trim();
        n = n.replace(/[^0-9]/g, '');
        n = n.substring(0, 10);
        if (n) {
          numbers.push(n);
        }
      }
    });
    numbers.sort();
    this.setState({ numbers: uniq(numbers), unprocessedNumbers: '' });
  },
  undoBulkNumber(number) {
    const { numbers } = this.state;
    const ix = numbers.findIndex((v) => v === number);
    if (ix !== -1) {
      numbers.splice(ix, 1);
      this.setState({ numbers });
    }
  },
  render() {
    const {
      processing,
      processingText,
      bulkMode,
      number,
      numbers,
      unprocessedNumbers,
      e911Enabled,
      origin,
      provider,
      capabilities,
      reason,
    } = this.state;
    const { closeAction } = this.props;

    const header = <h3>Add New Managed Phone Number</h3>;
    const body = (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-xs-4">
            Phone Number{bulkMode ? 's' : ''}
            &nbsp;
            <button
              className="btn btn-xs btn-default"
              onClick={() => this.setState({ bulkMode: !bulkMode })}
            >
              {bulkMode ? 'Single' : 'Bulk'}
            </button>
          </label>
          <div className="col-xs-3">
            {bulkMode ? (
              <textarea
                className="form-control"
                style={{ padding: '0 5px', fontSize: '12px', height: '100px' }}
                placeholder="Enter 1 number per line"
                onChange={this.onPropChange('unprocessedNumbers')}
                value={unprocessedNumbers}
              />
            ) : null}
            {bulkMode ? (
              <span className="help-block">
                <button className="btn btn-xs btn-primary" onClick={this.parseNumbers}>
                  Parse Numbers
                </button>
              </span>
            ) : null}
            {!bulkMode ? (
              <input
                className="form-control"
                value={number}
                type="tel"
                required={true}
                onChange={this.onPropChange('number')}
              />
            ) : null}
          </div>
          <div className="col-xs-4 align-right">
            {bulkMode
              ? numbers.map((n) => (
                  <span key={`preview-${n}`} className="help-block">
                    {phone(n)}
                    &nbsp;&nbsp;
                    <a href="javascript:void(0)" onClick={() => this.undoBulkNumber(n)}>
                      Remove
                    </a>
                  </span>
                ))
              : null}
            {!bulkMode ? <span className="help-block">{phone(number)}</span> : null}
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">e911 Enabled</label>
          <div className="col-xs-7">
            <select value={e911Enabled} onChange={this.onPropChange('e911Enabled')}>
              <option value="no">no</option>
              <option value="yes">yes</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Origin</label>
          <div className="col-xs-7">
            <select value={origin} onChange={this.onPropChange('origin')}>
              <option value="Automation">Automation</option>
              <option value="Manual">Manual</option>
              <option value="LNP">LNP</option>
              <option value="Unknown">Unknown</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Provider</label>
          <div className="col-xs-7">
            <select type="text" value={provider} onChange={this.onPropChange('provider')}>
              <option value="BANDWIDTH">BANDWIDTH</option>
              <option value="GLBX">GLBX</option>
              <option value="LEVEL3">LEVEL3</option>
              <option value="PAETEC">PAETEC</option>
              <option value="VITELITY">VITELITY</option>
              <option value="UNKNOWN">UNKNOWN</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">e911 capable</label>
          <div className="col-xs-7">
            <select
              type="text"
              value={capabilities.e911}
              onChange={this.onPropChange('capabilities.e911')}
            >
              <option value="no">no</option>
              <option value="yes">yes</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">sms capable</label>
          <div className="col-xs-7">
            <select
              type="text"
              value={capabilities.sms}
              onChange={this.onPropChange('capabilities.sms')}
            >
              <option value="no">no</option>
              <option value="yes">yes</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">cnam capable</label>
          <div className="col-xs-7">
            <select
              type="text"
              value={capabilities.cnam}
              onChange={this.onPropChange('capabilities.cnam')}
            >
              <option value="no">no</option>
              <option value="yes">yes</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Reason for change</label>
          <div className="col-xs-7">
            <input
              className="form-control"
              value={reason}
              type="text"
              required={true}
              onChange={this.onPropChange('reason')}
            />
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={processing}
          onClick={bulkMode ? this.bulkAddNumbers : this.addPhoneNumber}
        >
          {processing ? processingText || 'Configuring...' : 'Add'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(AddDMSNumberModal);
