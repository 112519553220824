import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import billingReducer from './reducers/billingReducer';
import didsReducer from './reducers/didsReducer';
import rcfReducer from './reducers/rcfReducer';
import selectedAccountReducer from './reducers/selectedAccountReducer';
import sessionReducer from './reducers/sessionReducer';
import notifReducer from './reducers/notifReducer';
import accountsReducer from './reducers/accountsReducer';
import adminAccountSearchReducer from './reducers/adminAccountSearchReducer';
import adminUserSearchReducer from './reducers/adminUserSearchReducer';
import usersReducer from './reducers/usersReducer';
import productReducer from './reducers/productReducer';
import uipermsReducer from './reducers/uipermsReducer';
import quoteCheckoutReducer from './reducers/quoteCheckoutReducer';
import quoteReducer from './reducers/quoteReducer';
import dealReducer from './reducers/dealReducer';
import agentDemoReducer from './reducers/agentDemoReducer';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

export default (initialState) => {
  // Add the reducer to your store on the `router` key
  // Also apply our middleware for navigating
  const middlewares = [thunk];
  if (initialState.config.enableLogger) {
    middlewares.push(logger);
  }
  const store = createStore(
    combineReducers({
      billing: billingReducer,
      dids: didsReducer,
      notifs: notifReducer,
      rcf: rcfReducer,
      session: sessionReducer,
      selectedAccount: selectedAccountReducer,
      accounts: accountsReducer,
      adminAccountSearch: adminAccountSearchReducer,
      adminUserSearch: adminUserSearchReducer,
      users: usersReducer,
      products: productReducer,
      accountPermissions: uipermsReducer,
      quote: quoteReducer,
      quoteCheckout: quoteCheckoutReducer,
      deal: dealReducer,
      agentDemo: agentDemoReducer,
      config: (s = {}) => s,
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  return { store };
};
