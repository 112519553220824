import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import get from 'lodash/get';
import ProductDetails from '../../../../components/ProductDetails/ProductDetails';
import TrunkDetails from './TrunkDetails';
import SIPSwitchDetails from './SipSwitchDetails';
import { fetchOneProductInstance } from '../../../../actions/productActions';
import ReconcileNumbers from './ReconcileNumbers';
import DeleteTrunkModal from './DeleteTrunkModal';
import DisableTrunkModal from './DisableTrunkModal';
import EnableTrunkModal from './EnableTrunkModal';
import ImportTrunkModal from './ImportTrunkModal';
import ProductNameFormatter from '../../../../lib/productNameFormatter';
import isNumber from 'lodash/isNumber';

const wellBtn = { marginTop: '10px', marginRight: '9px' };

const ReconciliationRoute = createReactClass({
  componentDidMount() {
    this.fetchWholeProduct();
  },
  getInitialState() {
    return {
      processing: false,
      editModal: null,
    };
  },
  fetchWholeProduct() {
    this.props.dispatch(
      fetchOneProductInstance({
        subscriptionNumber: this.props.subscriptionNumber,
        checkSwitchState: true,
        checkSubscriptionState: true,
      })
    );
  },

  /* Reconciliation actions */
  deleteTrunk() {
    const {
      subscriptionNumber,
      session: { email },
    } = this.props;

    this.setState({
      editModal: (
        <DeleteTrunkModal
          email={email}
          subscriptionNumber={subscriptionNumber}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  disableTrunk() {
    const {
      subscriptionNumber,
      session: { email },
    } = this.props;

    this.setState({
      editModal: (
        <DisableTrunkModal
          email={email}
          subscriptionNumber={subscriptionNumber}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  enableTrunk() {
    const {
      subscriptionNumber,
      session: { email },
    } = this.props;

    this.setState({
      editModal: (
        <EnableTrunkModal
          email={email}
          subscriptionNumber={subscriptionNumber}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  importTrunk() {
    const { subscriptionNumber } = this.props;
    const product = this.getLoadedProduct();

    this.setState({
      editModal: (
        <ImportTrunkModal
          subscriptionNumber={subscriptionNumber}
          sipSwitch={product.sipSwitch}
          subscription={product.subscription}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  /* end Reconciliation actions */

  /* calculating UI elements */
  getLoadedProduct() {
    const { products, subscriptionNumber } = this.props;
    return products.find((p) => p.subscriptionNumber === subscriptionNumber);
  },
  /**
   * Ensure the trunk is configured correctly in the switch (no mismatches)
   */
  trunkConfiguredInSwitch() {
    const product = this.getLoadedProduct();
    const { trunk, sipSwitch } = product;
    const callingAreaMismatchedSwitch = sipSwitch && trunk.callingArea !== sipSwitch.calling_area;
    const channelsMismatchedSwitch =
      sipSwitch && isNumber(sipSwitch.channels) && trunk.channels !== sipSwitch.channels;
    const typeMismatchedSwitch = sipSwitch && sipSwitch.type !== trunk.type;

    return !callingAreaMismatchedSwitch && !channelsMismatchedSwitch && !typeMismatchedSwitch;
  },
  allowedToImport() {
    const product = this.getLoadedProduct();
    const hasSub = product && product.subscription;
    const hasSwitch = product && product.sipSwitch && product.sipSwitch.username;
    const hasTrunk = product && product.trunk && product.trunk.username;
    return hasSub && hasSwitch && !hasTrunk;
  },
  allowedToDeleteTrunk() {
    const product = this.getLoadedProduct();
    const hasSwitch = product && product.sipSwitch && product.sipSwitch.username;
    const hasTrunk = product && product.trunk && product.trunk.username;
    const trunkDisabled = product && product.trunk && !product.trunk.enabled;
    return hasSwitch && hasTrunk && trunkDisabled;
  },
  allowedToDisableTrunk() {
    const product = this.getLoadedProduct();
    const hasSwitch = product && product.sipSwitch && product.sipSwitch.username;
    const hasTrunk = product && product.trunk && product.trunk.username;
    const trunkEnabled = product && product.trunk && product.trunk.enabled;
    const trunkConfiguredInSwitch = this.trunkConfiguredInSwitch();
    return hasSwitch && hasTrunk && trunkEnabled && trunkConfiguredInSwitch;
  },
  allowedToEnableTrunk() {
    const product = this.getLoadedProduct();
    const hasSwitch = product && product.sipSwitch && product.sipSwitch.username;
    const hasTrunk = product && product.trunk && product.trunk.username;
    const trunkDisabled = product && product.trunk && !product.trunk.enabled;
    return hasSwitch && hasTrunk && trunkDisabled;
  },
  /* end calculating UI elements */

  render() {
    const { subscriptionNumber, accountPermissions } = this.props;
    const { processing, editModal } = this.state;

    if (!accountPermissions.employeeReconcile) {
      return (
        <section>
          <h1>Forbidden</h1>
          <p>This account lacks permission to reconcile DIDs.</p>
        </section>
      );
    }

    const product = this.getLoadedProduct();
    if (!product) {
      return (
        <section>
          <h1>DID Reconciliation</h1>
          <p>Subscription #{subscriptionNumber} is still loading or not available.</p>
        </section>
      );
    }

    if (processing) {
      return (
        <section>
          <h1>DID Reconciliation</h1>
          <h2>{ProductNameFormatter(product, true)}</h2>
          <br />
          <h3>Applying changes...please wait...</h3>
        </section>
      );
    }

    const importTrunkAllowed = this.allowedToImport();
    const deleteTrunkAllowed = this.allowedToDeleteTrunk();
    const disableTrunkAllowed = this.allowedToDisableTrunk();
    const enableTrunkAllowed = this.allowedToEnableTrunk();
    const trunkConfiguredInSwitch = this.trunkConfiguredInSwitch();
    const entitlements = get(
      product,
      'subscription.entitlements.productSpecificEntitlements',
      undefined
    );

    return (
      <section className="quite-small">
        {editModal}
        <h1>DID Reconciliation</h1>
        <h2>{ProductNameFormatter(product, true)}</h2>
        <br />
        {enableTrunkAllowed ? (
          <p className="alert alert-danger" role="alert">
            DISABLED
          </p>
        ) : null}

        {disableTrunkAllowed ? (
          <button
            className="btn btn-danger btn-xs pull-right"
            style={wellBtn}
            onClick={this.disableTrunk}
          >
            Disable Trunk
          </button>
        ) : null}

        {!enableTrunkAllowed && !trunkConfiguredInSwitch ? (
          <p className="alert alert-danger" role="alert">
            Trunk disablement is not allowed for this trunk at this time, please review
            configuration.
          </p>
        ) : null}

        {enableTrunkAllowed ? (
          <button
            className="btn btn-primary btn-xs pull-right"
            style={wellBtn}
            onClick={this.enableTrunk}
          >
            Enable Trunk
          </button>
        ) : null}
        <h3>Trunk</h3>
        <div className="row animated fadeIn">
          <div className="col-sm-6">
            {importTrunkAllowed ? (
              <button
                className="btn btn-primary btn-xs pull-right"
                style={wellBtn}
                onClick={this.importTrunk}
              >
                <span>Import &raquo;</span>
              </button>
            ) : null}
            <ProductDetails title="Subscription" sub={product.subscription}>
              <h4>SIP Switch</h4>
              <SIPSwitchDetails
                sipSwitch={product.sipSwitch}
                trunk={product.trunk}
                entitlements={entitlements}
              />
              <br />
            </ProductDetails>
          </div>
          <div className="col-sm-6">
            {deleteTrunkAllowed ? (
              <button
                className="btn btn-danger btn-xs pull-right"
                style={wellBtn}
                onClick={this.deleteTrunk}
              >
                Destroy Trunk...
              </button>
            ) : null}
            <div className="well">
              <h4>Managed Trunk</h4>
              <TrunkDetails
                trunk={product.trunk}
                product={product}
                sipSwitch={product.sipSwitch}
                entitlements={entitlements}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '33px' }} />
        <ReconcileNumbers trunk={product.trunk} product={product} sipSwitch={product.sipSwitch} />
      </section>
    );
  },
});

ReconciliationRoute.propTypes = {
  dispatch: PropTypes.func,
  products: PropTypes.array,
  session: PropTypes.object,
  accountPermissions: PropTypes.object,
  subscriptionNumber: PropTypes.string,
};

function mapStateToProps({ products, session, accountPermissions }) {
  return { products, session, accountPermissions };
}

export default connect(mapStateToProps)(ReconciliationRoute);
