import * as actions from '../actions/adminAccountSearchActions';

// list of accounts - search results
const initialState = {
  pagination: {
    page: 1,
    pageSize: 20,
    pageCount: 1,
    rowCount: 0,
  },
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_RESULTS:
      return { ...action.payload };
    default:
      return state;
  }
};
