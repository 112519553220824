import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import * as actions from '../actions/notifActions';

const initialState = [];

const interpretNotification = (msg) => {
  // Is this a simple string message?
  if (isString(msg)) {
    return msg;
  }
  let notificationMessage = 'An error occurred. Please try again.';
  // we have to do this as opposed to instanceof UINotificationError due to babel transpiling
  // to code that no longer resolves the subclass of Error to the expected value
  logError(msg);
  if (msg.name === 'UINotificationError' || msg.status === 404) {
    // display UI errors to the user
    notificationMessage = msg.message;
  } else if (!isEmpty(msg.validationErrors)) {
    // display validation errors to the user
    notificationMessage = msg.validationErrors.join('. ');
  } else if (!isEmpty(msg.apiErrors)) {
    // display API errors to the user
    notificationMessage = msg.apiErrors.join('. ');
  }

  return notificationMessage;
};

function dedupeList(list) {
  const found = {};
  const newList = [];
  list.forEach((item) => {
    if (!found[item.notif]) {
      found[item.notif] = true;
      newList.push(item);
    }
  });
  return newList;
}

function logError(err) {
  // IE only exposes the console object if the dev tools are currently open
  try {
    if (window.console) {
      console.error('Notification error', {
        err,
        sourceService: err.sourceService,
        validationErrors: err.validationErrors,
        apiErrors: err.apiErrors,
      });
    }
  } catch (exception) {
    // this breaks tests otherwise. Will also protect against inconsistent console
    // object in various browsers
  }
}

export default function notifReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_NOTIF: {
      const interpretedMsg = interpretNotification(action.payload.notif);
      return dedupeList([
        ...state,
        {
          notif: interpretedMsg,
          action: action.payload.action,
        },
      ]);
    }
    case actions.REPLACE_NOTIFS:
      return dedupeList(
        action.payload.map((payload) => ({
          notif: interpretNotification(payload.notif),
          action: payload.action,
        }))
      );
    case actions.REMOVE_NOTIF: {
      const nextState = [...state];
      nextState.splice(action.payload, 1);
      return nextState;
    }
    case '@@router/LOCATION_CHANGE':
    case actions.CLEAR_NOTIFS:
      return [];
    default:
      return state;
  }
}
