import React from 'react';
import isNumber from 'lodash/isNumber';

const trunkNotOnSwitchAlert = (
  <div className="alert alert-danger">This trunk was not found on the SIP switch</div>
);

export default function sipSwitchDetails({ sipSwitch, trunk, entitlements }) {
  if (!sipSwitch) {
    return <div>Loading switch details...</div>;
  }

  const callingAreaMismatchedDMS = trunk && trunk.callingArea !== sipSwitch.calling_area;
  const callingAreaMismatchedEntitlements =
    entitlements &&
    ((sipSwitch.calling_area === 'international' && !entitlements.international) ||
      (sipSwitch.calling_area === 'extended' && !entitlements.extendedArea));
  const callingAreaMismatched = callingAreaMismatchedDMS || callingAreaMismatchedEntitlements;

  const channelsMismatchedDMS =
    trunk && isNumber(trunk.channels) && trunk.channels !== sipSwitch.channels;
  const channelsMismatchedEntitlements =
    entitlements && entitlements.channelized && entitlements.numChannels !== sipSwitch.channels;
  const channelsMismatched = channelsMismatchedDMS || channelsMismatchedEntitlements;

  const typeMismatchedDMS = trunk && trunk.type !== sipSwitch.type;
  const typeMismatched = typeMismatchedDMS;
  const dmsError = sipSwitch.dmsError;

  let errorDisplay = null;
  if (dmsError && dmsError.error.status !== 404) {
    errorDisplay = (
      <div>
        <p className="alert alert-danger">
          Error fetching trunk data from DMS.
          <br />
          {'Source service: ' +
            dmsError.data.sourceService +
            ', Status code: ' +
            dmsError.error.status}
        </p>
      </div>
    );
  }

  if (channelsMismatched || callingAreaMismatched || typeMismatched) {
    const callingAreaErrorText = `
            ${callingAreaMismatched ? 'Mismatched Calling Area (' : ''}
            ${callingAreaMismatchedDMS ? 'DMS' : ''}
            ${callingAreaMismatchedDMS && callingAreaMismatchedEntitlements ? ', ' : ''}
            ${callingAreaMismatchedEntitlements ? 'Subscription' : ''}
            ${callingAreaMismatched ? ')' : ''}
        `;
    const channelsErrorText = `
            ${channelsMismatched ? 'Mismatched Channel Count (' : ''}
            ${channelsMismatchedDMS ? 'DMS' : ''}
            ${channelsMismatchedDMS && channelsMismatchedEntitlements ? ', ' : ''}
            ${channelsMismatchedEntitlements ? 'Subscription' : ''}
            ${channelsMismatched ? ')' : ''}
        `;
    const typeErrorText = `
            ${typeMismatched ? 'Mismatched Trunk Type (' : ''}
            ${typeMismatchedDMS ? 'DMS' : ''}
            ${typeMismatched ? ')' : ''}
        `;

    errorDisplay = (
      <div>
        <p className="alert alert-danger">
          {callingAreaErrorText}
          {callingAreaMismatched ? <br /> : null}
          {channelsErrorText}
          {channelsMismatched ? <br /> : null}
          {typeErrorText}
        </p>
      </div>
    );
  }

  const trunkNotOnSwitch = !sipSwitch.username && (!dmsError || dmsError.error.status === 404);
  return (
    <div className="product-details">
      {trunkNotOnSwitch ? trunkNotOnSwitchAlert : null}
      {errorDisplay}
      <div>
        <label>Trunk Type:</label>
        <p>{sipSwitch.type}</p>
      </div>
      <div>
        <label>Username:</label>
        <p>{sipSwitch.username || ''}</p>
      </div>
      <div>
        <label>Calling Area:</label>
        <p>{sipSwitch.calling_area}</p>
      </div>
      <div>
        <label>Channels:</label>
        <p>{sipSwitch.channels || 'N/A'}</p>
      </div>
      <div>
        <label>Order ID:</label>
        <p>{sipSwitch.orderId}</p>
      </div>
    </div>
  );
}
