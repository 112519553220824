import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class HelpRoute extends React.Component {
  render() {
    const { roles } = this.props;
    return (
      <section className="animated fadeIn">
        <h1>Support</h1>
        <div className="row">
          <div className="col-sm-6">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4>Technical Support</h4>
              </div>
              <div className="panel-body">
                <p>For assistance with technical issues regarding your service:</p>
                <p>
                  <strong>
                    <a href="https://www.sangoma.com/support/contact" target="_blank">
                      sangoma.com/support/contact
                    </a>
                  </strong>
                </p>
                <p>To access the knowledge base or FAQs:</p>
                <p>
                  <strong>
                    <a href="https://www.sangoma.com/support/switchvox-cloud" target="_blank">
                      sangoma.com/support/switchvox-cloud
                    </a>
                  </strong>
                </p>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4>Customer Success</h4>
              </div>
              <div className="panel-body">
                <p>For general account questions or to modify service:</p>
                <p>
                  <strong>
                    <a
                      href="https://www.sangoma.com/support/switchvox-cloud/dcs-customer-service"
                      target="_blank"
                    >
                      sangoma.com/support/switchvox-cloud/dcs-customer-service
                    </a>
                  </strong>
                </p>
                <br />
                <p>
                  <strong>256-428-6175</strong>
                </p>
                <p>
                  <strong>customersuccess@digiumcloud.com</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            {roles && roles.find((r) => r.productId === 'hosted-switchvox') ? (
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h4>Switchvox Cloud Training</h4>
                </div>
                <div className="panel-body">
                  <p>To access online training for your Switchvox Cloud service:</p>
                  <p>
                    <strong>
                      <a href="https://www.sangoma.com/training/switchvox" target="_blank">
                        sangoma.com/training/switchvox
                      </a>
                    </strong>
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

HelpRoute.propTypes = {
  roles: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    roles: state.session.roles,
  };
}

export default withRouter(connect(mapStateToProps)(HelpRoute));
