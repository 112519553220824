import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Switchvox from '../hosted-switchvox/Switchvox';
import SIPTrunk from '../sip-trunking/SIPTrunk';

const Product = createReactClass({
  displayName: 'Product',

  render() {
    const { match, products, accountPermissions } = this.props;
    const product = products.find((p) => p.subscriptionNumber === match.params.subscriptionNumber);

    if (!product) {
      // Things could still be loading, so be optimistic about the future
      return <section></section>;
    }

    const forbiddenAlert = (
      <section>
        <h1>Forbidden</h1>
        <span className="alert">You do not have permission to view this product.</span>
      </section>
    );

    switch (product.productId) {
      case 'clickvox':
        if (!accountPermissions.viewClickvox) {
          return forbiddenAlert;
        }
        return (
          <section>
            <h1>Clickvox</h1>
          </section>
        );
      case 'hosted-switchvox':
        if (!accountPermissions.viewSwitchvox) {
          return forbiddenAlert;
        }
        return <Switchvox />;
      case 'sip-trunking':
        if (!accountPermissions.viewTrunking) {
          return forbiddenAlert;
        }
        return <SIPTrunk />;
      default:
        return (
          <section>
            <h1>Oops, something went wrong.</h1>
            <p>
              An unexpected error occurred. Our development team has been notified to correct the
              problem. Please try again in a few minutes. If you need immediate assistance, please
              contact our Customer Success team at &nbsp;
              <a href="mailto:customersuccess@digiumcloud.com">customersuccess@digiumcloud.com</a>.
            </p>
          </section>
        );
    }
  },
});

Product.propTypes = {
  match: PropTypes.object,
  products: PropTypes.array,
  accountPermissions: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    selectedAccount: state.selectedAccount,
    accountPermissions: state.accountPermissions,
    products: state.products,
  };
}

export default withRouter(connect(mapStateToProps)(Product));
