import 'whatwg-fetch';
import uniq from 'lodash/uniq';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';

/*!
 * Synchronous actions
 */

export const SET_ACCOUNT = 'SET_ACCOUNT';
export function setAccount(payload) {
  return {
    type: SET_ACCOUNT,
    payload,
  };
}

/*!
 * Async thunks
 */

export function resyncAccountNames(accountIdList) {
  return async (dispatch, getState) => {
    const { selectedAccount, session } = getState();
    if (!accountIdList) {
      if (!session.accounts || !Object.keys(session.accounts).length) {
        accountIdList = [getState().selectedAccount.id];
      } else {
        accountIdList = uniq([selectedAccount.id, ...session.accounts]);
      }
    }
    // get all account names in an ordered list
    let knownAccounts;
    try {
      knownAccounts = await Promise.all(
        accountIdList.map((acctId) => {
          if (acctId && acctId !== '*') {
            return fetchAccount(acctId).catch(() => {});
          }
          return null;
        })
      );
    } catch (err) {
      notifyError(dispatch)(err);
      return;
    }
    knownAccounts.forEach((acct) => {
      if (acct) {
        dispatch(setAccount(acct));
      }
    });
  };
}

// helper - not redux middleware
function fetchAccount(accountId) {
  return fetch(`/api/v1/accounts/${accountId}`, {
    method: 'GET',
    credentials: 'include',
  })
    .then(handleAPI)
    .catch(() => ({ id: accountId }));
}
