// TODO: need to replace all this.refs errors and test before upgrading React
/* eslint-disable react/no-string-refs */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import Modal from '../../../../components/Modal/Modal';
import { createRcfRule, updateRcfRule } from '../../../../actions/rcfActions';
import phone from '../../../../lib/phone';
import { byNumber } from '../../../../../src/lib/sorting';
import ProductNameFormatter from '../../../../lib/productNameFormatter';

const newRule = (subscriptionNumber) => ({
  id: null,
  name: '',
  destination: '',
  numbers: [],
  subscriptionId: subscriptionNumber,
});

const multiSelectStyling = { height: '100px', marginTop: '13px' };
const numberSelectionButtonStyles = { height: '1.25em', paddingTop: '8px' };

// EditRule is for editing for creating a RCF rule
const EditRule = createReactClass({
  propTypes: {
    rcf: PropTypes.array,
    // The unique ID of this rule. If this is a new rule, this will be null.
    ruleId: PropTypes.string,
    // The instance of the product that we are acting upon.
    product: PropTypes.object,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
  },
  getInitialState() {
    let rule;
    if (this.props.rcf) {
      rule = this.props.rcf.find((r) => r.id === this.props.ruleId);
    }
    if (!rule) {
      rule = newRule(this.props.product.subscriptionNumber);
    }
    return {
      rule,
      cancelButtonConf: false,
      isSaving: false,
      errors: {},
    };
  },
  save() {
    const { closeAction, dispatch } = this.props;
    const { rule } = this.state;
    const save = rule.id ? updateRcfRule : createRcfRule;
    const errors = this.validateInput();
    if (Object.keys(errors).length) {
      this.setState({ errors });
      return;
    }
    this.setState({
      isSaving: true,
    });

    dispatch(save(rule))
      .then(closeAction)
      .catch(() =>
        this.setState({
          isSaving: false,
        })
      );
  },
  onNameChange(event) {
    this.setState({
      rule: {
        ...this.state.rule,
        name: event.target.value,
      },
    });
  },
  onDestinationChange(event) {
    this.setState({
      rule: {
        ...this.state.rule,
        destination: event.target.value.replace(/[^0-9]/g, '').substring(0, 10),
      },
    });
  },
  addSelected() {
    const options = this.refs.availableNumbers.options;
    const selectedValues = [];

    for (let i = 0; i < options.length; i++) {
      // not a real array
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }

    if (!selectedValues.length) {
      // select all
      for (let i = 0; i < options.length; i++) {
        // not a real array
        options[i].selected = true;
      }
      return;
    }
    const newState = {
      rule: {
        ...this.state.rule,
        numbers: this.state.rule.numbers.concat(selectedValues),
      },
    };
    newState.rule.numbers.sort(byNumber);
    this.setState(newState);
  },
  removeSelected() {
    const options = this.refs.selectedNumbers.options;
    const values = [];
    const numbers = [...this.state.rule.numbers];

    for (let i = 0; i < options.length; i++) {
      // not a real array
      if (options[i].selected) {
        values.push(options[i].value);
      }
    }

    if (!values.length) {
      // select all
      for (let i = 0; i < options.length; i++) {
        // not a real array
        options[i].selected = true;
      }
      return;
    }

    this.setState({
      rule: {
        ...this.state.rule,
        numbers: numbers.filter((n) => !values.includes(n)),
      },
    });
  },
  saveButtonDisabled() {
    const { isSaving } = this.state;
    if (isSaving) {
      return true;
    }
  },
  validateInput() {
    const { rule } = this.state;
    const errors = {};
    if (!rule.name) {
      errors.name = 'This is a required Field';
    } else if (rule.name.length <= 3) {
      errors.name = 'Description must be longer than 3 characters';
    }
    if (!rule.destination) {
      errors.destination = 'This is a required Field';
    } else if (rule.destination.length !== 10) {
      errors.destination = 'This must be a 10-digit number';
    }
    return errors;
  },
  render() {
    const { closeAction, rcf, product } = this.props;
    const { rule, isSaving, cancelButtonConf, errors } = this.state;

    let saveButtonText;
    let title;

    if (rule.id) {
      title = 'Editing';
      saveButtonText = isSaving ? 'Saving...' : 'Save';
    } else {
      title = 'New';
      saveButtonText = isSaving ? 'Creating...' : 'Create';
    }
    const instanceInfo = ProductNameFormatter(product);

    let cancelButton;
    if (cancelButtonConf) {
      cancelButton = (
        <span>
          <button className="btn btn-primary" disabled={isSaving} onClick={closeAction}>
            Close
          </button>
          <button
            className="btn btn-default"
            onClick={() => this.setState({ cancelButtonConf: false })}
          >
            Stay here
          </button>
          &nbsp;
        </span>
      );
    } else {
      cancelButton = (
        <button
          className="btn btn-default"
          disabled={isSaving}
          onClick={() => this.setState({ cancelButtonConf: true })}
        >
          Cancel...
        </button>
      );
    }
    let allInstanceNumbers;
    if (product.trunk && product.trunk.numbers) {
      allInstanceNumbers = product.trunk.numbers;
    }
    if (product.switchvox && product.switchvox.numbers) {
      allInstanceNumbers = product.switchvox.numbers;
    }

    const ruleNumbersFlat = rcf
      .filter((r) => r.id !== rule.id) // not the selected number
      .map((r) => r.numbers)
      .reduce((prev, curr) => prev.concat(curr), []);
    const availableNumbers = [];
    let someNumbersExistOnSwitch = false;
    const selectedNumbers = allInstanceNumbers.filter((did) => {
      // is it already in this rule?
      if (rule.numbers.includes(did.number)) {
        return true;
      }

      // at least display it in the list, but it may not be selectable if
      // it is already in another
      if (!ruleNumbersFlat.includes(did.number)) {
        availableNumbers.push(did);
        // make sure at least 1 available number is on the switch so we can enable the addSelectedButton
        if (did.onSwitch) {
          someNumbersExistOnSwitch = true;
        }
      }

      return false;
    });
    const removeButton = selectedNumbers.length ? (
      <button className="btn btn-primary btn-xs" onClick={this.removeSelected}>
        Remove
      </button>
    ) : null;
    const addSelectedButton =
      availableNumbers.length && someNumbersExistOnSwitch ? (
        <button className="btn btn-primary btn-xs" onClick={this.addSelected}>
          Add
        </button>
      ) : null;

    const header = (
      <h3>
        {title} RCF Rule
        {instanceInfo ? <small className="help-block">{instanceInfo}</small> : null}
      </h3>
    );
    const body = (
      <div>
        <div className="form-horizontal">
          <div className="form-group">
            <label className="col-xs-6 control-label required-field">Description</label>
            <div className={`col-xs-6 ${errors.name ? 'has-error' : ''}`}>
              <input className="form-control" value={rule.name} onChange={this.onNameChange} />
              <span className={`help-block animated fadeIn ${errors.name ? '' : 'hidden'}`}>
                {errors.name}
              </span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-6 control-label required-field">Where to forward calls</label>
            <div className={`col-xs-6 ${errors.destination ? 'has-error' : ''}`}>
              <input
                className="form-control"
                placeholder="5555555555"
                type="phone"
                onChange={this.onDestinationChange}
                value={rule.destination}
              />
              <span className="help-block" style={{ paddingLeft: '9px' }}>
                {phone(rule.destination)}
              </span>
              <span className={`help-block animated fadeIn ${errors.destination ? '' : 'hidden'}`}>
                {errors.destination}
              </span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-6 control-label">
              Numbers available for forwarding
              <br />
              <select
                className="form-control align-right"
                multiple="multiple"
                ref="availableNumbers"
                style={multiSelectStyling}
              >
                {availableNumbers.map((n) => {
                  const opts = {
                    value: n.number,
                    key: n.number,
                  };
                  let notProvisioned = '';
                  // disable number if it is not on the switch yet
                  if (!n.onSwitch) {
                    opts.disabled = 'disabled';
                    notProvisioned = '(not yet available)';
                  }
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <option {...opts}>
                      {phone(n.number)}&nbsp;{notProvisioned}
                    </option>
                  );
                })}
              </select>
              <p className="align-right" style={numberSelectionButtonStyles}>
                {addSelectedButton}
              </p>
            </label>
            <div className="col-xs-6 control-label align-left" style={{ fontWeight: 'bold' }}>
              Numbers selected for forwarding
              <br />
              <select
                className="form-control"
                multiple="multiple"
                ref="selectedNumbers"
                style={multiSelectStyling}
              >
                {selectedNumbers.map((n) => (
                  <option value={n.number} key={n.number}>
                    {phone(n.number)}
                  </option>
                ))}
              </select>
              <p style={numberSelectionButtonStyles}>{removeButton}</p>
            </div>
          </div>
          <br />
        </div>
      </div>
    );

    const footer = (
      <div>
        {cancelButton}
        <button
          className="btn btn-primary"
          disabled={this.saveButtonDisabled()}
          onClick={this.save}
        >
          {saveButtonText}
        </button>
        {!rule.id ? (
          <span className="help-block">
            New rules are <strong>disabled</strong> by default
          </span>
        ) : null}
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps({ rcf }) {
  return {
    rcf,
  };
}

export default withRouter(connect(mapStateToProps)(EditRule));
