import 'whatwg-fetch';
import handleAPI from '../lib/handleInternalAPI';
import notifyError from '../lib/notifyError';
import { UINotificationError } from '../lib/errors';

/*!
 * Synchronous actions
 */
export const SET_SWITCHVOX_DEMO_CREATION_DATA = 'SET_SWITCHVOX_DEMO_CREATION_DATA';

export function setSwitchvoxDemoCreationData(payload) {
  return {
    type: SET_SWITCHVOX_DEMO_CREATION_DATA,
    payload,
  };
}

/*!
 * Async thunks
 */

export function fetchSwitchvoxDemoCreationData() {
  return async (dispatch) => {
    try {
      const url = '/api/v1/agent-demo/switchvox-cloud/creation-data';
      const response = await fetch(url, { credentials: 'include' });
      const dealCreationData = await handleAPI(response);
      dispatch(setSwitchvoxDemoCreationData(dealCreationData));
    } catch (err) {
      notifyError(dispatch)(err);
    }
  };
}

export function createSwitchvoxDemo(demo) {
  return async (dispatch) => {
    try {
      const url = '/api/v1/agent-demo/switchvox-cloud';
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(demo),
      });

      const createdQuote = await handleAPI(response);
      window.location = `/quote-checkout/${createdQuote.id}`;
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}
