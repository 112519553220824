import * as actions from '../actions/agentDemoActions';

const initialState = {
  creationData: null,
};

export default function dealReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SWITCHVOX_DEMO_CREATION_DATA:
      return { ...state, creationData: action.payload };
    default:
      return state;
  }
}
