/**
 * Attempt to format a given phone number as a US phone number
 * in national format. If the number cannot be interpreted as as
 * US number, it will be returned as is.
 *
 * @param {string} num A phone number to be formatted
 * @return {string}
 */
export default function formatAsNationalUsNumber(num) {
  if (!num) {
    return '';
  }

  // remove formatting characters
  let normalizedNum = num.replace(/[(). -]/g, '');

  // don't try to format phone numbers with special chars in them
  if (/[^0-9]/.test(normalizedNum)) {
    return num;
  }

  const overage = normalizedNum.length - 10;
  if (overage > 0) {
    const cc = normalizedNum.substring(0, overage);
    const usCc = '1';
    if (cc !== usCc) {
      return num;
    }
    normalizedNum = normalizedNum.substring(overage);
  }
  const area = normalizedNum.substring(0, 3);
  const prefix = normalizedNum.substring(3, 6);
  const local = normalizedNum.substring(6, 10);
  return `(${area}) ${prefix}-${local}`;
}
