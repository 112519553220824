import * as actions from '../actions/usersActions';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CLEAR_USERS:
      return null;
    case actions.SET_USERS:
      return [...action.payload];
    case actions.SET_USER: {
      // singular
      // do they exist yet?
      const existingUser = (state || []).findIndex((u) => u.id === action.payload.id);
      const newUsers = [...state];
      if (existingUser !== -1) {
        // update user properties at index, preferring new ones
        newUsers[existingUser] = { ...newUsers[existingUser], ...action.payload };
      } else {
        newUsers.push(action.payload);
      }
      return newUsers;
    }
    default:
      return state;
  }
};
