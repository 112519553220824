import 'whatwg-fetch';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';

/*!
 * Async thunks
 */

/**
 * Create a work order for the Digium customer support team.
 *
 */
export function createWorkOrder(subscriptionNumber, workOrder) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    await fetch(
      `/api/v1/accounts/${selectedAccount.id}/subscriptions/${subscriptionNumber}/work-order`,
      {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(workOrder),
      }
    )
      .then(handleAPI)
      .catch(notifyError(dispatch));
  };
}
