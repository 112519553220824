import React from 'react';
import Entitlements from './Entitlements';

/**
 * ProductDetails attempts to be a shared component for SIP Trunks and Hosted Switchvoxes.
 * It gives information about:
 * - zuora subscription
 * - trunk info like channel count or calling area
 * - entitlements like switchvox seats and quantities of numbers
 */
export default function ProductDetails({ title = 'Details', sub = {}, children }) {
  let errorDisplay = null;
  if (sub.error) {
    const formattedSubError = sub.error.validationErrors
      ? sub.error.validationErrors.join(' ')
      : sub.error.message || sub.error;
    errorDisplay = <p className="alert alert-danger">{formattedSubError}</p>;
  }
  let planDescription = <p>Unknown</p>;
  if (sub.contractTerms && sub.contractTerms.primaryPlanName) {
    planDescription = <p>{sub.contractTerms.primaryPlanName}</p>;
  } else if (sub.ratePlans) {
    planDescription = sub.ratePlans.map((rp) => <p key={rp.id}>{rp.ratePlanName || ''}</p>);
  }
  let entitlements = <p>Unknown</p>;
  if (sub.entitlements) {
    entitlements = <Entitlements entitlements={sub.entitlements} />;
  }
  let contractTermsDisplay = 'Unknown';
  let contractTermsStartDate = 'Unknown';
  if (sub.contractTerms) {
    contractTermsDisplay = sub.contractTerms.display;
    contractTermsStartDate = sub.contractTerms.startDate;
  }

  return (
    <div className="well product-details">
      {errorDisplay}
      {children}
      {title ? <h4>{title}</h4> : null}
      <div>
        <label>Plan:</label>
        {planDescription}
      </div>
      <div className="capitalize">
        <label>Contract Terms:</label>
        <p>{contractTermsDisplay}</p>
      </div>
      <div>
        <label>Contract Effective Date:</label>
        <p>{contractTermsStartDate}</p>
      </div>
      <div>
        <label>Subscription Status:</label>
        <p>{sub.status}</p>
      </div>
      <div>
        <label>Service Details:</label>
        {entitlements}
      </div>
    </div>
  );
}
