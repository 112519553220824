import React from 'react';
import PropTypes from 'prop-types';
import phone from '../../../../lib/phone';

/**
 * Functional Component for displaying a certain amount of numbers followed by how many more exist. This is useful when
 * performing a bulk operation on numbers and you want to display the affected phone numbers to the user.
 *
 * Required props:
 *   - numbers: Array of numbers to display (the phone numbers themselves)
 *   - maxDisplay: the number of full phone numbers to display before listing how many are left
 */
function FirstFewNumbers({ numbers, maxDisplay }) {
  // show first few numbers following by how many more are selected for an action
  const firstFew = [];
  for (let i = 0; i < maxDisplay; i++) {
    const n = numbers[i];
    const comma = numbers.length > i + 1 ? ',' : null;
    if (n) {
      firstFew.push(
        <span key={n}>
          {phone(n)}
          {comma} &nbsp;
        </span>
      );
    }
  }
  const s = numbers.length > firstFew.length + 1 ? 's' : '';
  const moreNumbers =
    numbers.length > firstFew.length ? (
      <span>
        and {numbers.length - firstFew.length} more number{s}
      </span>
    ) : null;

  return (
    <span>
      {firstFew}
      {moreNumbers}
    </span>
  );
}

FirstFewNumbers.propTypes = {
  numbers: PropTypes.array,
  maxDisplay: PropTypes.number,
};

export default FirstFewNumbers;
