import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import Modal from '../../../../../components/Modal/Modal';
import { saveInvoiceEmails } from '../../../../../actions/billingActions';
import { fetchAccountUsers } from '../../../../../actions/usersActions';

const EditInvoiceRecipients = createReactClass({
  propTypes: {
    defaultRecipient: PropTypes.string.isRequired,
    emails: PropTypes.array,
    users: PropTypes.array,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
  },
  componentDidMount() {
    this.props.dispatch(fetchAccountUsers());
  },
  getInitialState() {
    return {
      loading: false,
      emails: this.props.emails,
    };
  },
  saveEmails() {
    this.setState({ loading: true });
    this.props
      .dispatch(saveInvoiceEmails(this.state.emails))
      .then(this.props.closeAction)
      .catch(() => this.setState({ loading: false }));
  },
  changeEmail(index) {
    return (event) => {
      const emails = [...this.state.emails];
      emails[index] = event.target.value;
      this.setState({
        emails,
      });
    };
  },
  addEmail() {
    const available = this.availableUsers();
    let nextUser = '';
    if (available.length) {
      nextUser = available[0].email;
    }
    this.setState({
      emails: [...this.state.emails, nextUser],
    });
  },
  removeEmail(index) {
    const emails = [...this.state.emails];
    emails.splice(index, 1);
    this.setState({
      emails,
    });
  },
  availableUsers() {
    const { users } = this.props;
    const { emails } = this.state;
    return users.filter((u) => !emails.includes(u.email));
  },
  render() {
    const { closeAction, defaultRecipient } = this.props;
    const { loading, emails } = this.state;

    const header = <h3>Invoice Recipients</h3>;
    // displays all users who are not already chosen
    const availableUsers = this.availableUsers();

    const addEmail =
      emails.length !== 4 && availableUsers.length ? (
        <button className="btn btn-warning btn-block" onClick={this.addEmail}>
          Add
        </button>
      ) : null;

    const body = (
      <div>
        <p>
          <input
            disabled={true}
            type="text"
            className="form-control animated fadeIn"
            value={defaultRecipient}
          />
        </p>
        <p>
          An invoice recipient must have the <Link to="/users">View Account</Link> permission before
          adding them below.
        </p>
        {emails.map((email, i) => {
          let additionalOptions = availableUsers
            .filter((u) => u.email !== email)
            .map((u) => (
              <option key={u.id} value={u.email}>
                {u.email}
              </option>
            ));
          if (!additionalOptions.length) {
            additionalOptions = null;
          }
          return (
            <div className="row" key={i} style={{ marginBottom: '13px' }}>
              <div className="col-xs-9">
                <select
                  onChange={this.changeEmail(i)}
                  value={email}
                  className="form-control animated fadeIn"
                >
                  <option value={email}>{email}</option>
                  {additionalOptions}
                </select>
              </div>
              <div className="col-xs-3 align-right">
                <button className="btn btn-warning btn-block" onClick={() => this.removeEmail(i)}>
                  Remove
                </button>
              </div>
            </div>
          );
        })}
        <br />
        <div className="row">
          <div className="col-xs-3 col-xs-offset-9">{addEmail}</div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={loading} onClick={this.saveEmails}>
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function withViewAccount(u) {
  return u.roles && u.roles.find((r) => r.roleName === 'view-account');
}

function mapStateToProps(state) {
  const users = (state.users || []).filter(withViewAccount);
  if (state.selectedAccount && state.selectedAccount.zuoraAccount) {
    return {
      users,
      emails: state.selectedAccount.zuoraAccount.billingAndPayment.additionalEmailAddresses,
      defaultRecipient: state.selectedAccount.zuoraAccount.billToContact.workEmail,
    };
  }
  return {
    users,
    emails: [],
    defaultRecipient: '',
  };
}

export default withRouter(connect(mapStateToProps)(EditInvoiceRecipients));
