import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const Icon = createReactClass({
  propTypes: {
    icon: PropTypes.string.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    onClick: PropTypes.func,
  },
  render() {
    const styles = {
      svg: {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '1px',
      },
      path: {
        fill: this.props.color,
      },
    };

    return (
      <svg
        style={styles.svg}
        width={`${this.props.size}px`}
        height={`${this.props.size}px`}
        viewBox="0 0 16 16"
        onClick={this.props.onClick}
      >
        <path style={styles.path} d={this.props.icon}></path>
      </svg>
    );
  },
});

Icon.defaultProps = {
  size: 16,
};

export default Icon;
