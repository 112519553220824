import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import flatten from 'lodash/flatten';
import { fetchInitialSession, logout } from '../../actions/sessionActions';
import Notifications from '../../components/Notifications/Notifications';
import ProductSidebar from './components/ProductSidebar';
import SelectAccount from './components/SelectAccount/SelectAccount';
import sortProducts from '../../lib/sortProducts';

let today = new Date();

const menuStyle = {
  position: 'fixed',
  right: 0,
  top: 0,
  borderRadius: 0,
  zIndex: 1000,
};
const navLi = (pathname, text, to, cls) => {
  if (!cls) {
    cls = '';
  }
  if (pathname === to) {
    cls += ' active';
  }
  return (
    <li key={`${to}`} className={cls}>
      <Link to={to}>{text}</Link>
    </li>
  );
};

require('./Layout.less');

const Layout = createReactClass({
  propTypes: {
    session: PropTypes.object,
    selectedAccount: PropTypes.object,
    accountPermissions: PropTypes.object,
    products: PropTypes.array,

    children: PropTypes.any, // array or object
    dispatch: PropTypes.func,
  },
  getInitialState() {
    // allow copyright date to eventually update on year change
    today = new Date();
    return {
      mobileSidebarOpen: null,
      sidebarClasses: 'col-sm-2 sidebar hidden-xs',
    };
  },
  toggleMobileSidebar() {
    if (this.state.mobileSidebarOpen) {
      this.setState({
        mobileSidebarOpen: false,
        sidebarClasses: 'col-sm-2 sidebar animated slideOutLeft zIndexTop',
      });
    } else {
      this.setState({
        mobileSidebarOpen: true,
        sidebarClasses: 'col-sm-2 sidebar animated slideInLeft zIndexTop',
      });
    }
  },
  componentDidMount() {
    const { session } = this.props;

    // only load up the session one time
    const sessionNeverLoadedYet = !session;
    if (sessionNeverLoadedYet) {
      this.props.dispatch(fetchInitialSession());
    }
  },
  signOut() {
    this.props.dispatch(logout());
  },
  render() {
    const {
      products,
      session,
      children,
      selectedAccount,
      /**
       * @type {AccountPermissions}
       */
      accountPermissions,
      location: { pathname },
    } = this.props;

    let sidebarMain;
    let sidebarMobileButton;
    let mainPageClass = 'col-sm-10 animated fadeIn';
    let copyrightClass = 'copyright-notice';
    // Sidebar
    if (session && session.email) {
      let companyContactNav = null;
      let paymentInfoNav = null;
      let invoicesNav = null;
      let ordersNav = null;
      let usersNav = null;
      if (selectedAccount.zuoraAccount) {
        if (accountPermissions.viewAccount) {
          companyContactNav = navLi(
            pathname,
            'Company Contact',
            `/accounts/${selectedAccount.id}/company-contact`,
            'indented'
          );
          paymentInfoNav = navLi(
            pathname,
            'Payment Info',
            `/accounts/${selectedAccount.id}/payment-info`,
            'indented'
          );
        }
        if (accountPermissions.viewInvoices) {
          invoicesNav = navLi(
            pathname,
            'Invoices',
            `/accounts/${selectedAccount.id}/invoices`,
            'indented'
          );
        }
        if (accountPermissions.viewOrders) {
          ordersNav = [
            <li key="orders-heading" className="nav-header">
              Orders
            </li>,
            navLi(pathname, 'History', `/accounts/${selectedAccount.id}/orders`),
          ];
        }
        if (accountPermissions.manageUsers) {
          usersNav = navLi(
            pathname,
            'Portal Users',
            `/accounts/${selectedAccount.id}/users`,
            'indented'
          );
        }
      }

      let productsNav = null;
      if (products && products.length && accountPermissions.viewProducts) {
        const orderedProducts = [...products];
        orderedProducts.sort(sortProducts);
        productsNav = flatten(
          orderedProducts.map((instance) => {
            const childLinks = ProductSidebar({
              instance,
              pathname,
              accountPermissions,
              products,
            });
            return childLinks;
          })
        );
        // ProductSidebar may return an empty array if the account does not have
        // feature flags enabled for the product type
        if (productsNav.length) {
          productsNav.unshift(
            <li key="product-heading" className="nav-header">
              Products
            </li>
          );
        }
      }

      mainPageClass += ' col-sm-offset-2';
      sidebarMain = (
        <div className={this.state.sidebarClasses}>
          <ul className="nav nav-pills nav-stacked">
            <li className="nav-header">Digium Cloud Services</li>
            <li>
              <a href="#" onClick={this.signOut}>
                Sign Out
              </a>
            </li>
            <li style={{ marginBottom: '21px' }}>&nbsp;</li>
            <li className="nav-header">Account</li>
            <SelectAccount />
            {companyContactNav}
            {paymentInfoNav}
            {invoicesNav}
            {usersNav}
            {ordersNav}
            {productsNav}
            <li className="nav-header">Help</li>
            <li>
              <Link to="/help">Support</Link>
            </li>
          </ul>
          <span className="logo">&nbsp;</span>
        </div>
      );
      sidebarMobileButton = (
        <button
          className="btn btn-warning visible-xs"
          style={menuStyle}
          onClick={this.toggleMobileSidebar}
        >
          Menu
        </button>
      );
    } else {
      mainPageClass += ' col-sm-offset-1';
      copyrightClass += ' align-center';
    }
    return (
      <div>
        <Notifications />
        <section className="container-fluid">
          <div className="row">
            {sidebarMobileButton}
            {sidebarMain}
            <div className={mainPageClass}>
              {children}
              <div className={copyrightClass}>
                &copy; {today.getFullYear()} Digium Cloud Services, LLC All rights reserved.
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  },
});

function mapStateToProps(state) {
  return {
    selectedAccount: state.selectedAccount,
    session: state.session,
    products: state.products,
    accountPermissions: state.accountPermissions,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
