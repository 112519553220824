const entrances = [
  // 'slideInDown',
  // 'bounce',
  // 'rubberBand',
  // 'jello',
  // 'bounceIn',
  'bounceInDown',
  // 'bounceInLeft',
  // 'bounceInRight',
  // 'bounceInUp',
  // 'fadeIn',
  // 'fadeInDown',
  // 'fadeInUp',
  // 'fadeInRight',
  // 'fadeInLeft',
  // 'flipInX',
  // 'flipInY',
  // 'lightSpeedIn',
  // 'rotateIn',
  // 'slideInUp',
  // 'rotateInDownRight',
  // 'zoomIn',
  // 'zoomInLeft',
  // 'rollIn',
];
export default function makeMothaEffinEntrance() {
  return entrances[Math.floor(Math.random() * entrances.length)];
}
