import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import effingEntrance from '../../lib/effingEntrance';

const Modal = createReactClass({
  propTypes: {
    header: PropTypes.object,
    body: PropTypes.object,
    footer: PropTypes.object,
  },
  getInitialState() {
    return {
      animation: effingEntrance(),
    };
  },
  render() {
    const { header, body, footer } = this.props;
    const classes = `modal animated ${this.state.animation} show`;
    return (
      <div className={classes} tabIndex="-1" style={{ overflowY: 'scroll' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">{header}</div>
            <div className="modal-body">{body}</div>
            <div className="modal-footer">{footer}</div>
          </div>
        </div>
      </div>
    );
  },
});

export default Modal;
