import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import phone from '../../../../lib/phone';

// href="#" causes location change event every time it is clicked, which refetches all rule stuff,
// blocking the screen when it is not necessary.
// href="" reloads the whole page
// therefore we use empty
const jsvoid = 'javascript:void(0)'; // eslint-disable-line no-script-url

const RuleNumbersDisplay = createReactClass({
  propTypes: {
    rule: PropTypes.object,
  },
  getInitialState() {
    return {
      numberListVisible: false,
    };
  },
  toggleRuleNumbersVisible() {
    this.setState({ numberListVisible: !this.state.numberListVisible });
  },
  render() {
    const { rule } = this.props;
    const { numberListVisible } = this.state;

    if (rule.numbers.length === 0) {
      return <span className="help-block">0 numbers</span>;
    }
    if (rule.numbers.length < 4) {
      return (
        <div className="help-block">
          {rule.numbers.map((n) => (
            <span key={n}>
              {phone(n)}
              <br />
            </span>
          ))}
        </div>
      );
    }
    // it is expanded
    if (numberListVisible) {
      return (
        <div className="help-block animated fadeIn">
          {rule.numbers.map((n) => (
            <span key={n}>
              {phone(n)}
              <br />
            </span>
          ))}
          <a href={jsvoid} className="help-block" onClick={this.toggleRuleNumbersVisible}>
            Hide
          </a>
        </div>
      );
    }
    const firstThree = [];
    let n;
    for (let i = 0; i < 3; i++) {
      n = rule.numbers[i];
      firstThree.push(
        <span key={n}>
          {phone(n)}
          <br />
        </span>
      );
    }
    const s = rule.numbers.length > 4 ? 's' : '';
    // hide the numbers for now but indicate how many there are
    return (
      <span className="help-block">
        {firstThree}
        <a href={jsvoid} className="animated fadeIn" onClick={this.toggleRuleNumbersVisible}>
          {rule.numbers.length - 3} more number{s}
          &nbsp;
          {!numberListVisible ? <span className="caret" /> : null}
        </a>
      </span>
    );
  },
});

export default RuleNumbersDisplay;
