import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import forEach from 'lodash/forEach';

const stateCodeToName = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'Washington DC',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
};

const SelectState = createReactClass({
  propTypes: {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    useAbbreviationAsValue: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      disabled: false,
      useAbbreviationAsValue: false,
    };
  },
  render() {
    const { className, disabled, onChange, value, useAbbreviationAsValue } = this.props;

    // if using abbreviation as the value, lookup the abbreviation, or default to the abbreviation
    // otherwise, lookup the full name, or default to full name
    const stateValue = useAbbreviationAsValue
      ? Object.keys(stateCodeToName).find((key) => stateCodeToName[key] === value) || value
      : stateCodeToName[value] || value;

    const stateOptions = [];
    forEach(stateCodeToName, function (stateFullName, stateAbbreviation) {
      if (useAbbreviationAsValue) {
        stateOptions.push(
          <option value={stateAbbreviation} key={`state-${stateAbbreviation}`}>
            {stateFullName}
          </option>
        );
      } else {
        stateOptions.push(
          <option value={stateFullName} key={`state-${stateFullName}`}>
            {stateFullName}
          </option>
        );
      }
    });

    return (
      <select
        className={className}
        disabled={disabled}
        autoComplete="address-level1"
        onChange={onChange}
        value={stateValue}
      >
        <option value="">-- Select --</option>
        {stateOptions}
      </select>
    );
  },
});

export default SelectState;
