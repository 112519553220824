import 'whatwg-fetch';
import uniq from 'lodash/uniq';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';
/*!
 * Synchronous actions
 */

export const SET_USER_RESULTS = 'SET_USER_RESULTS';

export function setUserResults(payload) {
  return {
    type: SET_USER_RESULTS,
    payload,
  };
}

/*!
 * Async thunks
 */

export function userSearch(searchBy, searchTerm) {
  return async (dispatch) => {
    let results;
    try {
      results = await fetch(`/api/v1/users?${searchBy}=${searchTerm}`, {
        method: 'GET',
        credentials: 'include',
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      return;
    }
    dispatch(setUserResults(results));
  };
}

/**
 * Simply fetch a particular user's accounts without updating any state. Not sure
 * this belongs in an Action Creators file.
 *
 * @return {Promise<Array<Account>>} where each account has an array `userRoles`
 */
export function getUserAccountsAndRoles(userId) {
  return async (dispatch) => {
    let roles;
    try {
      roles = await fetch(`/api/v1/users/${userId}/role-info`, {
        method: 'GET',
        credentials: 'include',
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      return;
    }
    const hasStarAccount = roles.find((role) => role.accountId === '*');
    const accountIdList = uniq(
      roles.filter((role) => role.accountId !== '*').map((role) => role.accountId)
    );
    return Promise.all(
      accountIdList.map((accountId) =>
        fetch(`/api/v1/accounts/${accountId}`, {
          method: 'GET',
          credentials: 'include',
        }).then(handleAPI)
      )
    ).then((accounts) => {
      // add all the accounts to the account object
      if (hasStarAccount) {
        accounts.push({ id: '*' });
      }
      roles.forEach((role) => {
        const account = accounts.find((a) => a.id === role.accountId);
        if (account) {
          account.userRoles = account.userRoles || [];
          account.userRoles.push(role);
        }
      });
      return accounts;
    });
  };
}
