module.exports = [
  'rcf',
  'did-reconciliation-customer',

  // Product flags

  'clickvox',
  'hosted-switchvox',
  'sip-trunking',
];
