import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import Modal from '../../../../components/Modal/Modal';
import { importTrunk } from '../../../../actions/productActions';

const ImportTrunkModal = createReactClass({
  propTypes: {
    products: PropTypes.object,
    subscriptionNumber: PropTypes.string,
    subscription: PropTypes.object,
    sipSwitch: PropTypes.object,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
  },
  getInitialState() {
    const { sipSwitch = {}, subscription = { entitlements: {} } } = this.props;
    let type = '';
    if (sipSwitch.type) {
      type = sipSwitch.type;
    } else if (subscription.entitlements.productSpecificEntitlements.channelized) {
      type = 'channelized';
    } else {
      type = 'unlimited';
    }
    let channelCount = 0;
    if (sipSwitch.channels) {
      channelCount = sipSwitch.channels;
    } else if (
      subscription.entitlements &&
      subscription.entitlements.productSpecificEntitlements &&
      subscription.entitlements.productSpecificEntitlements.numChannels
    ) {
      channelCount = subscription.entitlements.productSpecificEntitlements.numChannels;
    }
    let callingArea = sipSwitch.calling_area;
    // try using the subscription if the calling_area is not in the switch
    if (
      !sipSwitch.calling_area &&
      subscription.entitlements &&
      subscription.entitlements.productSpecificEntitlements
    ) {
      if (subscription.entitlements.productSpecificEntitlements.international) {
        callingArea = 'international';
      } else if (subscription.entitlements.productSpecificEntitlements.extendedArea) {
        callingArea = 'extended';
      } else if (subscription.entitlements.productSpecificEntitlements.us48) {
        callingArea = 'lower-48';
      }
    }
    return {
      processing: false,
      reason: 'Trunk reconciliation',
      username: sipSwitch.username || '',
      password: sipSwitch.password || '',
      callingArea,
      type,
      channels: channelCount,
    };
  },
  onPropChange(propName) {
    return (event) => {
      const newState = {
        [propName]: event.target.value,
      };
      this.setState(newState);
    };
  },
  importTrunk() {
    const doneProcessing = () =>
      this.setState({
        processing: false,
      });
    const { subscriptionNumber, products } = this.props;
    const { reason, username, password, callingArea, type, channels } = this.state;
    const productId = products.find((p) => p.subscriptionNumber === subscriptionNumber).productId;

    const dmsTrunkBody = {
      productId,
      subscriptionNumber,
      reason,
      username,
      password,
      callingArea,
      type,
      channels: parseInt(channels, 10),
    };

    this.setState({ processing: true });

    this.props
      .dispatch(importTrunk({ subscriptionNumber, dmsTrunkBody }))
      .then(() => {
        doneProcessing();
        this.props.closeAction();
      })
      .catch(doneProcessing);
  },
  render() {
    const { processing, reason, username, callingArea, type, channels } = this.state;
    const { products, subscriptionNumber, closeAction } = this.props;

    const productId = products.find((p) => p.subscriptionNumber === subscriptionNumber).productId;

    let trunkType = null;
    if (productId === 'hosted-switchvox') {
      trunkType = type;
    } else {
      trunkType = (
        <select className="form-control" value={type} onChange={this.onPropChange('type')}>
          <option value="channelized">channelized</option>
          <option value="unlimited">unlimited - metered</option>
        </select>
      );
    }

    const header = <h3>Import SIP Trunk</h3>;
    const body = (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-xs-4">Trunk Username</label>
          <div className="col-xs-7">
            <input
              className="form-control"
              value={username}
              type="text"
              required={true}
              minLength={3}
              maxLength={15}
              onChange={this.onPropChange('username')}
            />
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Calling Area</label>
          <div className="col-xs-7">
            <select
              className="form-control"
              value={callingArea}
              onChange={this.onPropChange('callingArea')}
            >
              <option value="lower-48">lower-48</option>
              <option value="extended">extended</option>
              <option value="international">international</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Trunk Metering</label>
          <div className="col-xs-7">{trunkType}</div>
        </div>
        {type === 'channelized' ? (
          <div className="form-group">
            <label className="col-xs-4">Channels</label>
            <div className="col-xs-7">
              <input
                className="form-control"
                value={channels}
                type="number"
                step="1"
                min="0"
                max="1000"
                onChange={this.onPropChange('channels')}
              />
            </div>
          </div>
        ) : null}
        <div className="form-group">
          <label className="col-xs-4">Reason for importing</label>
          <div className="col-xs-7">
            <input
              className="form-control"
              value={reason}
              type="text"
              required={true}
              onChange={this.onPropChange('reason')}
            />
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={processing} onClick={this.importTrunk}>
          {processing ? 'Importing...' : 'Import'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps({ products }) {
  return { products };
}

export default connect(mapStateToProps)(ImportTrunkModal);
