import * as actions from '../actions/sessionActions';

const initialState = null; // null means it never loaded yet

export default function sessionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SESSION:
      if (!action.payload) {
        return {}; // resets user
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
