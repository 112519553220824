import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logout } from '../../../../actions/sessionActions';
import { accountSearch } from '../../../../actions/adminAccountSearchActions';
import { clearNotifs } from '../../../../actions/notifActions';
import constants from '../../../../lib/constants';

const AccountSearch = createReactClass({
  getInitialState() {
    return {
      searchBy: '',
      searchTerm: '',
      page: 1,
    };
  },
  onTextChange(event) {
    this.setState({
      searchTerm: event.target.value,
      page: 1,
    });
  },
  setSearchBy(searchBy) {
    const oldValue = this.state.searchBy;
    const newValue = oldValue === searchBy ? '' : searchBy;
    this.setState({ searchBy: newValue, page: 1, searchTerm: '' });
    if (newValue) {
      // react needs one round to turn off the disabled attribute before we can focus it
      setTimeout(() => this.searchTermInput.focus(), 50);
    }
  },
  handleSearchEnter(e) {
    if (e.key === 'Enter') {
      this.search();
    }
  },
  search() {
    const { searchBy, searchTerm, page } = this.state;
    this.props.dispatch(clearNotifs());
    this.props.dispatch(accountSearch(searchBy, searchTerm, page));
  },
  prevPage() {
    const { searchBy, searchTerm } = this.state;
    let page = this.state.page;
    page--;
    if (page < 1) {
      page = 1;
    }
    this.setState({ page });
    this.props.dispatch(accountSearch(searchBy, searchTerm, page));
  },
  nextPage() {
    const { searchBy, searchTerm } = this.state;
    let page = this.state.page;
    page++;
    this.setState({ page });
    this.props.dispatch(accountSearch(searchBy, searchTerm, page));
  },
  logout() {
    this.props.dispatch(logout());
  },
  render() {
    const {
      /**
       * @type {AccountPermissions}
       */
      accountPermissions,
      results: { pagination, items },
      accounts,
    } = this.props;
    const { searchBy, searchTerm } = this.state;
    if (!accountPermissions.selectAnyAccount) {
      return <p>No permission to search for accounts.</p>;
    }

    let paginationUI = null;
    if (items && items.length) {
      paginationUI = (
        <p>
          <button
            disabled={pagination.page <= 1}
            className="btn btn-success"
            onClick={this.prevPage}
          >
            Prev
          </button>
          &nbsp;
          {pagination.page} / {pagination.pageCount}
          &nbsp;
          <button
            disabled={pagination.page >= pagination.pageCount}
            className="btn btn-success"
            onClick={this.nextPage}
          >
            Next
          </button>
        </p>
      );
    }

    return (
      <div className="row">
        <div className="col-xs-3">
          <h4>All Accounts</h4>
          <p>
            <button className="btn btn-success btn-block" onClick={this.search}>
              Find Accounts
            </button>
          </p>
          <h5>Search Filters</h5>
          <p className="animated fadeIn">
            <input
              className="form-control input-sm"
              placeholder={!searchBy ? 'Select a search filter' : ''}
              disabled={!searchBy}
              onKeyUp={this.handleSearchEnter}
              ref={(input) => {
                this.searchTermInput = input;
              }}
              onChange={this.onTextChange}
              value={searchTerm}
            />
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'zuoraAccountId' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('zuoraAccountId')}
            >
              Zuora ID
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'name' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('name')}
            >
              Name
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'sfAccountId' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('sfAccountId')}
            >
              Salesforce ID
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'sipUsername' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('sipUsername')}
            >
              SIP Trunk Username
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'phoneNumber' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('phoneNumber')}
            >
              DCS Managed Phone Number
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'accountId' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('accountId')}
            >
              Biz Objects ID
            </button>
          </p>
        </div>
        <div className="col-xs-9">
          <table className="table table-condensed table-hover">
            <thead>
              <tr>
                <th />
                <th>Name</th>
                <th>Zuora Acct ID</th>
                <th>Salesforce Acct ID</th>
                <th>Biz Objects ID</th>
              </tr>
            </thead>
            <tbody>
              {!items || !items.length ? (
                <tr>
                  <td colSpan="5">Use the filters to list accounts</td>
                </tr>
              ) : null}
              {items &&
                items.map((r, i) => (
                  <tr key={r.id}>
                    <td>{i + 1}.</td>
                    <td>
                      <Link to={`/accounts/${r.id}`}>
                        {r.name || (accounts[r.id] ? accounts[r.id].name : '(none)')}
                      </Link>
                    </td>
                    <td>
                      <a
                        href={`${constants.ZUORA_URL}/apps/CustomerAccount.do?method=view&id=${r.zuoraAccountId}`}
                        target="_blank"
                      >
                        {r.zuoraAccountId}
                      </a>
                    </td>
                    <td>
                      <a href={`${constants.SALESFORCE_URL}/${r.sfAccountId}`} target="_blank">
                        {r.sfAccountId}
                      </a>
                    </td>
                    <td>{r.id}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          {paginationUI}
        </div>
      </div>
    );
  },
});

AccountSearch.propTypes = {
  dispatch: PropTypes.func,
  accountPermissions: PropTypes.object,
  results: PropTypes.object,
  accounts: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    accountPermissions: state.accountPermissions,
    results: state.adminAccountSearch,
    accounts: state.accounts,
  };
}

export default withRouter(connect(mapStateToProps)(AccountSearch));
