import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import { removeNotif } from '../../actions/notifActions';

require('./notifications.less');

const Notifications = createReactClass({
  propTypes: {
    notifs: PropTypes.array,
    dispatch: PropTypes.func,
  },
  render() {
    const { notifs, dispatch } = this.props;
    if (!notifs.length) {
      return null;
    }
    return (
      <div className="notifications align-center">
        {notifs.map((n, index) => (
          <div key={`notif-${index}`} className="notif animated fadeInDown">
            <div className={n.action ? 'clickable' : ''} onClick={n.action}>
              {n.notif}
            </div>
            <button className="btn btn-xs btn-primary" onClick={() => dispatch(removeNotif(index))}>
              &times;
            </button>
          </div>
        ))}
      </div>
    );
  },
});

function mapStateToProps(state) {
  return {
    notifs: state.notifs,
  };
}

export default withRouter(connect(mapStateToProps)(Notifications));
