import * as actions from '../actions/rcfActions';

// null means not loaded
const initialState = null; // []

export default function rcfReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLEAR_RULES:
      return null;
    case actions.SET_RULES:
      return [...action.payload];
    case actions.SET_RULE: {
      const currentRuleIndex = state.findIndex((rule) => rule.id === action.payload.id);
      const newState = [...state];
      if (!action.payload.numbers) {
        // guard against changes in the API from DMS, as this causes UI crashes before
        action.payload.numbers = [];
      }
      if (currentRuleIndex >= 0) {
        newState[currentRuleIndex] = action.payload;
      } else {
        newState.push(action.payload);
      }
      return newState;
    }
    default:
      return state;
  }
}
