import React from 'react'; // eslint-disable-line no-unused-vars

// These regular expressions may be out of date, but they occasionally work
const upsRegEx = /\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/i; // eslint-disable-line max-len
const fedexRegEx = /\b((96\d\d\d\d\d ?\d\d\d\d|96\d\d) ?\d\d\d\d ?d\d\d\d( ?\d\d\d)?)\b/i;
const uspsRegEx = /\b(91\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d|91\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d ?\d\d\d\d)\b/i; // eslint-disable-line max-len

const UPS = 'https://wwwapps.ups.com/etracking/tracking.cgi?tracknum=';
const FEDEX = 'https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=';
const USPS = 'https://tools.usps.com/go/TrackConfirmAction.action?tLabels=';

/**
 * Returns the tracking link URL for the order.
 *
 * Note: Lifted from the previous my.digiumcloud.com and refactored
 *
 * @param {string} trackingNumber
 * @return {string} url
 */
export function trackingLink(trackingNumber) {
  // I have seen empty spaces in the tracking link
  trackingNumber = (trackingNumber || '').trim();

  if (!trackingNumber) {
    return '';
  }

  // WHAT CAN BROWN DO FOR YOU
  if (upsRegEx.test(trackingNumber)) {
    return `${UPS}${trackingNumber}`;
  }

  // THE WORLD ON TIME
  if (
    fedexRegEx.test(trackingNumber) ||
    trackingNumber.length === 12 ||
    trackingNumber.length === 15
  ) {
    return `${FEDEX}${trackingNumber}`;
  }

  /*
     Neither snow nor rain nor heat nor gloom of night stays these couriers from the swift
     completion of their appointed rounds.
    */
  if (
    uspsRegEx.test(trackingNumber) ||
    // Global Express Guaranteed
    // 82 000 000 00
    (trackingNumber.length === 10 && trackingNumber[0] === '8') ||
    // 9n0n n000 0000 0000 0000 00
    (trackingNumber.length === 22 && trackingNumber[0] === '9')
  ) {
    return `${USPS}${trackingNumber}`;
  }

  // default to FedEx as it appears to be the default for cloud orders
  return `${FEDEX}${trackingNumber}`;
}

/**
 * Returns the shipping carrier for the order.
 *
 * This is intended to fix some orders where the carrier as marked as one company but
 * the tracking number indicates it's actually been shipped using a different carrier.
 *
 * @param {Array.<string>} trackingNumbers
 * @param {string} originalServiceHint - "USPS" or "UPS Ground" or "FedEx"
 * @return {string} carrier
 */
export function carrier(trackingNumbers, originalServiceHint) {
  // Since we know the carrier hint may be overridden the best we can do is try to guess it by the
  // first tracking number in the list
  const trackingNumber = (trackingNumbers[0] || '').trim();
  const serviceHint = (originalServiceHint || '').toLowerCase().trim();

  if (!trackingNumber) {
    return originalServiceHint;
  }

  // WHAT CAN BROWN DO FOR YOU
  if (upsRegEx.test(trackingNumber) && !serviceHint.includes('ups')) {
    return 'UPS';
  }

  // THE WORLD ON TIME
  if (
    (fedexRegEx.test(trackingNumber) ||
      trackingNumber.length === 12 ||
      trackingNumber.length === 15) &&
    !serviceHint.includes('fedex')
  ) {
    return 'FedEx';
  }

  /*
     Neither snow nor rain nor heat nor gloom of night stays these couriers from the swift
     completion of their appointed rounds.
    */
  if (
    (uspsRegEx.test(trackingNumber) ||
      // Global Express Guaranteed
      // 82 000 000 00
      (trackingNumber.length === 10 && trackingNumber[0] === '8') ||
      // 9n0n n000 0000 0000 0000 00
      (trackingNumber.length === 22 && trackingNumber[0] === '9')) &&
    !serviceHint.includes('usps')
  ) {
    return 'USPS';
  }

  // appears in order, leave as is
  return originalServiceHint;
}
