import 'whatwg-fetch';
import { setUIPerms } from './uipermsActions';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';
import { resyncAccountNames } from './accountsActions';

/*!
 * Synchronous actions
 */

export const SET_SESSION = 'SET_SESSION';

export function setSession(user) {
  return {
    type: SET_SESSION,
    payload: user,
  };
}

/*!
 * Async thunks
 */

/**
 * Logout the session User and redirect appropriately.
 *
 * @param params
 * @returns {Function}
 */
export function logout(params = {}) {
  const { returnPath = '' } = params;
  return async (dispatch) => {
    try {
      const { logoutUrl } = await fetch(`/api/v1/current-user?returnPath=${returnPath}`, {
        method: 'DELETE',
        credentials: 'include',
      }).then(handleAPI);

      // Sends them to the SAML logout screen, which upon finished will show them a login screen.
      // But if the log in using it, they will go to my.digium.com instead of here :(
      if (logoutUrl) {
        window.open(logoutUrl, '_self');
        return;
      }
      // unlikely to land here, but...
      // will reload entire app, redirect them to SAML login, then plop them right back
      // on the same page
      window.open(window.location.pathname, '_self');
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

/**
 * Fetches the session User only. This method does not calculate front-end permissions or fetch
 * accounts details for the User.
 *
 * @returns {Function}
 */
export function fetchSessionUserOnly() {
  return async (dispatch) => {
    try {
      const user = await fetch('/api/v1/current-user', {
        credentials: 'include',
      }).then(handleAPI);
      dispatch(setSession(user));
      return user;
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

/**
 * Refreshes the User on the back-end and returns the updated User instance.
 *
 * Can be used to recalculate permissions.
 *
 * @returns {Function}
 */
export function refreshSessionUserOnly() {
  return async (dispatch) => {
    try {
      const user = await fetch('/api/v1/current-user', {
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
      }).then(handleAPI);
      dispatch(setSession(user));
      return user;
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

/**
 * Fetches the session User as well as calculates front-end permissions and fetches accounts details
 * for the User.
 *
 * @returns {Function}
 */
export function fetchInitialSession() {
  return async (dispatch, getProps) => {
    await dispatch(fetchSessionUserOnly());
    const {
      selectedAccount,
      session: { roles },
    } = getProps();
    dispatch(setUIPerms(selectedAccount, roles));
    dispatch(resyncAccountNames());
  };
}
