import 'whatwg-fetch';
import handleAPI from '../lib/handleInternalAPI';
import notifyError from '../lib/notifyError';
import { addNotif, clearNotifs } from './notifActions';
import history from '../history';

/*!
 * Synchronous actions
 */

export const CLEAR_RULES = 'CLEAR_RULES';
export function clearRules(payload) {
  return {
    type: CLEAR_RULES,
    payload,
  };
}

export const SET_RULES = 'SET_RULES';
export function setRules(payload) {
  return {
    type: SET_RULES,
    payload,
  };
}

export const SET_RULE = 'SET_RULE';
export function setSingleRule(payload) {
  return {
    type: SET_RULE,
    payload,
  };
}

/*!
 * Async thunks
 */

export function fetchAllRcfRules() {
  return async (dispatch, getState) => {
    const { selectedAccount, accountPermissions } = getState();
    const result = await fetch(`/api/v1/accounts/${selectedAccount.id}/rcf-rules`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    dispatch(setRules(result));
    const enabled = result.filter((r) => r.enabled);
    if (enabled.length && accountPermissions.manageRCF) {
      const notifText = 'Remote call forwarding enabled - click for details';
      const alreadyHas = getState().notifs.find((n) => n === notifText);
      if (!alreadyHas) {
        const action = () => {
          // navigate to the first product with an enabled rule when the notif is clicked
          const firstRule = enabled[0];
          const { products } = getState();
          const product = products.find((p) => firstRule.subscriptionId === p.subscriptionNumber);
          if (product) {
            history.push(`/accounts/${selectedAccount.id}/${product.subscriptionNumber}/rcf`);
          }
        };
        dispatch(addNotif(notifText, action));
      }
    }
  };
}

export function createRcfRule(ruleProps) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    const result = await fetch(`/api/v1/accounts/${selectedAccount.id}/rcf-rules`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(ruleProps),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    dispatch(setSingleRule(result));
  };
}

export function updateRcfRule(ruleProps) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    const result = await fetch(`/api/v1/accounts/${selectedAccount.id}/rcf-rules/${ruleProps.id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(ruleProps),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    dispatch(setSingleRule(result));
  };
}

export function removeRcfRule(ruleId) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    await fetch(`/api/v1/accounts/${selectedAccount.id}/rcf-rules/${ruleId}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then((httpRes) => {
        if (httpRes.status < 200 || httpRes.status > 399) {
          return handleAPI(httpRes);
        }
      })
      .catch(notifyError(dispatch)); // will rethrow

    dispatch(clearNotifs());
    // refetch all
    dispatch(fetchAllRcfRules());
  };
}
