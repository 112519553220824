import React from 'react';

export default function LoginRoute(props) {
  let redirect = encodeURIComponent(props.location.pathname);
  if (redirect === '%2Flogin') {
    redirect = '%2F';
  }

  return (
    <section className="animated fadeIn" style={{ textAlign: 'center' }}>
      <h1>Digium Cloud Services</h1>
      <br />
      <p>
        <a href={`/auth/saml?continue=${redirect}`} className="btn btn-primary btn-lg">
          DCS Customer Sign In
        </a>
      </p>
      <br />
      <br />
      <p>
        <a href={`/auth/google?continue=${redirect}`}>Digium Employee Sign In</a>
      </p>
    </section>
  );
}
