import 'whatwg-fetch';
import qs from 'qs';
import get from 'lodash/get';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';
import { refreshSessionUserOnly } from './sessionActions';
import history from '../history';

export const SET_CHECKOUT_QUOTE_CREATION_DATA = 'SET_CHECKOUT_QUOTE_CREATION_DATA';
export function setCheckoutQuoteCreationData(payload) {
  return {
    type: SET_CHECKOUT_QUOTE_CREATION_DATA,
    payload,
  };
}

export const SET_CHECKOUT_ORDER = 'SET_CHECKOUT_ORDER';
export function setCheckoutOrder(payload) {
  return {
    type: SET_CHECKOUT_ORDER,
    payload,
  };
}

export const SET_CHECKOUT_QUOTE_METADATA = 'SET_CHECKOUT_QUOTE_METADATA';
export function setQuoteMetadata(payload) {
  return {
    type: SET_CHECKOUT_QUOTE_METADATA,
    payload,
  };
}

export const SET_CHECKOUT_QUOTE_ACCOUNT = 'SET_CHECKOUT_QUOTE_ACCOUNT';
export function setQuoteAccount(payload) {
  return {
    type: SET_CHECKOUT_QUOTE_ACCOUNT,
    payload,
  };
}

export const SET_CHECKOUT_QUOTE = 'SET_CHECKOUT_QUOTE';
export function setCheckoutQuote(payload) {
  return {
    type: SET_CHECKOUT_QUOTE,
    payload,
  };
}

export const SET_CHECKOUT_QUOTE_PAYMENT = 'SET_CHECKOUT_QUOTE_PAYMENT';
export function setCheckoutPayment(payload) {
  return {
    type: SET_CHECKOUT_QUOTE_PAYMENT,
    payload,
  };
}

export const SET_CHECKOUT_QUOTE_SHIPPING_CONTACT = 'SET_CHECKOUT_QUOTE_SHIPPING_CONTACT';
export function setQuoteShippingContact(payload) {
  return {
    type: SET_CHECKOUT_QUOTE_SHIPPING_CONTACT,
    payload,
  };
}

export const SET_CHECKOUT_QUOTE_SHIPPING_RATES = 'SET_CHECKOUT_QUOTE_SHIPPING_RATES';
export function setQuoteShippingRates(payload) {
  return {
    type: SET_CHECKOUT_QUOTE_SHIPPING_RATES,
    payload,
  };
}

export const SET_CHECKOUT_QUOTE_SELECTED_SHIPPING_RATE =
  'SET_CHECKOUT_QUOTE_SELECTED_SHIPPING_RATE';
export function setCheckoutShippingRate(shippingRateCode) {
  return {
    type: SET_CHECKOUT_QUOTE_SELECTED_SHIPPING_RATE,
    payload: shippingRateCode,
  };
}

/* async Thunky-Thunks (officially known as Thunk Thunkbergs) */

export function createAccount(quoteId, user, card) {
  return async (dispatch, getProps) => {
    const newAccount = {
      quoteId,
      user,
      card: {
        creditCardType: card.creditCardType,
        creditCardHolderName: card.cardHolderInfo.cardHolderName,
        creditCardNumber: card.creditCardNumber,
        expirationMonth: card.expirationMonth,
        expirationYear: card.expirationYear,
        securityCode: card.securityCode,
      },
      address: {
        address1: card.cardHolderInfo.addressLine1,
        address2: card.cardHolderInfo.addressLine2,
        city: card.cardHolderInfo.city,
        country: card.cardHolderInfo.country,
        state: card.cardHolderInfo.state,
        zipCode: card.cardHolderInfo.zipCode,
      },
    };

    try {
      await fetch(`/api/v1/accounts/for-quote/${quoteId}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(newAccount),
      }).then(handleAPI);

      const { session } = getProps();
      // if a logged in user is creating an account, refresh their session before redirecting
      // to ensure they have the correct permissions to checkout the quote
      if (session.email) {
        await dispatch(refreshSessionUserOnly());
      }
    } catch (err) {
      notifyError(dispatch)(err);
    }

    dispatch(fetchQuoteMetadata(quoteId));
  };
}

export function fetchQuoteMetadata(quoteId) {
  return async (dispatch) => {
    try {
      const apiResult = await fetch(`/api/v1/customers/quotes/${quoteId}/metadata`, {
        method: 'GET',
        credentials: 'include',
        cache: 'no-cache',
      });

      const metadata = await handleAPI(apiResult);
      dispatch(setQuoteMetadata(metadata));
    } catch (err) {
      if (err.status === 404) {
        dispatch(setQuoteMetadata({ found: false }));
        return;
      }

      notifyError(dispatch)(err);
    }
  };
}

export function fetchQuoteOrder(accountId, orderId) {
  return async (dispatch) => {
    const order = await fetch(`/api/v1/accounts/${accountId}/orders/${orderId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    dispatch(setCheckoutOrder(order));
  };
}

export function fetchQuoteAccount(accountId) {
  return async (dispatch) => {
    const bosAccount = await fetch(`/api/v1/accounts/${accountId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setQuoteAccount(bosAccount));
  };
}

export function fetchQuoteAccountPayment(accountId) {
  return async (dispatch) => {
    const creditCards = await fetch(`/api/v1/accounts/${accountId}/credit-cards`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    const paymentMethod = creditCards.find((v) => v.defaultPaymentMethod);
    dispatch(setCheckoutPayment(paymentMethod));
  };
}

export function fetchCheckoutQuote(quoteId) {
  return async (dispatch) => {
    const quote = await fetch(`/api/v1/customers/quotes/${quoteId}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setCheckoutQuote(quote));
  };
}

export function fetchCheckoutQuoteCreationData(quoteId) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/customers/quotes/${quoteId}/creation-data`;
      const response = await fetch(url, { credentials: 'include' });
      const quoteCreationData = await handleAPI(response);
      dispatch(setCheckoutQuoteCreationData(quoteCreationData));
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

export function addQuoteCard(card) {
  return async (dispatch, getState) => {
    const { quoteCheckout } = getState();
    const accountId = quoteCheckout.account.id;
    await fetch(`/api/v1/accounts/${accountId}/credit-cards`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(card),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(fetchQuoteAccountPayment(accountId));
  };
}

export function createCheckoutOrder(quoteId, shippingMethod) {
  return async (dispatch) => {
    const result = await fetch('/api/v1/customers/orders', {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ quoteId, shippingMethod }),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setCheckoutOrder(result));
    history.push(`/quote-checkout/${quoteId}/thanks`);
  };
}

export function updateQuoteBillingContact(contact) {
  return async (dispatch, getState) => {
    const { quoteCheckout } = getState();
    const accountId = quoteCheckout.account.id;
    const updatedAccount = await fetch(`/api/v1/accounts/${accountId}/billing-contact`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setQuoteAccount(updatedAccount));
  };
}

export function fetchQuoteShippingContact(accountId) {
  return async (dispatch) => {
    const apiResult = await fetch(`/api/v1/accounts/${accountId}/shipping-contact`, {
      method: 'GET',
      credentials: 'include',
    });

    // No shipping contact is okay-ish; we'll just prompt the user to create one
    if (apiResult.status === 404) {
      dispatch(setQuoteShippingContact(null));
      return;
    }

    const shippingContact = await handleAPI(apiResult);
    dispatch(setQuoteShippingContact(shippingContact));
  };
}

export function updateQuoteShippingContact(accountId, contact) {
  return async (dispatch) => {
    const updatedContact = await fetch(`/api/v1/accounts/${accountId}/shipping-contact`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setQuoteShippingContact(updatedContact));
    dispatch(setQuoteShippingRates(null));
  };
}

export function fetchQuoteShippingRates(shippingContact, accountName, shippingWeight) {
  return async (dispatch) => {
    const country = get(shippingContact, 'address.country');
    const params = {
      contactFirstName: shippingContact.firstName,
      contactLastName: shippingContact.lastName,
      contactCompanyName: accountName,
      contactStreet1: get(shippingContact, 'address.address1'),
      contactStreet2: get(shippingContact, 'address.address2'),
      contactCity: get(shippingContact, 'address.city'),
      contactState: get(shippingContact, 'address.state'),
      contactZipCode: get(shippingContact, 'address.zipCode'),
      contactCountryCode: country === 'United States' ? 'US' : country,
      shippingWeight,
    };
    const paramsString = qs.stringify(params);
    const apiResult = await fetch(`/api/v1/shipping-rates?${paramsString}`, {
      method: 'GET',
      credentials: 'include',
    });

    const shippingRates = await handleAPI(apiResult);
    dispatch(setQuoteShippingRates(shippingRates));
  };
}
