import React from 'react';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import isNumber from 'lodash/isNumber';
import UpdateTrunkModal from './UpdateTrunkModal';

const TrunkDetails = createReactClass({
  getInitialState() {
    const { trunk } = this.props;
    const state = {
      editModal: null,
    };

    if (trunk) {
      state.type = trunk.type;
      state.channels = trunk.channels || '';
      state.callingArea = trunk.callingArea;
      state.password = '';
      state.enabled = trunk.enabled;
    }

    return state;
  },

  save() {
    const { type, channels, callingArea, password } = this.state;
    const {
      trunk,
      product,
      session: { email },
    } = this.props;
    const trunkUpdateBody = { middleOut: true };

    if (callingArea !== '') {
      trunkUpdateBody.callingArea = callingArea;
    }
    if (type !== trunk.type) {
      trunkUpdateBody.type = type;
    }
    if (channels > 0 && type === 'channelized') {
      trunkUpdateBody.type = type;
      trunkUpdateBody.channels = channels;
    }
    if (password !== '') {
      trunkUpdateBody.password = password;
    }

    this.setState({
      editModal: (
        <UpdateTrunkModal
          email={email}
          subscriptionNumber={product.subscriptionNumber}
          accountId={product.accountId}
          trunkId={trunk.id}
          trunkUpdateBody={trunkUpdateBody}
          closeAction={() => this.setState({ editModal: null, password: '' })}
        />
      ),
    });
  },

  componentWillReceiveProps(nextProps) {
    const { trunk } = nextProps;

    if (trunk) {
      this.setState({
        ...this.state,
        type: trunk.type,
        channels: trunk.channels || '',
        callingArea: trunk.callingArea,
        password: '',
        enabled: trunk.enabled,
      });
    }
  },

  onPropChange(propName) {
    return (event) => {
      const newValue = event.target.value;
      const newState = { ...this.state };

      set(newState, propName, newValue);
      this.setState(newState);
    };
  },

  onChannelsChange() {
    return (event) => {
      const newValue = parseInt(event.target.value, 10);
      this.onPropChange('channels')({ target: { value: newValue || '' } });
    };
  },

  render() {
    const { trunk, product, sipSwitch, entitlements } = this.props;
    const { type, channels, callingArea, password, editModal, enabled } = this.state;

    if (!trunk) {
      return <p>Loading SIP trunk info...</p>;
    }

    const callingAreaMismatchedSwitch = sipSwitch && trunk.callingArea !== sipSwitch.calling_area;
    const callingAreaMismatchedEntitlements =
      entitlements &&
      ((trunk.callingArea === 'international' && !entitlements.international) ||
        (trunk.callingArea === 'extended' && !entitlements.extendedArea));
    const callingAreaMismatched = callingAreaMismatchedSwitch || callingAreaMismatchedEntitlements;

    const channelsMismatchedSwitch =
      sipSwitch && isNumber(sipSwitch.channels) && trunk.channels !== sipSwitch.channels;
    const channelsMismatchedEntitlements =
      entitlements && entitlements.channelized && entitlements.numChannels !== trunk.channels;
    const channelsMismatched = channelsMismatchedSwitch || channelsMismatchedEntitlements;

    const typeMismatchedSwitch = sipSwitch && sipSwitch.type !== trunk.type;
    const typeMismatchedEntitlements = type && type !== trunk.type;
    const typeMismatched = typeMismatchedSwitch || typeMismatchedEntitlements;

    let errorDisplay = null;
    if (channelsMismatched || callingAreaMismatched || typeMismatched) {
      const callingAreaErrorText = `
                ${callingAreaMismatched ? 'Mismatched Calling Area (' : ''}
                ${callingAreaMismatchedSwitch ? 'Switch' : ''}
                ${callingAreaMismatchedSwitch && callingAreaMismatchedEntitlements ? ', ' : ''}
                ${callingAreaMismatchedEntitlements ? 'Subscription' : ''}
                ${callingAreaMismatched ? ')' : ''}
            `;
      const channelsErrorText = `
                ${channelsMismatched ? 'Mismatched Channel Count (' : ''}
                ${channelsMismatchedSwitch ? 'Switch' : ''}
                ${channelsMismatchedSwitch && channelsMismatchedEntitlements ? ', ' : ''}
                ${channelsMismatchedEntitlements ? 'Subscription' : ''}
                ${channelsMismatched ? ')' : ''}
            `;
      const typeErrorText = `
                ${typeMismatched ? 'Mismatched Trunk Type (' : ''}
                ${typeMismatchedSwitch ? 'Switch' : ''}
                ${typeMismatchedSwitch && typeMismatchedEntitlements ? ', ' : ''}
                ${typeMismatchedEntitlements ? 'Subscription' : ''}
                ${typeMismatched ? ')' : ''}
            `;
      errorDisplay = (
        <div>
          <p className="alert alert-danger">
            {callingAreaErrorText}
            {callingAreaMismatched ? <br /> : null}
            {channelsErrorText}
            {channelsMismatched ? <br /> : null}
            {typeErrorText}
          </p>
        </div>
      );
    }

    let trunkType = null;
    if (product.productId === 'hosted-switchvox') {
      trunkType = type;
    } else {
      trunkType = (
        <select value={type} onChange={this.onPropChange('type')}>
          <option value="channelized">channelized</option>
          <option value="unlimited">unlimited</option>
        </select>
      );
    }

    const trunkImported = !isEmpty(trunk);

    // if trunk has not been imported yet, show a message indicating this
    return trunkImported ? (
      <div className="product-details">
        {editModal}
        {errorDisplay}
        <div>
          <label>Trunk Type:</label>
          <p>{trunkType}</p>
        </div>
        <div>
          <label>Username:</label>
          <p>{trunk.username || 'unknown'}</p>
        </div>
        <div>
          <label>New Password:</label>
          <p>
            <input
              type="text"
              className="form-control"
              value={password}
              onChange={this.onPropChange('password')}
            ></input>
          </p>
        </div>
        <div>
          <label>Calling Area:</label>
          <p>
            <select value={callingArea} onChange={this.onPropChange('callingArea')}>
              <option value="">Unknown</option>
              <option value="lower-48">Lower 48</option>
              <option value="extended">Extended Area</option>
              <option value="international">International</option>
            </select>
          </p>
        </div>
        <div>
          <label>Channels:</label>
          <p>
            <input
              type="text"
              className="form-control"
              value={channels}
              onChange={this.onChannelsChange()}
              disabled={type !== 'channelized'}
            ></input>
          </p>
        </div>
        <div>
          <label>Trunk ID:</label>
          <p>{trunk.id || 'unknown'}</p>
        </div>
        <div style={{ height: '28px' }}>
          <button
            disabled={!enabled}
            className="btn btn-xs btn-primary pull-right"
            onClick={this.save}
          >
            Apply
          </button>
        </div>
      </div>
    ) : (
      <div className="help-block attention-text" style={{ fontWeight: 700 }}>
        Trunk has not been created/imported yet, or there were errors fetching its data
      </div>
    );
  },
});

TrunkDetails.propTypes = {
  trunk: PropTypes.object,
  sipSwitch: PropTypes.object,
  entitlements: PropTypes.object,
  product: PropTypes.object,
};

// mapping products to re-render when a trunk is disabled
function mapStateToProps({ session, products }) {
  return { session, products };
}

export default connect(mapStateToProps)(TrunkDetails);
