import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditInvoiceRecipients from './EditInvoiceRecipients';
import EditContact from '../../../../../components/EditContact/EditContact';
import DisplayContact from '../../../../../components/DisplayContact/DisplayContact';
import { addCreditCard, updateBillingContact } from '../../../../../actions/billingActions';
import DisplayPaymentMethod from '../../../../../components/DisplayPaymentMethod';
import EditCreditCard from '../../../../../components/EditCreditCard/EditCreditCard';

const PaymentInfoRoute = createReactClass({
  getInitialState() {
    return { editModal: null };
  },
  killModal() {
    this.setState({ editModal: null });
  },
  editInvoiceRecipients() {
    this.setState({
      editModal: <EditInvoiceRecipients closeAction={this.killModal} />,
    });
  },
  editBillingAddress() {
    const { dispatch } = this.props;
    const save = (contact) => dispatch(updateBillingContact(contact));

    this.setState({
      editModal: (
        <EditContact
          closeAction={this.killModal}
          title="Edit Billing Address"
          save={save}
          contact={this.props.selectedAccount.zuoraAccount.billToContact}
        />
      ),
    });
  },
  addCard() {
    const { dispatch } = this.props;
    const currentCard = this.props.creditCards.find((v) => !!v.defaultPaymentMethod);
    const save = (card) => dispatch(addCreditCard(card));
    this.setState({
      editModal: (
        <EditCreditCard
          closeAction={this.killModal}
          card={currentCard}
          save={save}
          title="Payment Method"
          allowUsingBillToContactAddress={true}
        />
      ),
    });
  },
  render() {
    const creditCard = this.props.creditCards.find((v) => !!v.defaultPaymentMethod);
    const {
      selectedAccount: { zuoraAccount },
      accountPermissions,
    } = this.props;

    const { editModal } = this.state;

    let body;

    let creditCardBody = <p>No valid credit card found for this account.</p>;
    if (creditCard) {
      creditCardBody = <DisplayPaymentMethod type="CreditCard" paymentMethod={creditCard} />;
    }

    // ensure user has permission to view payment info
    if (accountPermissions.viewAccount) {
      if (zuoraAccount) {
        const { billingAndPayment, billToContact } = zuoraAccount;
        let invoiceRecipients = [<p key="primary-email-work">{billToContact.workEmail}</p>];
        if (billingAndPayment.additionalEmailAddresses) {
          invoiceRecipients = invoiceRecipients.concat(
            billingAndPayment.additionalEmailAddresses.map((email) => <p key={email}>{email}</p>)
          );
        }
        const editRecipientsButton = accountPermissions.manageAccount ? (
          <button className="btn btn-primary pull-right" onClick={this.editInvoiceRecipients}>
            Edit
          </button>
        ) : null;
        const editBillingAddressButton = accountPermissions.manageBilling ? (
          <button className="btn btn-primary pull-right" onClick={this.editBillingAddress}>
            Edit
          </button>
        ) : null;
        const editPaymentButton = accountPermissions.manageBilling ? (
          <button onClick={this.addCard} className="btn btn-primary pull-right">
            Edit
          </button>
        ) : null;

        body = (
          <div>
            {editModal}
            <div className="row">
              <div className="col-sm-6">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3>
                      Billing Contact
                      {editBillingAddressButton}
                    </h3>
                  </div>
                  <div className="panel-body">
                    <DisplayContact contact={billToContact} />
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3>
                      Additional Invoice Recipients
                      {editRecipientsButton}
                    </h3>
                  </div>
                  <div className="panel-body">{invoiceRecipients}</div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h3>
                      Payment Method
                      {editPaymentButton}
                    </h3>
                  </div>
                  <div className="panel-body">{creditCardBody}</div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        body = <p>Loading...</p>;
      }
    } else {
      body = <span className="alert alert-danger">You do not have access to this page</span>;
    }

    return (
      <div>
        <h1>Payment Info</h1>
        <br />
        {body}
      </div>
    );
  },
});

PaymentInfoRoute.propTypes = {
  dispatch: PropTypes.func,
  selectedAccount: PropTypes.object,
  creditCards: PropTypes.array,
  uiPermission: PropTypes.object,
};

function mapStateToProps({ selectedAccount, billing, accountPermissions }) {
  return {
    selectedAccount,
    accountPermissions,
    creditCards: billing.creditCards,
  };
}

export default connect(mapStateToProps)(PaymentInfoRoute);
