import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import set from 'lodash/set';
import createReactClass from 'create-react-class';
import Modal from '../../../../../components/Modal/Modal';
import ToggleSwitch from '../../../../../components/ToggleSwitch/ToggleSwitch';
import HelpIcon from '../../../../../components/HelpIcon/HelpIcon';
import {
  addUserPermission,
  createUserWithPermissions,
  fetchAccountUsers,
  removeUserPermissions,
} from '../../../../../actions/usersActions';
import notifyError from '../../../../../lib/notifyError';

const userHasRole = (user, roleName) => user.roles.find((r) => r.roleName === roleName);

const shouldDoViewToo = ['manage-products', 'manage-account'];

const tooltips = {
  'manage-users': (
    <HelpIcon text="Change permissions of any user on this account. Invite and remove users." />
  ),
  purchasing: <HelpIcon text="Buy new cloud hosted products for this account." />,
  'view-products': <HelpIcon text="See details about hosted services." />,
  'manage-products': (
    <HelpIcon text="See and change (where available) details on hosted services." />
  ),
  'view-account': <HelpIcon text="View orders, invoices, and payment information." />,
  'manage-account': (
    <HelpIcon text="View orders and invoices. Change invoice recipients and payment details." />
  ),
};

const permissionOption = (
  roleName,
  user,
  session,
  products,
  toggleFunc,
  processing,
  disabled = false
) => {
  const userHasSomewhatExactRole = userHasRole(user, roleName);
  let toggler;

  // Thou shalt refrain from removing thine own roaelse
  const ownPermissionBlocked = session.id === user.id && userHasSomewhatExactRole;
  if (ownPermissionBlocked) {
    toggler = <p>Enabled</p>;
  } else {
    toggler = (
      <ToggleSwitch
        onChange={() => toggleFunc({ roleName })}
        checked={!!userHasSomewhatExactRole}
        disabled={disabled || processing}
      />
    );
  }

  return (
    <div key={roleName} className="row form-group">
      <label
        className={`col-xs-6 control-label align-right capitalize ${disabled ? 'text-muted' : ''}`}
      >
        {roleName.replace(/-/g, ' ')}
      </label>
      <div className="col-xs-1" style={{ paddingTop: '6px' }}>
        {tooltips[roleName]}
      </div>
      <div className="col-xs-5" style={{ paddingTop: '6px' }}>
        {toggler}
      </div>
    </div>
  );
};

const AddEditUserPermissions = createReactClass({
  propTypes: {
    user: PropTypes.object,
    users: PropTypes.array,
    session: PropTypes.object,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
  },
  getInitialState() {
    let user;
    if (this.props.user) {
      user = { ...this.props.user };
      if (!user.roles) {
        user.roles = [];
      }
    } else {
      user = {
        firstName: '',
        lastName: '',
        email: '',
        roles: [], // careful about getting out of sync with props
      };
    }
    return {
      isNew: !this.props.user,
      processing: false,
      user,
      errors: {},
      showErrors: false,
    };
  },
  onPropChange(propName) {
    return (event) => {
      const user = { ...this.state.user };
      set(user, propName, event.target.value);
      this.setState({ user }, () => this.validate());
    };
  },
  // save creates a new user with these permissions
  save() {
    this.setState({ showErrors: true });
    if (!this.validate()) {
      return;
    }
    const { user } = this.state;
    const { closeAction, dispatch } = this.props;

    this.setState({ processing: true });

    dispatch(createUserWithPermissions(user))
      .then(() => dispatch(fetchAccountUsers()))
      .then(() => closeAction())
      .catch(() => this.setState({ processing: false }));
  },
  validate() {
    const errors = this.validateInput();
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  },
  validateInput() {
    const { firstName, lastName, email, roles } = this.state.user;
    const errors = {};

    if (!firstName) {
      errors.firstName = 'This is a required field';
    }
    if (!lastName) {
      errors.lastName = 'This is a required field';
    }
    if (!email) {
      errors.email = 'This is a required field';
    } else if (!/^$|^\S+@\S+\.\S+$/.test(email)) {
      // check if the email matches the format 'x@y.z'
      errors.email = 'Invalid email address';
    }
    if (!roles.length > 0) {
      errors.roles = 'You must add at least one permission.';
    }
    return errors;
  },
  addRole(role) {
    const { isNew, user } = this.state;
    user.roles.push({
      roleName: role.roleName,
    });
    this.setState({ user });

    if (isNew) {
      if (shouldDoViewToo.includes(role.roleName)) {
        const correspondingView = role.roleName.replace('manage-', 'view-');
        const hasView = user.roles.find((r) => r.roleName === correspondingView);
        if (!hasView) {
          this.addRole({
            roleName: correspondingView,
          });
        }
      }
      return;
    }

    this.setState({ processing: true });

    this.props
      .dispatch(addUserPermission(user.id, role))
      .then(() =>
        this.setState({
          processing: false,
          user: this.props.users.find((u) => u.id === user.id),
        })
      )
      .catch(() => this.setState({ processing: false }));
  },
  removeRole(role) {
    const { isNew, user } = this.state;
    const findRole = (ur) => ur.roleName === role.roleName;

    // new users should show updates immediately while editing should wait until the server says OK
    if (isNew) {
      const removeRoleIndex = user.roles.findIndex(findRole);
      const nextRoles = [...user.roles];
      nextRoles.splice(removeRoleIndex, 1);
      this.setState({
        user: {
          ...user,
          roles: nextRoles,
        },
      });
      return;
    }

    const userRoleToRemove = user.roles.find(findRole);

    this.setState({ processing: true });
    return this.props
      .dispatch(
        removeUserPermissions(user.id, {
          roleName: role.roleName,
          id: userRoleToRemove.id,
        })
      )
      .then((newRoleSet) => {
        this.setState({
          processing: false,
          user: this.props.users.find((u) => u.id === user.id),
        });
        return newRoleSet;
      })
      .catch(() => this.setState({ processing: false }));
  },
  toggleRole(role) {
    const { user } = this.state;
    const hasRole = user.roles.find((ur) => ur.roleName === role.roleName);
    if (hasRole) {
      this.removeRole(role);
    } else {
      this.addRole(role);
    }
  },
  async removeAllRoles() {
    const conf = window.confirm('Really remove all permissions on this account?');
    if (!conf) {
      return;
    }

    const { closeAction } = this.props;
    const { user } = this.state;
    const shouldRemoveCount = user.roles.length;
    const removalErrors = [];
    let removed = 0;
    let lastRoleSet = [];
    let userRole;
    for (let i = 0; i < shouldRemoveCount; i++) {
      userRole = user.roles[i];
      try {
        lastRoleSet = await this.removeRole(userRole);
        removed++;
      } catch (err) {
        console.warn({ err, userRole }, 'Silently failed to remove user role');
        removalErrors.push(err);
      }
    }
    if (lastRoleSet.length === 0) {
      console.log(
        { removalErrors },
        'All user roles appear to have been removed, so ignoring errors'
      );
      closeAction();
      return;
    }
    console.error({ removalErrors }, 'Some user roles failed to be removed');
    this.props.dispatch(
      notifyError(
        new Error(
          `${
            shouldRemoveCount - removed
          } permissions were not removed due to a system error. Please try again.`
        )
      )
    );
  },
  render() {
    const { user, isNew, processing, errors } = this.state;
    const { closeAction, session, users } = this.props;

    const shouldShowError = (errorVal) => this.state.showErrors && errorVal;

    const isSelf = session.id === user.id;

    const header = (
      <h3>
        {isNew ? 'Add New User Permissions' : `Edit Permissions for ${user.firstName}`}
        {!isNew && !isSelf ? (
          <button
            className="btn btn-warning btn-xs pull-right"
            disabled={processing}
            onClick={this.removeAllRoles}
          >
            Remove All Permissions...
          </button>
        ) : null}
      </h3>
    );

    const manageUsersPermission = permissionOption(
      'manage-users',
      user,
      session,
      users,
      this.toggleRole,
      processing
    );

    const purchasingPermission = permissionOption(
      'purchasing',
      user,
      session,
      users,
      this.toggleRole,
      processing
    );

    // view is implied by manage
    const viewProductsPermission = permissionOption(
      'view-products',
      user,
      session,
      users,
      this.toggleRole,
      processing,
      !!userHasRole(user, 'manage-products')
    );
    const manageProductsPermission = permissionOption(
      'manage-products',
      user,
      session,
      users,
      this.toggleRole,
      processing
    );

    // view is implied by manage
    const viewAccountPermission = permissionOption(
      'view-account',
      user,
      session,
      users,
      this.toggleRole,
      processing,
      !!userHasRole(user, 'manage-account')
    );
    const manageAccountPermission = permissionOption(
      'manage-account',
      user,
      session,
      users,
      this.toggleRole,
      processing
    );

    const body = (
      <div>
        {isNew ? (
          <div className="panel panel-default">
            <div className="panel-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <div className="help-block col-xs-6 col-xs-offset-4">
                    All fields below are required.
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-xs-4 control-label">First Name</label>
                  <div
                    className={`col-xs-6 ${shouldShowError(errors.firstName) ? 'has-error' : ''}`}
                  >
                    <input
                      className="form-control"
                      value={user.firstName}
                      type="text"
                      onChange={this.onPropChange('firstName')}
                    />
                    {shouldShowError(errors.firstName) && (
                      <span className={'help-block animated fadeIn'}>{errors.firstName}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-xs-4 control-label">Last Name</label>
                  <div
                    className={`col-xs-6 ${shouldShowError(errors.lastName) ? 'has-error' : ''}`}
                  >
                    <input
                      className="form-control"
                      value={user.lastName}
                      type="text"
                      onChange={this.onPropChange('lastName')}
                    />
                    {shouldShowError(errors.lastName) && (
                      <span className={'help-block animated fadeIn'}>{errors.lastName}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-xs-4 control-label">Email</label>
                  <div className={`col-xs-6 ${shouldShowError(errors.email) ? 'has-error' : ''}`}>
                    <input
                      className="form-control"
                      value={user.email}
                      type="email"
                      onChange={this.onPropChange('email')}
                    />
                    {shouldShowError(errors.email) && (
                      <span className={'help-block animated fadeIn'}>{errors.email}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="form-horizontal">
              <div className="row form-group">
                {!shouldShowError(errors.roles) && (
                  <div className="help-block col-xs-6 col-xs-offset-4">
                    {isNew ? 'You must add at least one permission.' : null}
                  </div>
                )}
                <div
                  className={`col-xs-6 col-xs-offset-4 ${
                    shouldShowError(errors.roles) ? 'has-error' : ''
                  }`}
                >
                  {shouldShowError(errors.roles) && (
                    <span className={'help-block animated fadeIn'}>{errors.roles}</span>
                  )}
                </div>
              </div>
              {manageUsersPermission}
              <br />
              {purchasingPermission}
              <br />
              {viewProductsPermission}
              {manageProductsPermission}
              <br />
              {viewAccountPermission}
              {manageAccountPermission}
            </div>
          </div>
        </div>
      </div>
    );

    const footer = (
      <div>
        <button className="btn btn-default" disabled={processing} onClick={closeAction}>
          {isNew ? 'Cancel' : processing ? 'Processing...' : 'Close'}
        </button>
        {isNew ? (
          <button className="btn btn-primary" disabled={processing} onClick={this.save}>
            {processing ? 'Processing...might take a while...' : 'Add'}
          </button>
        ) : null}
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps(state) {
  return {
    products: state.products,
    roleList: state.roleList,
    session: state.session,
    users: state.users,
  };
}

export default connect(mapStateToProps)(AddEditUserPermissions);
