import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import uniq from 'lodash/uniq';
import { fetchAccountUsers } from '../../../../../actions/usersActions';
import AddEditUserPermissions from './AddEditUserPermissions';

const UsersRoute = createReactClass({
  getInitialState() {
    return {
      modal: null,
    };
  },
  componentDidMount() {
    if (this.props.selectedAccount.id) {
      this.props.dispatch(fetchAccountUsers());
    }
  },
  killModal() {
    this.setState({ modal: null });
  },
  addUser() {
    this.setState({
      // lack of user means it is for creating a new user
      modal: <AddEditUserPermissions closeAction={this.killModal} />,
    });
  },
  editPermissions(user) {
    this.setState({
      modal: <AddEditUserPermissions closeAction={this.killModal} user={user} />,
    });
  },
  render() {
    const {
      users,
      selectedAccount,
      dispatch,
      /**
       * @type AccountPermissions
       */
      accountPermissions,
    } = this.props;
    const { modal } = this.state;
    if (!users) {
      if (selectedAccount && selectedAccount.id) {
        dispatch(fetchAccountUsers());
      }
      return (
        <div className="animated fadeIn">
          <br />
          <p>
            <em>Loading...</em>
          </p>
        </div>
      );
    }

    // Note: Digium employees may see the user's default biz objects account ID and SF contact.
    return (
      <div>
        {modal}
        <div>
          <h1>Portal Users</h1>
          <br />
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Person</th>
                <th>Permissions</th>
                {accountPermissions.selectAnyAccount ? (
                  <th className="internal-only">
                    <h4>Internal only</h4>
                  </th>
                ) : null}
                <th>
                  <button onClick={this.addUser} className="btn btn-primary pull-right">
                    Add
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <th>
                    {user.firstName} {user.lastName}
                    <br />
                    {user.email}
                  </th>
                  <td>
                    {uniq((user.roles || []).map((role) => role.roleName)).map((roleName) => (
                      <p className="capitalize" key={roleName}>
                        {roleName.replace(/-/g, ' ')}
                      </p>
                    ))}
                  </td>
                  {accountPermissions.selectAnyAccount ? (
                    <td className="internal-only col-xs-3">
                      <small className="help-block">
                        <strong>Biz Obj ID:</strong> {user.id}
                      </small>
                      <small className="help-block">
                        <strong>Default Acct:</strong> {user.accountId}
                      </small>
                      <small className="help-block">
                        <strong>Webadmin Username:</strong> {user.username}
                      </small>
                      <small className="help-block">
                        <strong>SF Contact:</strong> {user.sfContactId}
                      </small>
                    </td>
                  ) : null}
                  <td>
                    {/* TODO: only with role manage-users */}
                    {
                      <button
                        onClick={() => this.editPermissions(user)}
                        className="btn btn-primary pull-right"
                      >
                        Edit
                      </button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
});

UsersRoute.propTypes = {
  dispatch: PropTypes.func,
  selectedAccount: PropTypes.object,
  users: PropTypes.array,
  accountPermissions: PropTypes.object,
};

function mapStateToProps({ selectedAccount, users, accountPermissions }) {
  return { selectedAccount, users, accountPermissions };
}

export default connect(mapStateToProps)(UsersRoute);
