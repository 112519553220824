import * as actions from '../actions/didsActions';

const initialState = [];

export default function didsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DIDS:
      return [...action.payload];
    case actions.SET_DID: {
      const currentRuleIndex = state.findIndex((did) => did.number === action.payload.number);
      const newState = [...state];
      if (currentRuleIndex >= 0) {
        newState[currentRuleIndex] = action.payload;
      } else {
        newState.push(action.payload);
      }
      return newState;
    }
    default:
      return state;
  }
}
