import React from 'react';
import PropTypes from 'prop-types';
import phone from '../../lib/phone';

import './PhonePreviewInput.less';

/**
 * Input a phone number and display preview of number.
 */
export function PhonePreviewInput({ value, onChange, autoComplete }) {
  return (
    <div className="phone-preview-input">
      <input
        className="form-control"
        value={value}
        type="tel"
        autoComplete={autoComplete}
        onChange={onChange}
      />
      <span className="help-block">{phone(value)}</span>
    </div>
  );
}

PhonePreviewInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string.isRequired,
};
