import 'whatwg-fetch';
import handleAPI from '../lib/handleInternalAPI';
import notifyError from '../lib/notifyError';
import { UINotificationError } from '../lib/errors';
import history from '../history';

/*!
 * Synchronous actions
 */
export const SET_DEAL_CREATION_DATA = 'SET_DEAL_CREATION_DATA';
export const SET_DEAL_MASTER_AGENTS = 'SET_DEAL_MASTER_AGENTS';
export const SET_DEAL = 'SET_DEAL';
export const SET_DEAL_CREATED = 'SET_DEAL_CREATED';

export function setDeal(payload) {
  return {
    type: SET_DEAL,
    payload,
  };
}

export function setDealCreationData(payload) {
  return {
    type: SET_DEAL_CREATION_DATA,
    payload,
  };
}

export function setDealMasterAgents(payload) {
  return {
    type: SET_DEAL_MASTER_AGENTS,
    payload,
  };
}

export function setDealCreated(payload) {
  return {
    type: SET_DEAL_CREATED,
    payload,
  };
}

/*!
 * Async thunks
 */

export function fetchSwitchvoxDealCreationData() {
  return async (dispatch) => {
    try {
      const url = '/api/v1/deals/creation-data';
      const response = await fetch(url, { credentials: 'include' });
      const dealCreationData = await handleAPI(response);
      dispatch(setDealCreationData(dealCreationData));
    } catch (err) {
      notifyError(dispatch)(err);
    }
  };
}

export function fetchSipTrunkingDealCreationData() {
  return async (dispatch) => {
    try {
      const url = '/api/v1/deals/sip-creation-data';
      const response = await fetch(url, { credentials: 'include' });
      const dealCreationData = await handleAPI(response);
      dispatch(setDealCreationData(dealCreationData));
    } catch (err) {
      notifyError(dispatch)(err);
    }
  };
}

export function fetchDealEditData(dealId) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/deals/creation-data/${dealId}`;
      const response = await fetch(url, { credentials: 'include' });
      const dealCreationData = await handleAPI(response);
      dispatch(setDealCreationData(dealCreationData));
    } catch (err) {
      notifyError(dispatch)(err);
    }
  };
}

export function fetchMasterAgents() {
  return async (dispatch) => {
    try {
      const url = '/api/v1/deals/master-agents';
      const response = await fetch(url, { credentials: 'include' });
      const dealMasterAgents = await handleAPI(response);
      dispatch(setDealMasterAgents(dealMasterAgents));
    } catch (err) {
      notifyError(dispatch)(err);
    }
  };
}

/**
 * Updates a Deal and redirects the user after creation.
 *
 * @param {object} deal The Deal details
 * @returns {Function}
 */
export function updateDeal(deal, dealId) {
  return async (dispatch, getProps) => {
    try {
      const url = `/api/v1/deals/${dealId}`;
      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(deal),
      });
      const updatedDeal = await handleAPI(response);
      if (!deal.quote) {
        const {
          config: { digiumMyBaseUrl },
        } = getProps();
        // if the quote was skipped, redirect to active deals in partner portal
        // react requires using window.location for external redirects
        window.location = `${digiumMyBaseUrl}/en/users/resellers/deal-active/?deal-created=true`;
      } else {
        // mark that we created a deal in order to display a success message
        dispatch(setDealCreated(true));
        history.push(`/deals/view/${updatedDeal.opportunity.sfId}`);
      }
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}

export function createDeal(deal) {
  return async (dispatch, getProps) => {
    try {
      const url = '/api/v1/deals';
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(deal),
      });
      const createdDeal = await handleAPI(response);

      // Explicitly not storing the created deal in the redux store.
      // Create response is sparse, so re-fetching will be needed anyway.

      if (!createdDeal.quote) {
        // if the quote was skipped, redirect to active deals in partner portal
        const {
          config: { digiumMyBaseUrl },
        } = getProps();
        // react requires using window.location for external redirects
        // we would like the partner portal to display a "quote created" message. Since it
        // was created in this app, pass a query param for the partner portal to know it
        // should display the message
        window.location = `${digiumMyBaseUrl}/en/users/resellers/deal-active/?deal-created=true`;
      } else {
        // mark that we created a deal in order to display a success message
        dispatch(setDealCreated(true));
        history.push(`/deals/view/${createdDeal.opportunity.sfId}`);
      }
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}

export function getDeal(dealId) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/deals/${dealId}`;
      const response = await fetch(url, { credentials: 'include' });
      const deal = await handleAPI(response);
      dispatch(setDeal(deal));
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}

export function createSipstationDeal(deal) {
  return async (dispatch) => {
    try {
      const url = '/api/v1/deals';
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(deal),
      });
      const createdDeal = await handleAPI(response);

      // mark that we created a deal in order to display a success message
      dispatch(setDealCreated(true));
      history.push(`/deals/edit/${createdDeal.opportunity.sfId}`);
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}

/**
 * Updates a Sipstation Deal and redirects the user after creation.
 *
 * @param {object} deal The Deal details
 * @returns {Function}
 */
export function updateSipstationDeal(deal, dealId) {
  return async (dispatch, getProps) => {
    try {
      const url = `/api/v1/deals/sipstation/${dealId}`;
      const response = await fetch(url, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(deal),
      });
      await handleAPI(response);

      const {
        config: { digiumMyBaseUrl },
      } = getProps();
      // if the quote was skipped, redirect to active deals in partner portal
      // react requires using window.location for external redirects
      window.location = `${digiumMyBaseUrl}/en/users/resellers/deal-active/?deal-created=true`;
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}

/**
 * Generates a PDF document for a Sipstation deal
 *
 * @param {object} quoteData The quote details
 * @returns {Function}
 */
export function downloadSipstationPDF(dealId, quoteData) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/deals/sipstation/${dealId}/pdf`;
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(quoteData),
      });

      const pdfBlob = await response.blob();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, '_blank');
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}
