import 'whatwg-fetch';
import handleAPI from '../lib/handleInternalAPI';
import notifyError from '../lib/notifyError';
import { fetchOrders, fetchInvoices, fetchCreditCards } from './billingActions';
import { clearRules, fetchAllRcfRules } from './rcfActions';
import { fetchAllDids } from './didsActions';
import { resyncAccountNames } from './accountsActions';
import { clearUsers } from './usersActions';
import { clearProductInstances, fetchProductInstances } from './productActions';
import { setUIPerms } from './uipermsActions';
import { UINotificationError } from '../lib/errors';

/*!
 * Synchronous actions
 */

export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
export function setSelectedAccount(payload) {
  if (payload.id) {
    localStorage.setItem('lastSelectedAccountId', payload.id);
  }
  return {
    type: SET_SELECTED_ACCOUNT,
    payload,
  };
}

// helper
export function getLastSelectedAccountId() {
  return localStorage.getItem('lastSelectedAccountId');
}

/*!
 * Async thunks
 */

export function fetchSelectedAccount(initialAccount, isSwitching = false) {
  return async (dispatch, getState) => {
    if (initialAccount) {
      // means account changed
      // mark the newly selected account as not loaded while we fetch it
      dispatch(setSelectedAccount({ loaded: false, ...initialAccount }));
      dispatch(setUIPerms(getState().selectedAccount, getState().session.roles));
      if (isSwitching) {
        dispatch(clearProductInstances());
        dispatch(clearRules());
      }
    }
    const { selectedAccount } = getState();

    const account = await fetch(`/api/v1/accounts/${selectedAccount.id}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(() => {
        // setSelectedAccount does not merge the results, it overrides. We should pass
        // the initial account again so the id is available on the object
        dispatch(setSelectedAccount({ loaded: true, ...initialAccount }));
        notifyError(dispatch)(new UINotificationError('The selected account cannot be loaded.'));
      });

    dispatch(clearUsers());
    // set permissions before setting the selected account so any component that relies
    // on the selected account being loaded can depend on the permissions being updated for it
    dispatch(setUIPerms(account, getState().session.roles));
    // mark the newly selected account as loaded
    dispatch(setSelectedAccount({ loaded: true, ...account }));

    /**
     * @type {AccountPermissions}
     */
    const accountPermissions = getState().accountPermissions;

    if (accountPermissions.viewProducts) {
      dispatch(fetchProductInstances());
      dispatch(fetchAllDids());
      dispatch(fetchAllRcfRules());
    }
    if (accountPermissions.viewOrders) {
      dispatch(fetchOrders());
    }
    if (accountPermissions.viewInvoices) {
      dispatch(fetchInvoices());
    }
    if (accountPermissions.viewAccount) {
      // however, can only edit with manageAccount permission
      dispatch(fetchCreditCards());
    }

    dispatch(resyncAccountNames());
  };
}

export function updateSelectedAccount(acct) {
  return (dispatch, getState) => {
    const { selectedAccount } = getState();

    return fetch(`/api/v1/accounts/${selectedAccount.id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(acct),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(handleAPI)
      .then((updatedAccount) => {
        // set permissions before setting the selected account so any component that relies
        // on the selected account being loaded can depend on the permissions being updated for it
        dispatch(setUIPerms(updatedAccount, getState().session.roles));
        dispatch(setSelectedAccount(updatedAccount));
      })
      .catch((err) => {
        notifyError(dispatch)(err);
        // we want to detect failure in thing calling this func
        throw err;
      });
  };
}
