import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import Modal from '../Modal/Modal';
import SelectState from '../SelectState';
import { FormWithChromeFix } from '../../components/FormWithChromeFix';

require('./EditCreditCard.less');

const creditCardYearOptions = [];
const thisYear = new Date().getFullYear();
const futureStop = thisYear + 20;
for (let y = thisYear; y < futureStop; y++) {
  creditCardYearOptions.push(
    <option value={y} key={`cc-year-${y}`}>
      {y}
    </option>
  );
}

function getCardState(props) {
  const card = props.card || {};
  const cardHolderInfo = card.cardHolderInfo || {};

  return {
    // may come in as either cardType or creditCardType
    creditCardType: card.cardType || card.creditCardType || 'Visa',
    creditCardNumber: card.creditCardNumber || '',
    expirationMonth: card.expirationMonth || 1,
    expirationYear: card.expirationYear || parseInt(new Date().getFullYear(), 10),
    securityCode: card.securityCode || '',
    cardHolderInfo: {
      cardHolderName: cardHolderInfo.cardHolderName || '',
      addressLine1: cardHolderInfo.addressLine1 || '',
      addressLine2: cardHolderInfo.addressLine2 || '',
      city: cardHolderInfo.city || '',
      state: cardHolderInfo.state || '',
      country: cardHolderInfo.country || 'United States',
      zipCode: cardHolderInfo.zipCode || '',
    },
  };
}

const EditCreditCard = createReactClass({
  propTypes: {
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
    card: PropTypes.object,
    embed: PropTypes.bool,
    title: PropTypes.string,
    save: PropTypes.func,
    showEmbedFooter: PropTypes.bool,
    showErrors: PropTypes.bool,
    onChange: PropTypes.func,
    hasErrors: PropTypes.func,
    allowUsingBillToContactAddress: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      closeAction: () => {},
      embed: false,
      showEmbedFooter: true,
      showErrors: false,
      onChange: () => {},
      hasErrors: () => {},
      allowUsingBillToContactAddress: false,
    };
  },
  getInitialState() {
    const { allowUsingBillToContactAddress } = this.props;
    const card = this.props.card || {};
    const cardHolderInfo = card.cardHolderInfo || {};
    const addressNotPopulated =
      isEmpty(cardHolderInfo.addressLine1) &&
      isEmpty(cardHolderInfo.city) &&
      isEmpty(cardHolderInfo.state) &&
      isEmpty(cardHolderInfo.zipCode) &&
      isEmpty(cardHolderInfo.country);
    return {
      loading: false,
      cardPotentiallyValid: true,
      cardValid: false,
      lastGuessedCardType: null,
      card: getCardState(this.props),
      showErrors: false,
      useBillToContactAddress: addressNotPopulated && allowUsingBillToContactAddress,
    };
  },
  componentDidMount() {
    this.reportValidationErrors();
  },
  componentWillReceiveProps(nextProps) {
    const { showErrors } = this.state;
    if (nextProps.showErrors !== showErrors) {
      this.setState({ showErrors: nextProps.showErrors });
    }
    // handle create account updating card with session info
    if (nextProps.card && nextProps.card.cardHolderInfo) {
      this.setState({
        card: getCardState(nextProps),
      });
    }
  },
  save() {
    const { closeAction, save } = this.props;
    const { card, useBillToContactAddress } = this.state;
    // since we may need to set some of the fields to null before saving, clone the state
    // so we don't change it as part of saving the credit card
    const cardCreateBody = cloneDeep(card);
    this.setState({ showErrors: true });
    if (this.hasValidationErrors()) {
      return;
    }
    this.setState({ loading: true });
    // if using bill to contact address, null out address fields on the credit card
    if (useBillToContactAddress) {
      cardCreateBody.cardHolderInfo = {
        ...cardCreateBody.cardHolderInfo,
        addressLine1: null,
        addressLine2: null,
        city: null,
        state: null,
        country: null,
        zipCode: null,
      };
    }
    save(cardCreateBody)
      .then(closeAction)
      .catch(() => this.setState({ loading: false }));
  },
  hasValidationErrors() {
    return Object.keys(this.validationErrors()).length > 0;
  },
  validationErrors() {
    const { card, useBillToContactAddress } = this.state;
    const { cardHolderInfo } = this.state.card;
    const errors = {};

    if (!card.creditCardNumber) {
      errors.creditCardNumber = 'This is a required field';
    }
    if (card.creditCardType === 'Discover') {
      errors.creditCardType = 'Discover cards are not supported';
    }
    if (!card.securityCode) {
      errors.securityCode = 'This is a required field';
    }
    if (!cardHolderInfo.cardHolderName) {
      errors.cardHolderName = 'This is a required field';
    }

    // only validate presence of address fields if not using bill to contact address
    if (!useBillToContactAddress) {
      if (!cardHolderInfo.addressLine1) {
        errors.addressLine1 = 'This is a required field';
      }
      // skip addressLine2
      if (!cardHolderInfo.city) {
        errors.city = 'This is a required field';
      }
      if (!cardHolderInfo.state) {
        errors.state = 'This is a required field';
      }
      if (!cardHolderInfo.zipCode) {
        errors.zipCode = 'This is a required field';
      }
      if (!cardHolderInfo.country) {
        errors.country = 'This is a required field';
      }
    }

    return errors;
  },
  reportValidationErrors() {
    this.props.hasErrors(this.hasValidationErrors());
  },
  onPropChange(propName) {
    return (event) => {
      let value = event.target.value;
      if (propName === 'expirationMonth' || propName === 'expirationYear') {
        value = parseInt(value, 10);
      } else if (propName === 'creditCardNumber' || propName === 'securityCode') {
        // allow pasting any format, but strip anything but the number
        value = value.replace(/[^0-9]/g, '');
      } else if (propName === 'cardHolderInfo.state') {
        this.setCard('United States', 'cardHolderInfo.country');
      }
      this.setCard(value, propName);
    };
  },
  setCard(value, propName) {
    const { onChange } = this.props;
    const card = { ...this.state.card };
    set(card, propName, value);
    this.setState({ card }, () => this.reportValidationErrors());
    onChange({ card });
  },
  changeUseBillToContactAddress() {
    const { useBillToContactAddress } = this.state;

    this.setState({
      useBillToContactAddress: !useBillToContactAddress,
    });
  },
  render() {
    const { card, loading, showErrors, useBillToContactAddress } = this.state;
    const errors = this.validationErrors();
    const {
      closeAction,
      title,
      embed,
      showEmbedFooter,
      allowUsingBillToContactAddress,
    } = this.props;
    let header = null;
    if (title) {
      header = <h3>{title}</h3>;
    }

    const existingDiscover =
      card.creditCardType === 'Discover' ? (
        <option value="Discover" disabled>
          Discover
        </option>
      ) : (
        ''
      );

    const body = (
      <div className="panel panel-default">
        <div className="panel-body">
          <FormWithChromeFix id="edit-cc-form" className="form-horizontal">
            <div className="form-group">
              <label className="col-xs-4 control-label required-field">Card Type</label>
              <div className={`col-xs-6 ${showErrors && errors.creditCardType ? 'has-error' : ''}`}>
                <select
                  className="form-control"
                  value={card.creditCardType}
                  autoComplete="cc-type"
                  onChange={this.onPropChange('creditCardType')}
                >
                  <option value="Visa">Visa</option>
                  {existingDiscover}
                  <option value="AmericanExpress">American Express</option>
                  <option value="MasterCard">Mastercard</option>
                </select>
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.creditCardType ? '' : 'hidden'
                  }`}
                >
                  {errors.creditCardType}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label required-field">Card Number</label>
              <div
                className={`col-xs-6 ${showErrors && errors.creditCardNumber ? 'has-error' : ''}`}
              >
                <input
                  className="form-control"
                  value={card.creditCardNumber}
                  type="text"
                  autoComplete="cc-number"
                  onChange={this.onPropChange('creditCardNumber')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.creditCardNumber ? '' : 'hidden'
                  }`}
                >
                  {errors.creditCardNumber}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label required-field">Expiration</label>
              <div className="col-xs-3">
                <select
                  className="form-control"
                  value={card.expirationMonth}
                  autoComplete="cc-exp-month"
                  onChange={this.onPropChange('expirationMonth')}
                >
                  <option value="1">1 - January</option>
                  <option value="2">2 - February</option>
                  <option value="3">3 - March</option>
                  <option value="4">4 - April</option>
                  <option value="5">5 - May</option>
                  <option value="6">6 - June</option>
                  <option value="7">7 - July</option>
                  <option value="8">8 - August</option>
                  <option value="9">9 - September</option>
                  <option value="10">10 - October</option>
                  <option value="11">11 - November</option>
                  <option value="12">12 - December</option>
                </select>
              </div>
              <div className="col-xs-3">
                <select
                  className="form-control"
                  value={card.expirationYear}
                  autoComplete="cc-exp-year"
                  onChange={this.onPropChange('expirationYear')}
                >
                  {creditCardYearOptions}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label required-field">Security Code</label>
              <div className={`col-xs-3 ${showErrors && errors.securityCode ? 'has-error' : ''}`}>
                <input
                  className="form-control"
                  value={card.securityCode}
                  type="text"
                  autoComplete="cc-csc"
                  maxLength="4"
                  onChange={this.onPropChange('securityCode')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.securityCode ? '' : 'hidden'
                  }`}
                >
                  {errors.securityCode}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label required-field">Card Holder Name</label>
              <div className={`col-xs-6 ${showErrors && errors.cardHolderName ? 'has-error' : ''}`}>
                <input
                  className="form-control"
                  value={card.cardHolderInfo.cardHolderName}
                  type="text"
                  autoComplete="name"
                  onChange={this.onPropChange('cardHolderInfo.cardHolderName')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.cardHolderName ? '' : 'hidden'
                  }`}
                >
                  {errors.cardHolderName}
                </span>
              </div>
            </div>
            {allowUsingBillToContactAddress && (
              <div className="form-group bill-to-override">
                <label className="col-xs-4 control-label">Use Billing Contact</label>
                <div className="col-xs-6 bill-to-override-checkbox">
                  <input
                    type="checkbox"
                    checked={useBillToContactAddress}
                    onChange={() => this.changeUseBillToContactAddress()}
                  />
                </div>
              </div>
            )}
            <div className="form-group">
              <label
                className={`col-xs-4 control-label ${
                  useBillToContactAddress ? '' : 'required-field'
                }`}
              >
                Street 1
              </label>
              <div className={`col-xs-6 ${showErrors && errors.addressLine1 ? 'has-error' : ''}`}>
                <input
                  className="form-control"
                  disabled={useBillToContactAddress}
                  value={card.cardHolderInfo.addressLine1}
                  type="text"
                  autoComplete="address-line1"
                  onChange={this.onPropChange('cardHolderInfo.addressLine1')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.addressLine1 ? '' : 'hidden'
                  }`}
                >
                  {errors.addressLine1}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">Street 2</label>
              <div className={`col-xs-6 ${showErrors && errors.addressLine2 ? 'has-error' : ''}`}>
                <input
                  className="form-control"
                  disabled={useBillToContactAddress}
                  value={card.cardHolderInfo.addressLine2}
                  type="text"
                  autoComplete="address-line2"
                  onChange={this.onPropChange('cardHolderInfo.addressLine2')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.addressLine2 ? '' : 'hidden'
                  }`}
                >
                  {errors.city}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label
                className={`col-xs-4 control-label ${
                  useBillToContactAddress ? '' : 'required-field'
                }`}
              >
                City
              </label>
              <div className={`col-xs-6 ${showErrors && errors.city ? 'has-error' : ''}`}>
                <input
                  className="form-control"
                  disabled={useBillToContactAddress}
                  value={card.cardHolderInfo.city}
                  type="text"
                  autoComplete="address-level2"
                  onChange={this.onPropChange('cardHolderInfo.city')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.city ? '' : 'hidden'
                  }`}
                >
                  {errors.city}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">Country</label>
              <div className="col-xs-6 country-name">United States</div>
            </div>
            <div className="form-group">
              <label
                className={`col-xs-4 control-label ${
                  useBillToContactAddress ? '' : 'required-field'
                }`}
              >
                State
              </label>
              <div className={`col-xs-6 ${showErrors && errors.state ? 'has-error' : ''}`}>
                <SelectState
                  className="form-control"
                  disabled={useBillToContactAddress}
                  value={card.cardHolderInfo.state}
                  onChange={this.onPropChange('cardHolderInfo.state')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.state ? '' : 'hidden'
                  }`}
                >
                  {errors.state}
                </span>
              </div>
            </div>
            <div className="form-group">
              <label
                className={`col-xs-4 control-label ${
                  useBillToContactAddress ? '' : 'required-field'
                }`}
              >
                Postal Code
              </label>
              <div className={`col-xs-6 ${showErrors && errors.zipCode ? 'has-error' : ''}`}>
                <input
                  className="form-control"
                  disabled={useBillToContactAddress}
                  value={card.cardHolderInfo.zipCode}
                  type="text"
                  autoComplete="postal-code"
                  onChange={this.onPropChange('cardHolderInfo.zipCode')}
                />
                <span
                  className={`help-block animated fadeIn ${
                    showErrors && errors.zipCode ? '' : 'hidden'
                  }`}
                >
                  {errors.zipCode}
                </span>
              </div>
            </div>
            <div className="form-group">
              <div className="col-xs-4">&nbsp;</div>
              <div className="col-xs-6 control-label">
                <p className="required-footnote">required field</p>
              </div>
            </div>
          </FormWithChromeFix>
        </div>
      </div>
    );

    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={loading} onClick={() => this.save()}>
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    );

    if (embed) {
      const embedFooter = <div className="align-right">{footer}</div>;
      return (
        <div>
          {header}
          {body}
          {showEmbedFooter && embedFooter}
        </div>
      );
    }

    return <Modal header={header} body={body} footer={footer} />;
  },
});

export default withRouter(connect()(EditCreditCard));
