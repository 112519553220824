import React from 'react';
import PropTypes from 'prop-types';

// Observed a quirk in Chrome 70 where change events are not
// emitted for autocomplete values when there are multiple forms
// on the same page. If forms are given ids, the issue is not seen.
// This component exists solely to enforce that the form has an id.
export function FormWithChromeFix(props) {
  return (
    <form id={props.id} className={props.className}>
      {props.children}
    </form>
  );
}

FormWithChromeFix.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};
