import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import Modal from '../../../../components/Modal/Modal';
import { updateRcfRule } from '../../../../actions/rcfActions';
import phone from '../../../../lib/phone';
import RuleNumbersDisplay from './RuleNumbersDisplay';

const warningMessage = (
  <p>
    Enabling call forwarding means your PBX will not receive inbound calls for the following
    numbers. Instead they will be routed as follows:
  </p>
);

const ConfirmRuleset = createReactClass({
  propTypes: {
    rcf: PropTypes.array,
    rulesToUpdate: PropTypes.array,
    dispatch: PropTypes.func,
    closeAction: PropTypes.func,
  },
  getInitialState() {
    const { rcf } = this.props;
    const rcfAsObjects = {};
    rcf.forEach((rule) => {
      rcfAsObjects[rule.id] = rule;
    });
    return {
      rcf: rcfAsObjects,
      isSaving: false,
    };
  },
  save() {
    const { closeAction, rulesToUpdate, dispatch } = this.props;

    this.setState({
      isSaving: true,
    });

    Promise.all(
      rulesToUpdate.map((ruleId) =>
        dispatch(
          updateRcfRule({
            id: ruleId,
            enabled: true,
          })
        )
      )
    )
      .then(closeAction)
      .catch(() =>
        this.setState({
          isSaving: false,
        })
      );
  },
  render() {
    const { rulesToUpdate, closeAction } = this.props;
    const { rcf, isSaving } = this.state; // object, not array
    const header = <h3>Confirm Remote Call Forwarding</h3>;
    const body = (
      <div>
        {warningMessage}
        {rulesToUpdate.map((ruleId) => {
          const rule = rcf[ruleId];
          return (
            <div className="panel panel-default" key={`rule-${ruleId}`}>
              <div className="panel-heading">
                <h5>
                  <strong>{rule.name}</strong>
                </h5>
              </div>
              <div className="panel-body">
                <div className="row">
                  <div className="col-xs-4">
                    <RuleNumbersDisplay rule={rule} />
                  </div>
                  <div className="col-xs-4 align-center">to</div>
                  <div className="col-xs-4 align-right">{phone(rule.destination)}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

    const footer = (
      <div>
        <button className="btn btn-default" disabled={isSaving} onClick={closeAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={isSaving} onClick={this.save}>
          {isSaving ? 'Enabling...' : 'Enable'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps({ rcf }) {
  return {
    rcf,
  };
}

export default withRouter(connect(mapStateToProps)(ConfirmRuleset));
