import * as actions from '../actions/uipermsActions';
import { AccountPermissions } from '../../shared/accountPermissions';

const initialState = new AccountPermissions();

export default function uipermsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_UI_PERMS:
      return { ...action.payload };
    default:
      return state;
  }
}
