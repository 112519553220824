import * as actions from '../actions/adminUserSearchActions';

// list of users - search results
const initialState = {
  pagination: {},
  items: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_USER_RESULTS:
      return { ...action.payload };
    default:
      return state;
  }
};
