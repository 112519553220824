import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchInvoices } from '../../../../../actions/billingActions';

const InvoicesRoute = createReactClass({
  componentDidMount() {
    this.props.dispatch(fetchInvoices());
  },
  prevPage() {
    // pagination may not exist yet
    const {
      invoices: { pagination },
    } = this.props;
    if (pagination && typeof pagination.page !== 'undefined') {
      const prev = pagination.page - 1;
      this.props.dispatch(fetchInvoices(Math.max(0, prev)));
    }
  },
  nextPage() {
    // pagination may not exist yet
    const {
      invoices: { pagination },
    } = this.props;
    if (pagination && typeof pagination.page !== 'undefined') {
      const next = pagination.page + 1;
      this.props.dispatch(fetchInvoices(next));
    }
  },
  render() {
    const {
      invoices: { items = [], pagination = {} },
      selectedAccount,
    } = this.props;

    // it is impossible to know the number of pages or total items because it comes out of
    // zuora which has no such query mechanism (is anyone surprised?)
    // - show Prev button when you are past the first page
    // - show the Next button when the current page is full
    const showPrev = typeof pagination.page !== 'undefined' && pagination.page > 1;
    const hideNext =
      !items.length || (items.length && pagination.pageSize && items.length < pagination.pageSize);
    const paginationUI = (
      <p>
        {showPrev ? (
          <button className="btn btn-success" onClick={this.prevPage}>
            Prev
          </button>
        ) : null}
        &nbsp;
        {hideNext ? null : pagination.page}
        &nbsp;
        {hideNext ? null : (
          <button className="btn btn-success" onClick={this.nextPage}>
            Next
          </button>
        )}
      </p>
    );

    const body = (
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Invoice #</th>
            <th>Date</th>
            <th className="align-right">Total</th>
          </tr>
        </thead>
        <tbody>
          {items.map((invoice) => (
            <tr key={invoice.id}>
              <td>
                {invoice.invoiceNumber}
                &nbsp;&nbsp;
                <a
                  href={`/redir?pdf=/api/v1/accounts/${selectedAccount.id}/invoices/${invoice.id}/invoice.pdf`}
                  target="_blank"
                >
                  PDF &#8599;
                </a>
              </td>
              <td>{new Date(invoice.invoiceDate).toLocaleDateString()}</td>
              <td className="align-right">${parseFloat(invoice.amount || 0, 10).toFixed(2)}</td>
            </tr>
          ))}
          {!items.length ? (
            <tr>
              <td colSpan="3">None</td>
            </tr>
          ) : null}
        </tbody>
      </table>
    );
    return (
      <div>
        <h1>Invoices</h1>
        <br />
        {paginationUI}
        {body}
      </div>
    );
  },
});

InvoicesRoute.propTypes = {
  dispatch: PropTypes.func,
  selectedAccount: PropTypes.object,
  invoices: PropTypes.object,
};

function mapStateToProps({ selectedAccount, billing }) {
  return {
    selectedAccount,
    invoices: billing.invoices,
  };
}

export default connect(mapStateToProps)(InvoicesRoute);
