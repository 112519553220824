import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import createReactClass from 'create-react-class';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import Modal from '../Modal/Modal';
import SelectState from '../SelectState';
import { PhonePreviewInput } from '../PhonePreviewInput';
import { FormWithChromeFix } from '../../components/FormWithChromeFix';

/**
 * EditContact is for editing a Zuora Contact, either billToContact or soldToContact.
 */
const EditContact = createReactClass({
  propTypes: {
    accountId: PropTypes.string,
    contact: PropTypes.object,
    closeAction: PropTypes.func,
    title: PropTypes.string,
    save: PropTypes.func,
    embed: PropTypes.bool,
  },
  getDefaultProps() {
    return {
      closeAction: () => {},
      embed: false,
    };
  },
  getInitialState() {
    const { contact } = cloneDeep(this.props);
    // React throws errors when input values are null. Silly behavior.
    // This conflicts with the way Zuora chokes on empty values, and
    // probably is handled in the backend.
    forEach(contact.address, (value, key) => {
      if (!value) {
        contact.address[key] = '';
      }
    });
    return {
      loading: false,
      contact,
      errors: {},
      showErrors: false,
    };
  },
  onPropChange(propName) {
    return (event) => {
      const contact = { ...this.state.contact };
      const newValue =
        propName === 'workPhone' ? event.target.value.replace(/[^0-9]/g, '') : event.target.value;
      set(contact, propName, newValue);
      this.setState({ contact }, () => this.validate());
    };
  },
  save() {
    this.setState({ showErrors: true });
    if (!this.validate()) {
      return;
    }

    const { accountId } = this.props;
    this.setState({ loading: true });
    this.props
      .save(this.state.contact, accountId)
      .then(this.props.closeAction)
      .catch(() => this.setState({ loading: false }));
  },
  validate() {
    const errors = this.validateInput();
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  },
  validateInput() {
    const { firstName, lastName, workPhone, workEmail } = this.state.contact;
    const { address1, city, state, zipCode, country } = this.state.contact.address;
    const errors = {};

    if (!firstName) {
      errors.firstName = 'This is a required field';
    }
    if (!lastName) {
      errors.lastName = 'This is a required field';
    }
    if (!workPhone) {
      errors.workPhone = 'This is a required field';
    }
    if (!workEmail) {
      errors.workEmail = 'This is a required field';
    } else if (!/^$|^\S+@\S+\.\S+$/.test(workEmail)) {
      // check if the email matches the format 'x@y.z'
      errors.workEmail = 'Invalid email address';
    }
    if (!address1) {
      errors.address1 = 'This is a required field';
    }
    if (!city) {
      errors.city = 'This is a required field';
    }
    if (!state) {
      errors.state = 'This is a required field';
    }
    if (!zipCode) {
      errors.zipCode = 'This is a required field';
    }
    if (!country) {
      errors.country = 'This is a required field';
    }

    return errors;
  },
  render() {
    const { contact, loading, errors } = this.state;
    const { closeAction, title, embed } = this.props;
    const shouldShowError = (errorVal) => this.state.showErrors && errorVal;
    let header;
    if (title) {
      header = <h3>{title}</h3>;
    }
    const body = (
      <div>
        <div className="panel panel-default">
          <div className="panel-body">
            <FormWithChromeFix id="edit-contact-form" className="form-horizontal">
              <div className="form-group">
                <label className="col-xs-4 control-label">First Name</label>
                <div className={`col-xs-6 ${shouldShowError(errors.firstName) ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    value={contact.firstName}
                    type="text"
                    autoComplete="billing given-name"
                    onChange={this.onPropChange('firstName')}
                  />
                  {shouldShowError(errors.firstName) && (
                    <span className={'help-block animated fadeIn'}>{errors.firstName}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">Last Name</label>
                <div className={`col-xs-6 ${shouldShowError(errors.lastName) ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    value={contact.lastName}
                    type="text"
                    autoComplete="billing family-name"
                    onChange={this.onPropChange('lastName')}
                  />
                  {shouldShowError(errors.lastName) && (
                    <span className={'help-block animated fadeIn'}>{errors.lastName}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">Phone</label>
                <div className={`col-xs-6 ${shouldShowError(errors.workPhone) ? 'has-error' : ''}`}>
                  <PhonePreviewInput
                    autoComplete="billing tel"
                    onChange={this.onPropChange('workPhone')}
                    value={contact.workPhone}
                  />
                  {shouldShowError(errors.workPhone) && (
                    <span className={'help-block animated fadeIn'}>{errors.workPhone}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">Email</label>
                <div className={`col-xs-6 ${shouldShowError(errors.workEmail) ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    value={contact.workEmail}
                    type="email"
                    autoComplete="billing email"
                    onChange={this.onPropChange('workEmail')}
                  />
                  {shouldShowError(errors.workEmail) && (
                    <span className={'help-block animated fadeIn'}>{errors.workEmail}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">Street 1</label>
                <div className={`col-xs-6 ${shouldShowError(errors.address1) ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    value={contact.address.address1}
                    autoComplete="billing address-line1"
                    onChange={this.onPropChange('address.address1')}
                  />
                  {shouldShowError(errors.address1) && (
                    <span className={'help-block animated fadeIn'}>{errors.address1}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">Street 2</label>
                <div className="col-xs-6">
                  <input
                    className="form-control"
                    value={contact.address.address2}
                    autoComplete="billing address-line2"
                    onChange={this.onPropChange('address.address2')}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">City</label>
                <div className={`col-xs-6 ${shouldShowError(errors.city) ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    value={contact.address.city}
                    autoComplete="billing address-level2"
                    onChange={this.onPropChange('address.city')}
                  />
                  {shouldShowError(errors.city) && (
                    <span className={'help-block animated fadeIn'}>{errors.city}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">State</label>
                <div className={`col-xs-6 ${shouldShowError(errors.state) ? 'has-error' : ''}`}>
                  <SelectState
                    className="form-control"
                    value={contact.address.state}
                    onChange={this.onPropChange('address.state')}
                  />
                  {shouldShowError(errors.state) && (
                    <span className={'help-block animated fadeIn'}>{errors.state}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">Postal Code</label>
                <div className={`col-xs-6 ${shouldShowError(errors.zipCode) ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    value={contact.address.zipCode}
                    autoComplete="billing postal-code"
                    onChange={this.onPropChange('address.zipCode')}
                  />
                  {shouldShowError(errors.zipCode) && (
                    <span className={'help-block animated fadeIn'}>{errors.zipCode}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="col-xs-4 control-label">Country</label>
                <div className={`col-xs-6 ${shouldShowError(errors.country) ? 'has-error' : ''}`}>
                  <input
                    className="form-control"
                    value={contact.address.country}
                    autoComplete="billing country-name"
                    onChange={this.onPropChange('address.country')}
                  />
                  {shouldShowError(errors.country) && (
                    <span className={'help-block animated fadeIn'}>{errors.country}</span>
                  )}
                </div>
              </div>
            </FormWithChromeFix>
          </div>
        </div>
      </div>
    );

    let saveButtonText;
    if (loading) {
      saveButtonText = 'Saving...';
    } else {
      saveButtonText = 'Save';
    }
    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={loading} onClick={this.save}>
          {saveButtonText}
        </button>
      </div>
    );
    if (embed) {
      return (
        <div>
          {header}
          {body}
          <div className="align-right">{footer}</div>
        </div>
      );
    }
    return <Modal header={header} body={body} footer={footer} />;
  },
});

export default EditContact;
