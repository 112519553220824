import * as actions from '../actions/quoteCheckoutActions';

const initialState = {
  creationData: null,
  /**
   * @type BOSQuote
   */
  quote: null,

  order: null,
  orderLoaded: false,

  /**
   * @type BOSAccount
   */
  account: null,
  /**
   * @type QuoteMetadata
   */
  metadata: null,
  /**
   * @type PaymentMethod
   */
  paymentMethod: null,
  paymentMethodLoaded: false,
  shippingContact: null,
  shippingContactLoaded: false,
  shippingRates: null,
  selectedShippingRate: '0',
};

export default function quoteCheckoutReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_CHECKOUT_ORDER:
      return { ...state, order: action.payload, orderLoaded: true };
    case actions.SET_CHECKOUT_QUOTE:
      return { ...state, quote: action.payload };
    case actions.SET_CHECKOUT_QUOTE_ACCOUNT:
      return { ...state, account: action.payload };
    case actions.SET_CHECKOUT_QUOTE_CREATION_DATA:
      return { ...state, creationData: action.payload };
    case actions.SET_CHECKOUT_QUOTE_PAYMENT:
      return {
        ...state,
        paymentMethod: action.payload,
        // have to have explicit "loaded" field to differentiate
        // from a null that indicates there is no payment method.
        paymentMethodLoaded: true,
      };
    case actions.SET_CHECKOUT_QUOTE_METADATA:
      return { ...state, metadata: action.payload };
    case actions.SET_CHECKOUT_QUOTE_SHIPPING_CONTACT:
      return {
        ...state,
        shippingContact: action.payload,
        // have to have explicit "loaded" field to differentiate
        // from a null that indicates there is no shipping contact.
        shippingContactLoaded: true,
      };
    case actions.SET_CHECKOUT_QUOTE_SHIPPING_RATES:
      return { ...state, shippingRates: action.payload };
    case actions.SET_CHECKOUT_QUOTE_SELECTED_SHIPPING_RATE:
      return { ...state, selectedShippingRate: action.payload };
    default:
      return state;
  }
}
