import React from 'react';
import { Route, Switch } from 'react-router';

import Layout from './Layout';
import Help from './routes/help/Help';

export default function TechnicalDifficulties() {
  return (
    <Layout>
      <Switch>
        <Route path="/help" component={Help} />
        <Route
          render={() => (
            <section className="animated fadeIn">
              <h1>Oops, something went wrong.</h1>
              <br />
              <div className="row">
                <div className="col-sm-8">
                  <p>
                    An unexpected error occurred. Our development team has been notified to correct
                    the problem. Please try again in a few minutes. If you need immediate
                    assistance, please contact our Customer Success team at{' '}
                    <a href="mailto:customersuccess@digiumcloud.com">
                      customersuccess@digiumcloud.com
                    </a>
                    .
                  </p>
                  <br />
                </div>
              </div>
            </section>
          )}
        ></Route>
      </Switch>
    </Layout>
  );
}
