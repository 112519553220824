import * as actions from '../actions/quoteActions';

const initialState = {
  creationData: null,
  creationError: null,
  quotes: {},
  preview: null,
};

export default function quoteReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_QUOTE_CREATION_DATA:
      return { ...state, creationData: action.payload };
    case actions.SET_QUOTE_ERROR:
      return { ...state, creationError: action.payload };
    case actions.SET_QUOTE:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [action.payload.id]: action.payload,
        },
      };
    case actions.SET_QUOTE_PREVIEW:
      return {
        ...state,
        preview: action.payload,
      };
    default:
      return state;
  }
}
