import { addNotif } from '../actions/notifActions';

export default (dispatch) =>
  function notifyError(errMessage) {
    dispatch(addNotif(errMessage));

    if (errMessage instanceof Error) {
      throw errMessage;
    }
    throw new Error(errMessage);
  };
