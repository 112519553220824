import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import isEmpty from 'lodash/isEmpty';
import negate from 'lodash/negate';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import ImportSwitchNumbersModal from './ImportSwitchNumbersModal';
import DeleteNumbersModal from './DeleteNumbersModal';
import AddDMSNumberModal from './AddDMSNumberModal';
import EditDMSNumbersModal from './EditDMSNumbersModal';
import FixMisconfiguredSwitchNumberModal from './FixMisconfiguredSwitchNumberModal';
import DisplaySwitchNumberTrunkMismatchModal from './DisplaySwitchNumberTrunkMismatchModal';
import ManagedNumberRow from './ManagedNumberRow';
import DropdownUL from './DropdownUL';
import possible11DigitsTo10 from '../../../../lib/possible11DigitsTo10';
import {
  fetchOneProductInstance,
  setDefaultE911Number,
  addDidsToTrunk,
} from '../../../../actions/productActions';
import { customerSetDidsForRemoval } from '../../../../actions/didsActions';
import { calcUsedEntitlements, intuitTollFree } from '../../../../lib/intuitTollFree';
import phone from '../../../../lib/phone';
import Modal from '../../../../components/Modal/Modal';
import { addNotif } from '../../../../actions/notifActions';
import { byManagedNumbers } from '../../../../../src/lib/sorting';
import FirstFewNumbers from './FirstFewNumbers';

// is the number not routable in the SIP switch
const isUnroutable = (switchNumber) => switchNumber.routable === false;
// is the number misconfigured in the SIP switch (trunk mismatch between e911_numbers and dbaliases)
const isMisconfigured = (switchNumber) => switchNumber.e911_misconfigured_trunk;

const ReconcileNumbers = createReactClass({
  getInitialState() {
    return {
      processing: false,
      editModal: null,
      dmsNumberCheckboxes: [],
      sortField: 'number',
      sortAscending: true,
      loading: true,
    };
  },
  componentWillReceiveProps(nextProps) {
    const { trunk, sipSwitch } = nextProps;

    if (trunk || sipSwitch) {
      // force render when we get either the trunk or the sipSwitch
      this.setState({ loading: false });
    }
  },
  toggleSorting(fieldName) {
    this.setState({
      sortField: fieldName,
      sortAscending: !this.state.sortAscending,
    });
  },
  importNumbersFromSwitch() {
    const {
      product: { trunk, sipSwitch, productId, subscriptionNumber },
    } = this.props;
    const managedNumbers = trunk.numbers.map((n) => n.number);
    // On the switch, GLBX numbers have a leading 1 that isn't stored in DMS.  When we look for
    // unmanaged numbers, only search by the last 10 digits for a good comparison
    const unmanagedNumbers = sipSwitch.numbers.filter(
      (n) => !managedNumbers.includes(n.number.substr(-10, 10))
    );
    this.setState({
      editModal: (
        <ImportSwitchNumbersModal
          subscriptionNumber={subscriptionNumber}
          productId={productId}
          trunkId={trunk.id}
          numbers={unmanagedNumbers}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  addNumber() {
    const { product, trunk } = this.props;

    this.setState({
      editModal: (
        <AddDMSNumberModal
          subscriptionNumber={product.subscriptionNumber}
          productId={product.productId}
          trunkId={trunk.id}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  fixMisconfiguredSwitchNumber(number) {
    const { product, sipSwitch } = this.props;

    this.setState({
      editModal: (
        <FixMisconfiguredSwitchNumberModal
          subscriptionNumber={product.subscriptionNumber}
          username={sipSwitch.username}
          misconfiguredNumberToFix={number}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  displaySwitchNumberTrunkMismatch(number) {
    const { sipSwitch } = this.props;
    this.setState({
      editModal: (
        <DisplaySwitchNumberTrunkMismatchModal
          username={sipSwitch.username}
          misconfiguredNumberToDisplay={number}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  editNumbers(event) {
    const { dmsNumberCheckboxes } = this.state;
    const { product, dispatch } = this.props;
    // nothing to edit
    if (!dmsNumberCheckboxes.length) {
      event.preventDefault();
      return;
    }

    // cannot edit numbers that are not currently on the switch
    const allNumbersOnSwitch = dmsNumberCheckboxes.every(this.isInSwitch);
    if (!allNumbersOnSwitch) {
      event.preventDefault();
      return;
    }

    // prevent DropdownUl from getting click event again and showing the drop down again
    event.stopPropagation();
    this.setState({
      editModal: (
        <EditDMSNumbersModal
          numbers={dmsNumberCheckboxes}
          dispatch={dispatch}
          product={product}
          subscriptionNumber={product.subscriptionNumber}
          cancelAction={() => this.setState({ editModal: null })}
          continueAction={() => this.setState({ editModal: null, dmsNumberCheckboxes: [] })}
        />
      ),
    });
  },
  returnToInventory(event) {
    const { dmsNumberCheckboxes } = this.state;
    // nothing to delete
    if (!dmsNumberCheckboxes.length) {
      event.preventDefault();
      return;
    }

    // prevent DropdownUl from getting click event again and showing the drop down again
    event.stopPropagation();
    this.setState({
      editModal: (
        <DeleteNumbersModal
          numbers={dmsNumberCheckboxes}
          dispatch={this.props.dispatch}
          product={this.props.product}
          cancelAction={() => this.setState({ editModal: null })}
          continueAction={this.numberReturnedToInventory}
        />
      ),
    });
  },
  numberReturnedToInventory() {
    this.setState({ processing: true, editModal: null, dmsNumberCheckboxes: [] });
    this.refetchProduct()
      .then(() => this.setState({ processing: false }))
      .catch(() => this.setState({ processing: false }));
  },
  refetchProduct() {
    const { product, dispatch } = this.props;

    if (!product) {
      return;
    }

    return dispatch(
      fetchOneProductInstance({
        subscriptionNumber: product.subscriptionNumber,
        checkSwitchState: true,
      })
    );
  },
  toggleDmsNumberCheckbox(number) {
    const dmsNumberCheckboxes = [...this.state.dmsNumberCheckboxes];
    if (dmsNumberCheckboxes.includes(number)) {
      dmsNumberCheckboxes.splice(dmsNumberCheckboxes.indexOf(number), 1);
    } else {
      dmsNumberCheckboxes.push(number);
    }
    this.setState({ dmsNumberCheckboxes });
  },
  async undoCustomerRemoval(event) {
    const { dmsNumberCheckboxes } = this.state;

    // nothing to change
    if (!dmsNumberCheckboxes.length) {
      event.preventDefault();
      return;
    }

    // cannot undo customer removal unless all selected numbers are pending removal
    const allNumbersPendingRemoval = dmsNumberCheckboxes.every(this.isPendingRemoval);
    if (!allNumbersPendingRemoval) {
      event.preventDefault();
      return;
    }

    // prevent DropdownUl from getting click event again and showing the drop down again
    event.stopPropagation();
    const { dispatch } = this.props;
    this.setState({ processing: true });
    await dispatch(
      customerSetDidsForRemoval({
        numbersList: dmsNumberCheckboxes,
        pendingRemovalFromTrunk: false,
      })
    );
    await this.refetchProduct();
    this.setState({ processing: false, dmsNumberCheckboxes: [] });
  },
  confirmDefaultE911(event) {
    const { dmsNumberCheckboxes } = this.state;
    if (dmsNumberCheckboxes.length !== 1) {
      event.preventDefault();
      return;
    }

    // can only set E911 number as the default E911 number
    const numberE911Enabled = this.isE911Enabled(dmsNumberCheckboxes[0]);
    if (!numberE911Enabled) {
      event.preventDefault();
      return;
    }

    // prevent DropdownUl from getting click event again and showing the drop down again
    event.stopPropagation();
    const header = (
      <div>
        <h3>Change Default E911</h3>
      </div>
    );
    const body = (
      <div>
        This will change the default E911 number on the trunk to {phone(dmsNumberCheckboxes[0])}.
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" onClick={() => this.setState({ editModal: null })}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={this.makeDefaultE911}>
          Apply Changes
        </button>
      </div>
    );

    this.setState({ editModal: <Modal header={header} body={body} footer={footer} /> });
  },
  makeDefaultE911() {
    const { dmsNumberCheckboxes } = this.state;
    const {
      dispatch,
      product: { trunk, subscriptionNumber },
    } = this.props;
    const number = trunk.numbers.find((did) => did.number === dmsNumberCheckboxes[0]);
    let error = false;

    if (number.e911Default) {
      dispatch(
        addNotif(
          `${phone(dmsNumberCheckboxes[0])} is already the default E911 number for this trunk`
        )
      );
      error = true;
    }
    if (!number.e911Enabled) {
      dispatch(addNotif(`${phone(dmsNumberCheckboxes[0])} is not an E911 enabled number`));
      error = true;
    }
    if (error) {
      this.setState({ editModal: null });
      return;
    }

    this.setState({ processing: true });
    dispatch(
      setDefaultE911Number(dmsNumberCheckboxes[0], { subscriptionNumber }, { trunkId: trunk.id })
    )
      .then(() => this.setState({ processing: false, editModal: null, dmsNumberCheckboxes: [] }))
      .catch(() => this.setState({ processing: false, editModal: null }));
  },
  confirmExportToSwitch(event) {
    const { dmsNumberCheckboxes } = this.state;
    // nothing to export
    if (!dmsNumberCheckboxes.length) {
      event.preventDefault();
      return;
    }

    // can only export numbers not on the switch
    const someAlreadyOnSwitch = dmsNumberCheckboxes.some(this.isInSwitch);
    if (someAlreadyOnSwitch) {
      event.preventDefault();
      return;
    }

    // prevent DropdownUl from getting click event again and showing the drop down again
    event.stopPropagation();
    const header = (
      <div>
        <h3>Export to switch</h3>
      </div>
    );
    const body = (
      <div>
        This will export the following to the switch:{' '}
        <FirstFewNumbers numbers={dmsNumberCheckboxes} maxDisplay={3} />
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" onClick={() => this.setState({ editModal: null })}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={this.exportToSwitch}>
          Export
        </button>
      </div>
    );

    this.setState({ editModal: <Modal header={header} body={body} footer={footer} /> });
  },
  exportToSwitch() {
    const { dmsNumberCheckboxes } = this.state;
    const {
      dispatch,
      product: { trunk },
    } = this.props;
    const onSwitch = dmsNumberCheckboxes.filter(this.isInSwitch);

    if (onSwitch.length) {
      const phoneNumbers = onSwitch.map(phone).join(', ');
      dispatch(addNotif(`The following numbers are already on the switch: ${phoneNumbers}`));
      this.setState({ editModal: null });
      return;
    }

    const payload = {
      numbers: dmsNumberCheckboxes,
      reason: 'Exporting to switch',
      middleOut: true,
    };
    this.setState({ processing: true });
    return dispatch(addDidsToTrunk(trunk.id, payload))
      .then(() =>
        this.refetchProduct().then(() =>
          this.setState({
            processing: false,
            editModal: null,
            dmsNumberCheckboxes: [],
          })
        )
      )
      .catch(() => this.setState({ processing: false, editModal: null }));
  },
  selectAll(event) {
    // prevent DropdownUl from getting click event again and showing the drop down again
    event.stopPropagation();
    const {
      product: { trunk },
    } = this.props;
    const managedNumbers = trunk && trunk.numbers ? trunk.numbers.map((n) => n.number) : [];
    this.setState({ dmsNumberCheckboxes: managedNumbers });
  },
  selectNone(event) {
    // prevent DropdownUl from getting click event again and showing the drop down again
    event.stopPropagation();
    this.setState({ dmsNumberCheckboxes: [] });
  },
  // is the phone number known to the SIP switch?
  isInSwitch(number) {
    const {
      product: { sipSwitch },
    } = this.props;
    const candidates = get(sipSwitch, 'numbers', []);
    return candidates.some((switchNumber) => possible11DigitsTo10(switchNumber.number) === number);
  },
  // is the phone number E911 enabled?
  isE911Enabled(number) {
    const {
      product: { trunk },
    } = this.props;
    const candidates = get(trunk, 'numbers', []);
    return candidates.some((did) => did.number === number && did.e911Enabled);
  },
  // get a switch DID given a phone number
  getSwitchDid(number) {
    const {
      product: { sipSwitch },
    } = this.props;
    const candidates = get(sipSwitch, 'numbers', []);
    return candidates.find((switchNumber) => possible11DigitsTo10(switchNumber.number) === number);
  },
  // is the phone number pending customer removal?
  isPendingRemoval(number) {
    const {
      product: { trunk },
    } = this.props;
    return (
      trunk &&
      trunk.numbers &&
      trunk.numbers.find(
        (trunkNumber) => trunkNumber.pendingRemovalFromTrunk && trunkNumber.number === number
      )
    );
  },
  render() {
    const { product, trunk, sipSwitch } = this.props;
    const { processing, editModal, dmsNumberCheckboxes, sortField, sortAscending } = this.state;

    if (!product) {
      return null; // pre-loading
    }

    const { subscriptionNumber } = product;
    // sipSwitch can be an empty object when the switch doesn't have the trunk, or we fail to
    // retrieve it for some reason. It might even have an empty numbers array. But we
    // get the username from kamailio's database.
    const sipSwitchFound = sipSwitch && sipSwitch.username;
    // make sure the trunk has been imported from the switch or was created as part of on-boarding before allowing
    // importing numbers into the DMS from the switch
    const trunkImported = !isEmpty(trunk);
    // is the phone number known to the DMS?
    const isManaged = (switchNumber) =>
      trunk &&
      trunk.numbers &&
      trunk.numbers.find(
        (trunkNumber) => trunkNumber.number === possible11DigitsTo10(switchNumber)
      );
    // sipSwitch may have misconfigured numbers. Determine this so we can block imports
    const sipSwitchMisconfigured =
      sipSwitch &&
      sipSwitch.numbers &&
      sipSwitch.numbers.find(
        (switchNumber) => isUnroutable(switchNumber) || isMisconfigured(switchNumber)
      );
    let unmanagedNumberCount = 0;
    let switchNumbers = <p>Loading switch numbers...</p>;
    let switchErrors = null;
    let switchNumberCount = null;
    let managedNumbers = trunkImported ? <p>Loading managed numbers...</p> : null;
    let managedNumberCount = null;
    let managedNumberNotInSwitchCount = 0;
    let managedNumberErrors = null;

    if (sipSwitch && sipSwitch.numbers) {
      switchNumbers = (
        <table className="table table-condensed table-bordered">
          <thead>
            <tr>
              <th />
              <th style={{ minWidth: '90px' }}>Number</th>
              <th>E911 Enabled</th>
              <th>E911 Provider</th>
              <th>E911 Default</th>
            </tr>
          </thead>
          <tbody>
            {sipSwitch.numbers.map((did) => {
              const alreadyManaged = isManaged(did.number);
              if (!alreadyManaged) {
                unmanagedNumberCount++;
              }
              return (
                <tr key={`mn-${did.number}`} className={alreadyManaged ? '' : 'danger'}>
                  <td>
                    {isUnroutable(did) ? (
                      <span
                        onClick={() => this.fixMisconfiguredSwitchNumber(did)}
                        className="help attention-background attention-border"
                      >
                        !
                      </span>
                    ) : (
                      ''
                    )}
                    {isMisconfigured(did) ? (
                      <span
                        onClick={() => this.displaySwitchNumberTrunkMismatch(did)}
                        className="help attention-background attention-border"
                      >
                        !
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{did.number}</td>
                  <td>{did.e911_enabled ? 'yes' : 'no'}</td>
                  <td>{did.e911_provider || ''}</td>
                  <td>
                    {did.e911_default ? <span className="label label-default">yes</span> : 'no'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
      const switchUsed = calcUsedEntitlements(sipSwitch.numbers);
      switchNumberCount = (
        <strong className="help-block">
          {sipSwitch.numbers.length - unmanagedNumberCount}/{sipSwitch.numbers.length}
          &nbsp; managed, {switchUsed.numTollFreeDIDs} TF, {switchUsed.numLocalDIDs}&nbsp; local,{' '}
          {switchUsed.numE911DIDs} E911
        </strong>
      );

      const switchNoE911 = sipSwitch.numbers.some((did) => did.e911_enabled) ? null : (
        <p>No E911 numbers on trunk</p>
      );
      const switchNoDefaultE911 = sipSwitch.numbers.some((did) => did.e911_default) ? null : (
        <p>No default E911 number</p>
      );
      if (switchNoE911 || switchNoDefaultE911) {
        switchErrors = (
          <div className="help-block attention-text" style={{ fontWeight: 700 }}>
            {switchNoE911}
            {switchNoDefaultE911}
          </div>
        );
      }
    }
    if (trunkImported && trunk.numbers) {
      const sortOrder = sortAscending ? (
        <span className="caret caret-reversed" />
      ) : (
        <span className="caret" />
      );
      // add type based on the value of number before displaying
      const trunkNumbersWithType = trunk.numbers.map((number) => ({
        ...number,
        ...{ type: intuitTollFree(number) },
      }));
      managedNumbers = (
        <table className="table table-bordered">
          <thead>
            <tr className="clickable">
              <th style={{ width: '5%' }} />
              <th style={{ width: '11%' }} onClick={() => this.toggleSorting('number')}>
                Number &nbsp; {sortField === 'number' ? sortOrder : null}
              </th>
              <th
                style={{ width: '11%' }}
                onClick={() => this.toggleSorting('pendingRemovalFromTrunk')}
              >
                Customer
                <br />
                Removed &nbsp; {sortField === 'pendingRemovalFromTrunk' ? sortOrder : null}
              </th>
              <th style={{ width: '11%' }} onClick={() => this.toggleSorting('type')}>
                Type &nbsp; {sortField === 'type' ? sortOrder : null}
              </th>
              <th style={{ width: '11%' }} onClick={() => this.toggleSorting('origin')}>
                Origin &nbsp; {sortField === 'origin' ? sortOrder : null}
              </th>
              <th style={{ width: '11%' }} onClick={() => this.toggleSorting('provider')}>
                Provider &nbsp; {sortField === 'provider' ? sortOrder : null}
              </th>
              <th style={{ width: '10%' }} onClick={() => this.toggleSorting('e911_enabled')}>
                E911 Enabled &nbsp; {sortField === 'e911_enabled' ? sortOrder : null}
              </th>
              <th style={{ width: '10%' }} onClick={() => this.toggleSorting('e911_default')}>
                E911 Default &nbsp; {sortField === 'e911_default' ? sortOrder : null}
              </th>
            </tr>
          </thead>
          <tbody>
            {byManagedNumbers(trunkNumbersWithType, sortField, sortAscending, sortBy).map((did) => {
              const switchDid = this.getSwitchDid(did.number);
              if (!switchDid) {
                managedNumberNotInSwitchCount++;
              }
              return (
                <ManagedNumberRow
                  key={`mannum${did.number}`}
                  did={did}
                  subscriptionNumber={subscriptionNumber}
                  switchDid={switchDid}
                  onToggle={() => this.toggleDmsNumberCheckbox(did.number)}
                  toggled={dmsNumberCheckboxes.includes(did.number)}
                  setProcessing={(boolVal) => this.setState({ processing: boolVal })}
                />
              );
            })}
          </tbody>
        </table>
      );
      const managedUsed = calcUsedEntitlements(trunk.numbers);
      managedNumberCount = (
        <strong className="help-block">
          {trunk.numbers.length - managedNumberNotInSwitchCount}/{trunk.numbers.length} on&nbsp;
          switch, {managedUsed.numTollFreeDIDs} TF, {managedUsed.numLocalDIDs}
          &nbsp; local, {managedUsed.numE911DIDs} E911
        </strong>
      );

      const managedNumbersNoDefaultE911 = trunk.numbers.some(
        (number) => number.e911Default
      ) ? null : (
        <p>No default E911 number</p>
      );
      const managedNumbersNoE911 = trunk.numbers.some((number) => number.e911Enabled) ? null : (
        <p>No E911 enabled</p>
      );
      if (managedNumbersNoE911 || managedNumbersNoDefaultE911) {
        managedNumberErrors = (
          <div className="help-block attention-text" style={{ fontWeight: 700 }}>
            {managedNumbersNoE911}
            {managedNumbersNoDefaultE911}
          </div>
        );
      }
    } else if (!trunkImported) {
      managedNumberErrors = (
        <div className="help-block attention-text" style={{ fontWeight: 700 }}>
          Trunk has not been created/imported yet, or there were errors fetching its data
        </div>
      );
    }

    const hideImportNumbersBtn =
      processing ||
      !sipSwitchFound ||
      sipSwitchMisconfigured ||
      (unmanagedNumberCount <= 0 && trunk && trunk.id) ||
      !trunkImported;
    // cannot edit numbers that are not currently on the switch
    const atLeastOneSelected = !isEmpty(dmsNumberCheckboxes);
    const onlyOneSelected = atLeastOneSelected && dmsNumberCheckboxes.length === 1;
    const selectedNumbersAllE911Enabled = dmsNumberCheckboxes.every(this.isE911Enabled);
    const selectedNumbersAllOnSwitch = dmsNumberCheckboxes.every(this.isInSwitch);
    const selectedNumbersNoneOnSwitch = dmsNumberCheckboxes.every(negate(this.isInSwitch));
    const selectedAllPendingRemoval = dmsNumberCheckboxes.every(this.isPendingRemoval);
    const hideAddManagedNumberBtn = processing || !sipSwitchFound || !trunkImported;
    // show header for managed numbers only if the trunk has been imported
    const managedNumbersHeader = trunkImported ? (
      <div className="help-block" style={{ height: '56px' }}>
        Highlighted numbers are not on the SIP switch.
      </div>
    ) : null;

    if (atLeastOneSelected && !selectedNumbersAllOnSwitch) {
      managedNumberErrors = (
        <div className="help-block attention-text" style={{ fontWeight: 700 }}>
          Numbers that are not on the switch cannot be edited. Please export them to the switch
          first.
        </div>
      );
    }

    let quickSelectButtonText = <span>&mdash;</span>;
    if (trunk && trunk.numbers && dmsNumberCheckboxes.length === trunk.numbers.length) {
      quickSelectButtonText = <span>&#10004;</span>;
    } else if (!trunk || !trunk.numbers || dmsNumberCheckboxes.length === 0) {
      quickSelectButtonText = <span>&nbsp;</span>;
    }
    const quickSelectButtonStyle = {
      border: '1px solid #c6c6c6',
      height: '18px',
      width: '18px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    };
    const quickSelectButton = <div style={quickSelectButtonStyle}>{quickSelectButtonText}</div>;
    const showActions = !processing && sipSwitchFound && trunkImported;
    const quickSelectAction = showActions ? (
      <DropdownUL buttonText={quickSelectButton} minWidth="30px">
        <li>
          <a onClick={this.selectAll}>All</a>
        </li>
        <li>
          <a onClick={this.selectNone}>None</a>
        </li>
      </DropdownUL>
    ) : null;
    const selectedNumbersCount = (
      <span>{dmsNumberCheckboxes.length ? `${dmsNumberCheckboxes.length} selected` : ''}</span>
    );
    const grayedOutItem = { color: '#ccc', cursor: 'default' };
    const selectedNumbersAction = showActions ? (
      <DropdownUL buttonText={<span>Action</span>} minWidth="55px">
        <li>
          <a
            style={atLeastOneSelected && selectedNumbersAllOnSwitch ? null : grayedOutItem}
            onClick={this.editNumbers}
          >
            Edit
          </a>
        </li>
        <li>
          <a
            style={atLeastOneSelected && selectedAllPendingRemoval ? null : grayedOutItem}
            onClick={this.undoCustomerRemoval}
          >
            Undo Customer Removal
          </a>
        </li>
        <li>
          <a
            style={atLeastOneSelected && selectedNumbersNoneOnSwitch ? null : grayedOutItem}
            onClick={this.confirmExportToSwitch}
          >
            Export to the switch
          </a>
        </li>
        <li>
          <a
            style={onlyOneSelected && selectedNumbersAllE911Enabled ? null : grayedOutItem}
            onClick={this.confirmDefaultE911}
          >
            Make the default E911
          </a>
        </li>
        <li>
          <a style={atLeastOneSelected ? null : grayedOutItem} onClick={this.returnToInventory}>
            Delete
          </a>
        </li>
      </DropdownUL>
    ) : null;
    const managedNumbersActions = (
      <div>
        <div className="row">
          <div className="col-xs-1">{quickSelectAction}</div>
          <div className="col-xs-1">{selectedNumbersAction}</div>
          <div className="col-xs-2" style={{ position: 'relative', top: '10px', left: '25px' }}>
            {selectedNumbersCount}
          </div>
          <div className="col-xs-8">
            {!hideAddManagedNumberBtn ? (
              <button onClick={this.addNumber} className="btn btn-primary pull-right">
                Add a New Number
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );

    return (
      <div className="animated fadeIn">
        {editModal}
        <h3>
          Phone Numbers&nbsp;
          {processing ? (
            <small className="animated fadeIn flash infinite">Processing...</small>
          ) : null}
        </h3>
        <div className="row" style={{ marginBottom: '34px' }}>
          <div className="col-sm-4">
            {!hideImportNumbersBtn ? (
              <button
                onClick={this.importNumbersFromSwitch}
                style={{ marginTop: '10px', marginRight: '9px' }}
                className="btn btn-primary btn-xs pull-right"
              >
                Import &raquo;
              </button>
            ) : null}
            <div className={`well ${processing ? 'processing' : ''}`}>
              <h4>Switch Configuration</h4>
              {switchNumberCount}
              {switchErrors}
              <span className="help-block">Highlighted numbers are not managed.</span>
              <div style={{ height: '40px' }} />
              {switchNumbers}
            </div>
          </div>
          <div className="col-sm-8">
            <div className={`well ${processing ? 'processing' : ''}`}>
              <h4>Managed Numbers</h4>
              {managedNumberCount}
              {managedNumberErrors}
              {managedNumbersHeader}
              {managedNumbersActions}
              <br />
              {managedNumbers}
            </div>
          </div>
        </div>
      </div>
    );
  },
});

ReconcileNumbers.propTypes = {
  dispatch: PropTypes.func,
  product: PropTypes.object,
  trunk: PropTypes.object,
  sipSwitch: PropTypes.object,
};

export default connect()(ReconcileNumbers);
