import 'whatwg-fetch';

/*!
 * Synchronous actions
 */

export const ADD_NOTIF = 'ADD_NOTIF';
export function addNotif(notif, action) {
  return {
    type: ADD_NOTIF,
    payload: {
      notif,
      action,
    },
  };
}

export const REPLACE_NOTIFS = 'REPLACE_NOTIFS';
export function replaceNotifs(notifList) {
  return {
    type: REPLACE_NOTIFS,
    payload: notifList,
  };
}

export const REMOVE_NOTIF = 'REMOVE_NOTIF';
export function removeNotif(notifIndex) {
  return {
    type: REMOVE_NOTIF,
    payload: notifIndex,
  };
}

export const CLEAR_NOTIFS = 'CLEAR_NOTIFS';
export function clearNotifs() {
  return {
    type: CLEAR_NOTIFS,
  };
}

/*!
 * Async thunks
 */

// none yet
