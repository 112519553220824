export default function sortProducts(a, b) {
  if (a.productId < b.productId) {
    return -1;
  }
  if (a.productId > b.productId) {
    return 1;
  }

  const aIdentifier =
    a.subscription && a.subscription.SubscriptionNickname__c
      ? a.subscription.SubscriptionNickname__c
      : a.subscriptionNumber;
  const bIdentifier =
    b.subscription && b.subscription.SubscriptionNickname__c
      ? b.subscription.SubscriptionNickname__c
      : b.subscriptionNumber;

  if (aIdentifier < bIdentifier) {
    return -1;
  }
  if (aIdentifier > bIdentifier) {
    return 1;
  }
  return 0;
}
