import 'whatwg-fetch';
import handleAPI from '../lib/handleInternalAPI';
import notifyError from '../lib/notifyError';
import { byInvoiceDate } from '../../src/lib/sorting';
import { setSelectedAccount } from './selectedAccountActions';
import { setUIPerms } from './uipermsActions';

/*!
 * Synchronous actions
 */

export const SET_ORDERS = 'SET_ORDERS';
export function setOrders(orders) {
  return {
    type: SET_ORDERS,
    payload: orders,
  };
}

export const SET_INVOICES = 'SET_INVOICES';
export function setInvoices(invoices) {
  invoices.items.sort(byInvoiceDate);
  return {
    type: SET_INVOICES,
    payload: invoices,
  };
}

export const SET_CREDIT_CARDS = 'SET_CREDIT_CARDS';
export function setCreditCards(cards) {
  return {
    type: SET_CREDIT_CARDS,
    payload: cards,
  };
}

/*!
 * Async thunks
 */

export function fetchOrders(page = 1) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    if (!selectedAccount.id) {
      throw new Error('Selected account has no id - this should not happen.');
    }
    const orders = await fetch(
      `/api/v1/accounts/${selectedAccount.id}/orders?pageSize=20&page=${page}`,
      {
        method: 'GET',
        credentials: 'include',
      }
    )
      .then(handleAPI)
      .catch(notifyError(dispatch));
    dispatch(setOrders(orders));
  };
}

export function fetchInvoices(page = 1) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    if (!selectedAccount.id) {
      throw new Error('Selected account has no id - this should not happen.');
    }
    const invoices = await fetch(`/api/v1/accounts/${selectedAccount.id}/invoices?page=${page}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    dispatch(setInvoices(invoices));
  };
}

export function fetchCreditCards() {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    if (!selectedAccount.id) {
      throw new Error('Selected account has no id - this should not happen.');
    }
    const results = await fetch(`/api/v1/accounts/${selectedAccount.id}/credit-cards`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    dispatch(setCreditCards(results));
  };
}

export function addCreditCard(card) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    await fetch(`/api/v1/accounts/${selectedAccount.id}/credit-cards`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(card),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(fetchCreditCards());
  };
}

export function setDefaultCard(paymentMethodId) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    await fetch(`/api/v1/accounts/${selectedAccount.id}/credit-cards/${paymentMethodId}/default`, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    return dispatch(fetchCreditCards());
  };
}

export function removeCreditCard(paymentMethodId) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    await fetch(`/api/v1/accounts/${selectedAccount.id}/credit-cards/${paymentMethodId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    return dispatch(fetchCreditCards());
  };
}

export function updateCompanyContact(contact) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    const updatedAccount = await fetch(`/api/v1/accounts/${selectedAccount.id}/company-contact`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setSelectedAccount(updatedAccount));
    dispatch(setUIPerms(getState().selectedAccount, getState().session.roles));
  };
}

export function updateBillingContact(contact) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    const updatedAccount = await fetch(`/api/v1/accounts/${selectedAccount.id}/billing-contact`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(contact),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setSelectedAccount(updatedAccount));
    dispatch(setUIPerms(getState().selectedAccount, getState().session.roles));
  };
}

export function saveInvoiceEmails(emails) {
  // but..her..emails!
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    const updatedAccount = await fetch(
      `/api/v1/accounts/${selectedAccount.id}/invoice-recipients`,
      {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(emails),
        headers: {
          'content-type': 'application/json',
        },
      }
    )
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setSelectedAccount(updatedAccount));
    dispatch(setUIPerms(getState().selectedAccount, getState().session.roles));
  };
}
