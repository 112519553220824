import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from '../configureStore';
import history from '../history';

require('../lib/customUIErrorHandler');
require('../lib/googleAnalytics');

const { store } = configureStore({
  config: window.appConfig || {},
});

export function render(AppRouter) {
  ReactDOM.render(
    <AppContainer warnings={false}>
      <Provider store={store}>
        <Router history={history}>
          <AppRouter />
        </Router>
      </Provider>
    </AppContainer>,
    document.getElementById('react-root')
  );
}
