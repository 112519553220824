import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchOneProductInstance } from '../../../../actions/productActions';
import Layout from '../../Layout';

const AdminIntegrity = createReactClass({
  getInitialState() {
    const { dispatch, products } = this.props;
    // typically this would be when the come from another page
    if (products.length) {
      products.forEach(({ subscriptionNumber }) => {
        dispatch(
          fetchOneProductInstance({ subscriptionNumber, checkSubscriptionState: true })
        ).then(() => this.setState({ productsLoaded: this.state.productsLoaded + 1 }));
      });
    }
    return {
      productsLoaded: 0,
    };
  },
  componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props;
    const existingProducts = this.props.products;
    const nextProducts = nextProps.products;

    // this is necessary when loading the integrity page directly
    if (existingProducts.length !== nextProducts.length) {
      // fetch details for all newly added products
      nextProducts
        .filter(
          (p) => !existingProducts.find((oldP) => oldP.subscriptionNumber === p.subscriptionNumber)
        )
        .forEach(({ subscriptionNumber }) => {
          dispatch(
            fetchOneProductInstance({
              subscriptionNumber,
              checkSubscriptionState: true,
            })
          ).then(() => this.setState({ productsLoaded: this.state.productsLoaded + 1 }));
        });
    }
  },
  render() {
    const {
      products,
      rcf,
      dids,
      /**
       * @type AccountPermissions
       */
      accountPermissions = {},
    } = this.props;
    const { productsLoaded } = this.state;

    if (!accountPermissions.selectAnyAccount) {
      return (
        <Layout>
          <section className="animated fadeIn">
            <h1>Permission error</h1>
            <p>You lack permission to access this feature.</p>
          </section>
        </Layout>
      );
    }

    const productsWithTrunks = products.filter((p) => p.trunk && p.trunk.id);
    const trunkIds = productsWithTrunks.map((p) => p.trunk.id);
    const subNums = products.map((p) => p.subscriptionNumber);
    const trunkToSubMap = {};
    productsWithTrunks.forEach((p) => {
      trunkToSubMap[p.trunk.id] = p.subscriptionNumber;
    });

    const badDids = dids.filter(
      (did) =>
        !did.trunkId ||
        !trunkIds.includes(did.trunkId) ||
        !did.subscriptionId ||
        !subNums.includes(did.subscriptionId)
    );
    const badRules = rcf
      ? rcf.filter((rule) => !rule.subscriptionId || !subNums.includes(rule.subscriptionId))
      : [];

    let orphanDidBody = null;
    let orphanRuleBody = null;

    if (badDids.length) {
      orphanDidBody = (
        <div className="well">
          <h3>Managed Numbers With Issues</h3>
          <table className="table">
            <thead>
              <tr>
                <th>did.number</th>
                <th>did.trunkId</th>
                <th>did.subscriptionId</th>
                <th>did.customerId</th>
              </tr>
            </thead>
            <tbody>
              {badDids.map((did) => (
                <tr key={`baddid-${did.number}`}>
                  <td>{did.number}</td>
                  <td>{did.trunkId}</td>
                  <td>{did.subscriptionId}</td>
                  <td>{did.customerId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      orphanDidBody = <p>No known issues with DIDs.</p>;
    }
    if (badRules.length) {
      orphanRuleBody = (
        <div className="well">
          <h3>RCF Rules with Issues</h3>
          <table className="table">
            <thead>
              <tr>
                <th>rule.name</th>
                <th>rule.id</th>
                <th>rule.numbers</th>
                <th>rule.subscriptionId</th>
                <th>customerId</th>
              </tr>
            </thead>
            <tbody>
              {badRules.map((rule) => (
                <tr key={`badrule-${rule.id}`}>
                  <td>{rule.name}</td>
                  <td>{rule.id}</td>
                  <td>{(rule.numbers || []).join(', ')}</td>
                  <td>{rule.subscriptionId}</td>
                  <td>{rule.customerId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      orphanRuleBody = <p>No known issues with RCF rules.</p>;
    }

    return (
      <section className="animated fadeIn">
        <h1>Developer Debugging</h1>
        <h3>Possible Data Integrity Issues</h3>
        <span className="help-block">
          Below are possible mismatches or missing data on products and features of the selected
          customer&apos;s account.
        </span>
        <br />
        {productsLoaded !== products.length ? (
          <p>Loading products...</p>
        ) : (
          <div>
            {orphanDidBody}
            {orphanRuleBody}
          </div>
        )}
      </section>
    );
  },
});

AdminIntegrity.propTypes = {
  dispatch: PropTypes.func,
  products: PropTypes.array,
  selectedAccount: PropTypes.object,
  accountPermissions: PropTypes.object,
  rcf: PropTypes.array,
  dids: PropTypes.array,
};

function mapStateToProps({ selectedAccount, products, accountPermissions, rcf, dids }) {
  return { selectedAccount, products, accountPermissions, rcf, dids };
}

export default withRouter(connect(mapStateToProps)(AdminIntegrity));
