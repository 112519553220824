import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import Modal from '../../../../components/Modal/Modal';
import { updateDIDNumbers } from '../../../../actions/productActions';
import FirstFewNumbers from './FirstFewNumbers';

const EditDMSNumbersModal = createReactClass({
  propTypes: {
    numbers: PropTypes.array,
    product: PropTypes.object,
    continueAction: PropTypes.func,
    cancelAction: PropTypes.func,
    dispatch: PropTypes.func,
  },
  getInitialState() {
    const {
      product: { trunk },
      numbers,
    } = this.props;
    const editNumbers = trunk.numbers.filter((number) => numbers.includes(number.number));
    // if all numbers to be edited have the same value for an attribute, show that
    const commonAttributes = {};
    ['origin', 'provider', 'e911Enabled'].forEach((attribute) => {
      const common = editNumbers
        .map((number) => {
          let value = number[attribute];
          // convert e911Enabled boolean values to yes|no
          if (attribute === 'e911Enabled') {
            value = number[attribute] ? 'yes' : 'no';
          }
          return value;
        })
        .reduce((accu, current) => (accu === current ? accu : ''));
      commonAttributes[attribute] = common;
    });
    return Object.assign(commonAttributes, {
      processing: false,
      reason: 'Trunk reconciliation',
    });
  },
  applyNumberChanges() {
    const { dispatch, numbers, continueAction, subscriptionNumber } = this.props;
    const { e911Enabled, provider, origin, reason } = this.state;
    // only apply changes that are not blank
    const numberChanges = {};
    if (!isEmpty(origin)) {
      numberChanges.origin = origin;
    }
    if (!isEmpty(provider)) {
      numberChanges.provider = provider;
    }
    if (!isEmpty(e911Enabled)) {
      numberChanges.e911Enabled = e911Enabled === 'yes';
    }
    const numberUpdateParams = numbers.map((number) => ({
      number,
      origin: numberChanges.origin,
      provider: numberChanges.provider,
      e911Enabled: numberChanges.e911Enabled,
    }));

    this.setState({ processing: true });
    dispatch(
      updateDIDNumbers(numberUpdateParams, { subscriptionNumber }, { middleOut: true, reason })
    )
      .then(() => {
        this.setState({ processing: false });
        continueAction();
      })
      .catch(() => this.setState({ processing: false }));
  },
  onPropChange(propName) {
    return (event) => {
      const newState = { ...this.state };
      const newValue = event.target.value;
      set(newState, propName, newValue);
      this.setState(newState);
    };
  },
  render() {
    const { processing, e911Enabled, origin, provider, reason } = this.state;
    const { numbers, cancelAction } = this.props;

    const header = <h3>Bulk Edit Numbers</h3>;
    const body = (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-xs-4">Numbers:</label>
          <div className="col-xs-7">
            <FirstFewNumbers numbers={numbers} maxDisplay={3} />
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-11">Attributes:</label>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Origin</label>
          <div className="col-xs-7">
            <select value={origin} onChange={this.onPropChange('origin')}>
              <option value="">&nbsp;</option>
              <option value="Automation">Automation</option>
              <option value="Manual">Manual</option>
              <option value="LNP">LNP</option>
              <option value="Unknown">Unknown</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Provider</label>
          <div className="col-xs-7">
            <select type="text" value={provider} onChange={this.onPropChange('provider')}>
              <option value="">&nbsp;</option>
              <option value="BANDWIDTH">BANDWIDTH</option>
              <option value="GLBX">GLBX</option>
              <option value="LEVEL3">LEVEL3</option>
              <option value="PAETEC">PAETEC</option>
              <option value="VITELITY">VITELITY</option>
              <option value="UNKNOWN">UNKNOWN</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">e911 Enabled</label>
          <div className="col-xs-7">
            <select value={e911Enabled} onChange={this.onPropChange('e911Enabled')}>
              <option value="">&nbsp;</option>
              <option value="no">no</option>
              <option value="yes">yes</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Reason for change</label>
          <div className="col-xs-7">
            <input
              className="form-control"
              value={reason}
              type="text"
              required={true}
              onChange={this.onPropChange('reason')}
            />
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        You are about to make changes to all of the selected numbers. Are you sure?
        <br />
        <br />
        <button className="btn btn-default" disabled={processing} onClick={cancelAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={processing} onClick={this.applyNumberChanges}>
          {processing ? 'Applying...' : 'Save Changes'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(EditDMSNumbersModal);
