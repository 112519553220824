import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import phone from '../../lib/phone';

// View a Zuora Contact - billToContact or soldToContact
const DisplayContact = createReactClass({
  propTypes: {
    contact: PropTypes.object,
  },
  render() {
    const { contact } = this.props;
    return (
      <div style={{ overflowWrap: 'break-word' }} className="form-display-only">
        <div className="form-horizontal">
          <div className="form-group">
            <label className="col-xs-5 control-label">First Name</label>
            <div className="col-xs-7">{contact.firstName}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">Last Name</label>
            <div className="col-xs-7">{contact.lastName}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">Phone</label>
            <div className="col-xs-7">{phone(contact.workPhone)}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">Email</label>
            <div className="col-xs-7">{contact.workEmail}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">Street 1</label>
            <div className="col-xs-7">{contact.address.address1}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">Street 2</label>
            <div className="col-xs-7">{contact.address.address2}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">City</label>
            <div className="col-xs-7">{contact.address.city}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">State</label>
            <div className="col-xs-7">{contact.address.state}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">Postal Code</label>
            <div className="col-xs-7">{contact.address.zipCode}</div>
          </div>
          <div className="form-group">
            <label className="col-xs-5 control-label">Country</label>
            <div className="col-xs-7">{contact.address.country}</div>
          </div>
        </div>
      </div>
    );
  },
});

export default DisplayContact;
