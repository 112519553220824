import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import Modal from '../../../../components/Modal/Modal';
import phone from '../../../../lib/phone';
import { addSwitchDidsToDMS } from '../../../../actions/didsActions';
import { addDidsToTrunk, fetchOneProductInstance } from '../../../../actions/productActions';

const ImportSwitchNumbersModal = createReactClass({
  propTypes: {
    trunkId: PropTypes.string,
    numbers: PropTypes.array,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
    subscriptionNumber: PropTypes.string,
    productId: PropTypes.string,
  },
  getInitialState() {
    return {
      processing: '',
      reason: 'Trunk reconciliation',
    };
  },
  onPropChange(propName) {
    return (event) => {
      const newState = {
        [propName]: event.target.value,
      };
      this.setState(newState);
    };
  },
  importNumbersFromSwitch() {
    const { dispatch, trunkId, numbers, subscriptionNumber, productId } = this.props;
    const { reason } = this.state;

    const doneProcessing = () =>
      this.setState({
        processing: '',
      });
    const updateProcessing = (msg) => this.setState({ processing: msg });

    dispatch(
      addSwitchDidsToDMS({ productId, subscriptionNumber, reason }, numbers, updateProcessing)
    )
      .then(() => {
        this.setState({ processing: 'Pushing to trunk' });
        return dispatch(
          addDidsToTrunk(trunkId, {
            numbers: numbers.map((n) => n.number),
            reason,
          })
        );
      })
      .then(() => {
        this.setState({ processing: 'Refreshing data' });
        return dispatch(fetchOneProductInstance({ subscriptionNumber, checkSwitchState: true }));
      })
      .then(() => {
        doneProcessing();
        this.props.closeAction();
      })
      .catch(doneProcessing);
  },
  render() {
    const { processing, reason } = this.state;
    const { closeAction, numbers } = this.props;

    const header = <h3>Import Numbers from Switch to DID Manager</h3>;
    const body = (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-xs-4">Phone Numbers</label>
          <div className="col-xs-7">
            {numbers.map((n) => (
              <p className="col-xs-12" key={`import-${n.number}`}>
                {phone(n.number)}
              </p>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Reason for importing</label>
          <div className="col-xs-7">
            <input
              className="form-control"
              value={reason}
              type="text"
              required={true}
              onChange={this.onPropChange('reason')}
            />
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" disabled={!!processing} onClick={closeAction}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={!!processing}
          onClick={this.importNumbersFromSwitch}
        >
          {processing ? 'Importing...' : 'Import'}
        </button>
        {processing ? <span className="help-block">{processing}</span> : null}
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(ImportSwitchNumbersModal);
