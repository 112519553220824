/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';

import Layout from './Layout';
import Preloaded from './Preloaded';
import PortalUnauthorized from './PortalUnauthorized';
import TechnicalDifficulties from './TechnicalDifficulties';
import Login from './routes/login/Login';
import Accounts from './routes/account/Accounts';
import Help from './routes/help/Help';

const PortalApp = createReactClass({
  render() {
    const { session } = this.props;

    // It is assumed that someone who is not logged in will have an empty object for a session.
    if (!session) {
      return (
        <Switch>
          <Route path="*" component={Preloaded} />
        </Switch>
      );
    }

    // The cloud portal mostly disallows unauthenticated access.
    // <Redirect /> is only for react-router routes, we want to hit the server with /auth/saml
    if (!session.email) {
      const cont = window.location.pathname;
      return (
        <Switch>
          <Route key="n0" exact path="/login" component={Login} />
          <Route
            key="n1"
            path="*"
            render={() => {
              window.open(`/auth/saml?continue=${cont}`, '_self');
              return <Preloaded />; // prevents ui error when render returns undefined
            }}
          />
        </Switch>
      );
    }

    if (session.isBosDown) {
      return <TechnicalDifficulties />;
    }

    const noRoles = !session.roles || !session.roles.length;
    if (noRoles) {
      return <PortalUnauthorized />;
    }

    return (
      <Layout>
        <Switch>
          <Route path="/accounts" component={Accounts} />
          <Route path="/login" component={Login} />
          <Route path="/help" component={Help} />
          // 404 route; let them pick the account to load
          <Route render={() => <Redirect to="/accounts" />} />
        </Switch>
      </Layout>
    );
  },
});

PortalApp.propTypes = {
  match: PropTypes.object,
  session: PropTypes.object,
};

function mapStateToProps({ session }) {
  return { session };
}

export default withRouter(connect(mapStateToProps)(PortalApp));
