import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

require('./help.less');

/* https://stackoverflow.com/questions/442404/retrieve-the-position-x-y-of-an-html-element */
function getOffset(el) {
  let _x = 0;
  let _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

const HelpIcon = createReactClass({
  propTypes: {
    text: PropTypes.string, // text or JSX children
  },
  getInitialState() {
    return {
      visible: false,
      styles: {},
      id: `popover-${Math.random().toString(36).substring(2)}`,
    };
  },
  removePopover() {
    this.removeBodyListener();
    let popover = document.getElementById(this.state.id);
    if (popover) {
      popover.classList.remove('fadeIn');
      popover.classList.add('fadeOut');
      setTimeout(() => {
        popover = document.getElementById(this.state.id);
        if (popover) {
          popover.parentNode.removeChild(popover);
        }
      }, 200);
    }
  },
  removeBodyListener() {
    document.getElementsByTagName('body')[0].removeEventListener('click', this.toggleShow);
  },
  componentWillUnmount() {
    this.removePopover();
  },
  toggleShow(event) {
    if (event && event.stopPropagation) {
      // normally clicking the button again will not close it
      event.stopPropagation();
    }
    if (this.state.visible) {
      this.setState({ visible: false });
      this.removePopover();
      return;
    }

    // TODO: replace this.refs.popoverParent with this.popoverParent and test
    // eslint-disable-next-line react/no-string-refs
    const parent = this.refs.popoverParent;
    const offset = getOffset(parent);
    const popover = document.createElement('div');
    popover.id = this.state.id;
    popover.className = 'popover animated fadeIn fast bottom in';
    popover.style.display = 'block';
    popover.style.top = `${offset.top + 19}px`;
    popover.style.left = `${offset.left - 128}px`;
    popover.innerHTML = `<div class="arrow" style="left: 50%"></div>
            <div class="popover-content">${this.props.text}</div>`;
    popover.onclick = this.toggleShow;
    const body = document.getElementsByTagName('body')[0];
    body.addEventListener('click', this.toggleShow);
    body.appendChild(popover);
    this.setState({ visible: true });
  },
  render() {
    return (
      // TODO: need to fix this error before upgrading React
      // eslint-disable-next-line react/no-string-refs
      <span ref="popoverParent" className="help" onClick={this.toggleShow}>
        ?
      </span>
    );
  },
});

export default HelpIcon;

/* <h3 className="popover-title" style="display: none;"></h3> */
