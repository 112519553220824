import React from 'react';
import Layout from './Layout';

export default function Preloaded() {
  return (
    <Layout>
      <section className="animated fadeIn" style={{ textAlign: 'center' }}>
        <h1>Digium Cloud Services</h1>
        <br />
        <p>Loading portal data...</p>
      </section>
    </Layout>
  );
}
