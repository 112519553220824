import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import get from 'lodash/get';

import productNameFormatter from '../../../../lib/productNameFormatter';
import sortProducts from '../../../../lib/sortProducts';
import constants from '../../../../lib/constants';
import { updateProductSubscription } from '../../../../actions/productActions';
import { fetchSelectedAccount } from '../../../../actions/selectedAccountActions';
import EditLabel from '../../../../components/EditLabel/EditLabel';
import AccountSearch from './AccountSearch';
import UserSearch from './UserSearch';
import AdminEditAccount from './AdminEditAccount';
import CompanyContact from './company-contact/CompanyContact';
import PaymentInfo from './payment-info/PaymentInfo';
import Orders from './orders/Orders';
import Users from './users/Users';
import Invoices from './invoices/Invoices';
import AdminIntegrity from '../data-integrity/AdminIntegrity';
import Product from '../products/Product';

const ViewAccount = createReactClass({
  getInitialState() {
    return {
      processing: false,
    };
  },
  refreshAccount() {
    const { match, dispatch, selectedAccount } = this.props;
    const accountId = get(match, 'params.accountId');
    const selectedAccountId = get(selectedAccount, 'id');

    if (accountId && selectedAccountId !== accountId) {
      this.setProcessing(true);
      dispatch(fetchSelectedAccount({ id: accountId }, true))
        .then(() => this.setProcessing(false))
        .catch(() => this.setProcessing(false));
    }
  },
  componentWillMount() {
    this.refreshAccount();
  },
  componentDidUpdate() {
    this.refreshAccount();
  },
  setProcessing(processing) {
    this.setState({ processing });
  },
  render() {
    const { match, session, selectedAccount, accounts, products, accountPermissions } = this.props;
    const { processing } = this.state;

    const style = processing ? { cursor: 'wait' } : {};

    let selectedAccountName;
    if (selectedAccount.name) {
      selectedAccountName = selectedAccount.name;
    } else if (accounts[selectedAccount.id] && accounts[selectedAccount.id].name) {
      selectedAccountName = accounts[selectedAccount.id].name;
    } else if (selectedAccount.zuoraAccount && selectedAccount.zuoraAccount.name) {
      selectedAccountName = selectedAccount.zuoraAccount.name;
    } else {
      selectedAccountName = 'Loading...';
    }

    const orderedProducts = !accountPermissions.viewProducts
      ? []
      : [...products].sort(sortProducts);
    const customerAccountDetails = !accountPermissions.viewAccount ? (
      <span className="alert alert-warning">
        You do not have permission to view account details.
      </span>
    ) : (
      <table className="table table-condensed">
        <tbody>
          <tr>
            <th className="col-xs-4">User</th>
            <td>
              <p>
                {session.firstName} {session.lastName}
              </p>
              <p>{session.email}</p>
            </td>
          </tr>
          <tr>
            <th>Account Number</th>
            <td>
              {selectedAccount.zuoraAccount ? selectedAccount.zuoraAccount.accountNumber : null}
            </td>
          </tr>
          <tr>
            <th>Products</th>
            <td>
              {orderedProducts.length ? (
                orderedProducts.map((p) => (
                  <EditLabel
                    key={`prod-${p.subscriptionNumber}`}
                    editable={accountPermissions.manageProducts}
                    processing={processing}
                    text={productNameFormatter(p, true)}
                    editText={p.subscription ? p.subscription.SubscriptionNickname__c : ''}
                    onTextChanged={(text) => {
                      this.setProcessing(true);
                      this.props
                        .dispatch(
                          updateProductSubscription({
                            subscriptionNumber: p.subscriptionNumber,
                            updateBody: {
                              SubscriptionNickname__c: text,
                            },
                          })
                        )
                        .then(() => this.setProcessing(false))
                        .catch(() => this.setProcessing(false));
                    }}
                  ></EditLabel>
                ))
              ) : (
                <p></p>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );

    let editDetails = null;
    if (accountPermissions.manageAnyAccount) {
      editDetails = <AdminEditAccount />;
    }

    let adminTools = null;
    if (accountPermissions.selectAnyAccount) {
      adminTools = (
        <div className="well internal-only">
          <h4>Internal Only</h4>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th className="col-xs-4">Business Object Account ID</th>
                <td>{selectedAccount.id}</td>
              </tr>
              <tr>
                <th>Salesforce Account ID</th>
                <td>
                  {selectedAccount.zuoraAccount ? (
                    <a
                      href={`${constants.SALESFORCE_URL}/${selectedAccount.zuoraAccount.sfAccountId}`}
                      target="_blank"
                    >
                      {selectedAccount.zuoraAccount.sfAccountId}
                    </a>
                  ) : null}
                </td>
              </tr>
              <tr>
                <th>Zuora Account ID</th>
                <td>
                  <a
                    href={`${constants.ZUORA_URL}/apps/CustomerAccount.do?method=view&id=${selectedAccount.zuoraAccountId}`}
                    target="_blank"
                  >
                    {selectedAccount.zuoraAccountId}
                  </a>
                </td>
              </tr>
              {selectedAccount.zuoraAccount && selectedAccount.zuoraAccount.metrics ? (
                <tr>
                  <th>MRR</th>
                  <td>
                    {selectedAccount.zuoraAccount.metrics.contractedMrr.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </td>
                </tr>
              ) : null}
              <tr>
                <th>Feature Flags</th>
                <td>
                  {!(selectedAccount.featureFlags || []).length ? 'A Big Fat Goose Egg' : null}
                  {(selectedAccount.featureFlags || []).map((flag) => (
                    <span key={`flag-${flag}`}>
                      <span className="label label-default">{flag}</span>
                      &nbsp;
                    </span>
                  ))}
                </td>
              </tr>
              <tr>
                <th>Developer Debugging</th>
                <td>
                  <Link
                    className="btn btn-info btn-sm"
                    to={`/accounts/${selectedAccount.id}/data-integrity`}
                  >
                    View Advanced Report
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
          {editDetails}
          <br />
          <AccountSearch />
          <UserSearch />
        </div>
      );
    }

    const accountDetails = match.isExact ? (
      <div>
        <h1>{selectedAccountName}</h1>
        {customerAccountDetails}
        <br />
        <br />
        {adminTools}
      </div>
    ) : null;

    return (
      <section className="animated fadeIn" style={style}>
        {accountDetails}
        <Switch>
          {accountPermissions.viewAccount ? (
            <Route path={`${match.url}/company-contact`} component={CompanyContact} />
          ) : null}
          {accountPermissions.viewAccount ? (
            <Route path={`${match.url}/payment-info`} component={PaymentInfo} />
          ) : null}
          {accountPermissions.viewOrders ? (
            <Route path={`${match.url}/orders`} component={Orders} />
          ) : null}
          {accountPermissions.viewInvoices ? (
            <Route path={`${match.url}/invoices`} component={Invoices} />
          ) : null}
          {accountPermissions.manageUsers ? (
            <Route path={`${match.url}/users`} component={Users} />
          ) : null}
          {accountPermissions.selectAnyAccount ? (
            <Route path={`${match.url}/data-integrity`} component={AdminIntegrity} />
          ) : null}
          {accountPermissions.viewProducts ? (
            <Route path={`${match.url}/:subscriptionNumber`} component={Product} />
          ) : null}
        </Switch>
      </section>
    );
  },
});

ViewAccount.propTypes = {
  match: PropTypes.object,
  selectedAccount: PropTypes.object,
  accounts: PropTypes.object,
  session: PropTypes.object,
  accountPermissions: PropTypes.object,
  products: PropTypes.array,
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedAccount: state.selectedAccount,
    accounts: state.accounts,
    session: state.session,
    accountPermissions: state.accountPermissions,
    products: state.products,
  };
}

export default withRouter(connect(mapStateToProps)(ViewAccount));
