import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import tollFreePrefixes from '../../../../lib/tollFreePrefixes';
import { calcUsedEntitlements, intuitTollFree } from '../../../../lib/intuitTollFree';
import { fetchOneProductInstance } from '../../../../actions/productActions';
import { createWorkOrder } from '../../../../actions/workOrderActions';
import phone from '../../../../lib/phone';
import didOnlyEntitlements from '../../../../lib/didOnlyEntitlements';
import ConfirmWithText from '../../../../components/ConfirmWithText/ConfirmWithText';
import ProductNameFormatter from '../../../../lib/productNameFormatter';
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';
import { customerSetDidsForRemoval } from '../../../../actions/didsActions';

const supportLink = 'https://www.sangoma.com/support/contact/';

const diffRow = ({
  usageMatches,
  title,
  subsetEntitlement,
  actualEntitlement,
  isE911,
  internalOnlyViewMismatch,
  canRemove,
}) => {
  const difference = actualEntitlement - subsetEntitlement;
  let label = null;
  let description = null;
  const internalOnly = internalOnlyViewMismatch ? 'internal-only' : '';

  if (!usageMatches) {
    if (difference === 0) {
      // label = <span className="label label-info">OK</span>;
      // description = 'Billing matches your number(s)';
    } else if (difference > 0) {
      label = <span className="label label-danger">+ {difference}</span>;
      const helpText = `There are ${difference} additional number(s) on your service.
                Please select numbers for removal below, or billing will be adjusted to reflect
                the current quantity in service.`;
      description = isE911 ? (
        <a href={supportLink} target="_blank">
          Contact customer success to modify E911 numbers
        </a>
      ) : (
        <span>
          Remove {difference} number(s) below to avoid billing changes&nbsp;
          <HelpIcon text={helpText} />
        </span>
      );
    } else {
      label = <span className="label label-default">{Math.abs(difference)}</span>;
      description = `Contact support to add ${Math.abs(difference)} number(s)`;
    }
  }

  const row = (
    <tr>
      <td className="col-xs-2">{title}</td>
      <td className="col-xs-1 align-center">{subsetEntitlement}</td>
      {canRemove || internalOnly ? (
        <td className={`align-center ${internalOnly}`}>{label}</td>
      ) : null}
      {canRemove || internalOnly ? <td className={internalOnly}>{description}</td> : null}
    </tr>
  );

  return row;
};

const ManageProductNumbers = createReactClass({
  getInitialState() {
    this.refetchProduct();
    return {
      editModal: null,
      selectedNumbers: [],
    };
  },
  refetchProduct() {
    const { subscriptionNumber, dispatch } = this.props;
    return dispatch(
      fetchOneProductInstance({
        subscriptionNumber,
        checkSwitchState: true,
        checkSubscriptionState: true,
      })
    );
  },
  getProduct() {
    const { subscriptionNumber, products } = this.props;
    return products.find((p) => p.subscriptionNumber === subscriptionNumber);
  },
  toggleNumberSelection(didNumber) {
    return () => {
      const newState = { ...this.state };
      if (this.state.selectedNumbers.includes(didNumber)) {
        newState.selectedNumbers = this.state.selectedNumbers.filter((n) => n !== didNumber);
      } else {
        newState.selectedNumbers.push(didNumber);
      }
      this.setState(newState);
    };
  },
  requestDelete() {
    const { selectedAccount, subscriptionNumber, dispatch } = this.props;
    const { selectedNumbers } = this.state;
    const product = this.getProduct();
    const sub = product.subscription;
    const closeAction = () => this.setState({ editModal: null });

    // First, make sure they didn't get rid of too many

    const potentialRemainingNumbers = product.trunk.numbers.filter(
      (did) => !selectedNumbers.includes(did.number)
    );
    const potentialUsage = { ...calcUsedEntitlements(potentialRemainingNumbers) };
    const subset = pick(sub.entitlements, didOnlyEntitlements);

    let wouldBeBelowEntitlement = false;
    Object.keys(potentialUsage).forEach((entitlementName) => {
      // OK to be equal; in fact preferred
      if (potentialUsage[entitlementName] < subset[entitlementName]) {
        wouldBeBelowEntitlement = true;
      }
    });
    if (wouldBeBelowEntitlement) {
      const message = (
        <div>
          <p>You have chosen to remove more numbers than are necessary.</p>
          <p>
            <a href={supportLink} target="_blank">
              Contact support to continue
            </a>
          </p>
        </div>
      );
      this.setState({
        editModal: (
          <ConfirmWithText
            title="Contact Support to Continue"
            text={message}
            okAction={closeAction}
            closeAction={closeAction}
          />
        ),
      });
      return;
    }

    // OK to mark the numbers for removal - they didn't pick too many.

    const confirmation = (
      <div>
        <p>
          The following phone numbers will be removed. No more calls will be received on these
          numbers. It may take a few days for the changes to be processed.
        </p>
        {selectedNumbers.map((n) => (
          <p key={`conf-${n}`}>
            <strong>{phone(n)}</strong>
          </p>
        ))}
      </div>
    );
    const deleteAllSelectedNumbers = async () => {
      this.setState({ selectedNumbers: [], processing: true });

      let tollFreeDisplayList = '';
      const tollFreeSelectedNumbers = selectedNumbers.filter((n) =>
        tollFreePrefixes.includes(n.substring(0, 3))
      );
      if (tollFreeSelectedNumbers.length > 0) {
        tollFreeDisplayList = `Toll Free Numbers: ${tollFreeSelectedNumbers.join('; ')}`;
      }
      let localDisplayList = '';
      const localSelectedNumbers = selectedNumbers.filter(
        (n) => !tollFreeSelectedNumbers.includes(n)
      );
      if (localSelectedNumbers.length > 0) {
        localDisplayList = `Local Numbers: ${localSelectedNumbers.join('; ')}`;
      }
      return Promise.all([
        dispatch(
          createWorkOrder(subscriptionNumber, {
            caseType: 'Reconcile - customer amendment request',
            sipUsername: product.trunk.username,
            productCategory: 'SIP Trunking',
            caseSubject: `DID reconciliation required for account ${selectedAccount.zuoraAccount.accountNumber}, subscription ${subscriptionNumber}`,
            caseDescription: `The customer has chosen numbers for removal from their account.

                            Account name: ${selectedAccount.name}
                            Account Number: ${selectedAccount.zuoraAccount.accountNumber}
                            Subscription: ${subscriptionNumber}
                            Account Portal URL: ${window.location.origin}/accounts/${selectedAccount.id}
                            Trunk username: ${product.trunk.username}

                            ${localDisplayList}
                            ${tollFreeDisplayList}`,
          })
        ),
      ])
        .then(() =>
          dispatch(
            customerSetDidsForRemoval({
              numbersList: selectedNumbers,
              pendingRemovalFromTrunk: true,
            })
          )
        )
        .then(() => this.refetchProduct())
        .then(() => this.setState({ processing: false }));
    };

    this.setState({
      editModal: (
        <ConfirmWithText
          title="Confirm Phone Number Removal"
          text={confirmation}
          okAction={() => deleteAllSelectedNumbers()}
          closeAction={closeAction}
        />
      ),
    });
  },
  render() {
    const {
      selectedAccount: { featureFlags = [] },
      /**
       * @type AccountPermissions
       */
      accountPermissions,
    } = this.props;
    const { editModal, selectedNumbers, processing } = this.state;

    const product = this.getProduct();
    const sub = product ? product.subscription : null;
    const numbers =
      product && product.trunk && product.trunk.numbers
        ? product.trunk.numbers.filter((did) => !did.pendingRemovalFromTrunk)
        : [];
    const didsPendingRemoval =
      product && product.trunk && product.trunk.numbers
        ? product.trunk.numbers.filter((did) => did.pendingRemovalFromTrunk)
        : [];
    let subEntitlements = null;
    let numbersBody = null;
    let pendingRemovalNumbersBody = null;
    let releaseButton = null;
    let releaseNotice = null;
    let usageMatches = true;
    let canRemove = false;

    // Entitlements display
    // Damn if this isn't some spaghetti.
    const productSpecificEntitlements = get(sub, 'entitlements.productSpecificEntitlements');
    const canRenderEntitlements =
      productSpecificEntitlements &&
      product &&
      product.trunk &&
      product.productId === 'sip-trunking';
    if (canRenderEntitlements) {
      const usage = pick(calcUsedEntitlements(numbers), didOnlyEntitlements);
      const subset = pick(productSpecificEntitlements, didOnlyEntitlements);
      // They need to contact support to remove e911 numbers, so do not allow
      // editing if only e911 is mismatched. BUT we still want to show them
      // that things are not right.
      usageMatches = isEqual(usage, subset);
      const nonE911Mismatched =
        accountPermissions.manageProducts &&
        (usage.numLocalDIDs !== subset.numLocalDIDs ||
          usage.numTollFreeDIDs !== subset.numTollFreeDIDs);
      // only show a customer mismatches if they have a special flag, permissions,
      // and there are actual mismatches
      canRemove = accountPermissions.customerReconcile && nonE911Mismatched;
      // an employee can see mismatches when the customer cannot, if there are mismatches
      const internalOnlyViewMismatch =
        !featureFlags.includes('did-reconciliation-customer') &&
        accountPermissions.manageAnyAccount &&
        nonE911Mismatched;

      subEntitlements = (
        <div className={!canRemove && !internalOnlyViewMismatch ? 'col-sm-8' : 'col-sm-12'}>
          <div className="well">
            <table className="table table-condensed">
              <thead>
                <tr>
                  <th>Billing Summary</th>
                  <th className="align-center">Ordered</th>

                  {canRemove ? <th className="align-center">Additional</th> : null}
                  {canRemove ? <th /> : null}

                  {internalOnlyViewMismatch ? (
                    <th colSpan="2" className="internal-only">
                      <h4>Internal only</h4>
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {diffRow({
                  usageMatches,
                  title: 'Local Numbers',
                  subsetEntitlement: subset.numLocalDIDs,
                  actualEntitlement: usage.numLocalDIDs,
                  isE911: false,
                  internalOnlyViewMismatch,
                  canRemove,
                })}
                {diffRow({
                  usageMatches,
                  title: 'Toll Free Numbers',
                  subsetEntitlement: subset.numTollFreeDIDs,
                  actualEntitlement: usage.numTollFreeDIDs,
                  isE911: false,
                  internalOnlyViewMismatch,
                  canRemove,
                })}
                {diffRow({
                  usageMatches,
                  title: 'E911 Numbers',
                  subsetEntitlement: subset.numE911DIDs,
                  actualEntitlement: usage.numE911DIDs,
                  isE911: true,
                  internalOnlyViewMismatch,
                  canRemove,
                })}
              </tbody>
            </table>
          </div>
        </div>
      );

      if (canRemove && !usageMatches) {
        releaseNotice = (
          <div className="row">
            <div className="col-sm-12">
              <div className="well">
                <p>
                  <strong>Overview - Manage Phone Numbers</strong>
                </p>
                <br />
                <p>
                  Any differences between the phone numbers active on your service and your current
                  billing may be the result of temporary numbers previously provided while your
                  existing numbers transferred to our service. The quantity of these additional
                  numbers will be highlighted in red under &quot;Additional&quot;.
                </p>
                <p>
                  If there are numbers listed that are not actively used or unnecessary, please
                  select them for removal below. All numbers listed can be maintained on your
                  service if needed, and the account billing will be updated to reflect the
                  quantities of each phone number type that is in use on your service.
                </p>
              </div>
            </div>
          </div>
        );
      }
    }

    // Phone numbers spaghetti/fettuccine alfredo display
    if (!product) {
      numbersBody = <p>Loading product info...</p>;
    } else if (product && sub && sub.error) {
      numbersBody = (
        <p className="alert alert-danger">
          {sub.error.validationErrors
            ? sub.error.validationErrors.join(' ')
            : sub.error.message || sub.error}
        </p>
      );
    } else if (product && !product.trunk) {
      numbersBody = <p>Loading phone numbers...</p>;
    } else if (product && product.trunk && !product.trunk.numbers) {
      numbersBody = <p>No phone number data is available at this time.</p>;
    } else {
      if (canRemove) {
        let releaseButtonText = 'Select phone numbers to remove them from service';
        if (selectedNumbers.length) {
          const s = selectedNumbers.length === 1 ? '' : 's';
          releaseButtonText = `Remove ${selectedNumbers.length} phone number${s} from service...`;
        }
        releaseButton = (
          <button
            className="btn btn-danger btn-sm"
            onClick={this.requestDelete}
            disabled={processing || !selectedNumbers.length}
          >
            {releaseButtonText}
          </button>
        );
      }
      // only three columns for regular users, vs four for manageProducts
      numbersBody = (
        <div className={`well ${processing ? 'processing' : ''}`}>
          <h4>
            Phone Numbers in Service &nbsp;
            <HelpIcon text="This is a list of all phone numbers currently active on your service, excluding those numbers dedicated to Virtual Fax." />
            &nbsp;
            {processing ? <em className="animated flash slow infinite">Processing</em> : null}
          </h4>
          <br />
          {releaseButton}
          <table className="table">
            <thead>
              <tr>
                {canRemove ? <th /> : null}
                <th style={{ minWidth: '120px' }}>
                  Number &nbsp;
                  {canRemove ? (
                    <HelpIcon text="Check the box beside desired phone number(s), then click the Remove button to remove those numbers from your service." />
                  ) : null}
                </th>
                <th>
                  Type &nbsp;
                  <HelpIcon text="This indicates whether the number is classified as Local, Toll-Free or designated as E911 registered." />
                </th>
                <th>
                  Transferred Numbers &nbsp;
                  <HelpIcon text="This indicates if a number was transferred to our service from another carrier. “No” indicates it was purchased through Digium Cloud Services." />
                </th>
              </tr>
            </thead>
            <tbody>
              {numbers
                .filter((did) => !did.pendingRemovalFromTrunk)
                .map((did) => (
                  <tr key={`manage-${did.number}`}>
                    {canRemove ? (
                      <td>
                        {!did.e911Enabled ? (
                          <input
                            type="checkbox"
                            disabled={processing}
                            checked={selectedNumbers.includes(did.number)}
                            onClick={this.toggleNumberSelection(did.number)}
                          />
                        ) : null}
                      </td>
                    ) : null}
                    <td>{phone(did.number)}</td>
                    <td>
                      {intuitTollFree(did)}
                      &nbsp;
                      {did.e911Enabled ? 'E911' : null}
                    </td>
                    <td>{did.origin === 'LNP' ? 'yes' : 'no'}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );

      if (didsPendingRemoval.length) {
        pendingRemovalNumbersBody = (
          <div className="well">
            <h4>
              Pending Removal ({didsPendingRemoval.length}) &nbsp;
              <HelpIcon text="These phone numbers have been selected to be removed from service by a portal user and are currently being processed. This process may take up to 2-3 business days to complete." />
            </h4>
            <table className="table">
              <thead>
                <tr>
                  <th style={{ minWidth: '120px' }}>Number</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                {didsPendingRemoval.map((did) => (
                  <tr key={`manage-${did.number}`}>
                    <td>{phone(did.number)}</td>
                    <td>
                      {intuitTollFree(did)}
                      &nbsp;
                      {did.e911Enabled ? 'e911' : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }
    }

    return (
      <section className="animated fadeIn">
        {editModal}
        <h1>Manage Phone Numbers</h1>
        <h3>
          <span>{ProductNameFormatter(product)}</span>
        </h3>
        {releaseNotice}
        <div className="row">{subEntitlements}</div>
        <div className="row">
          <div className="col-xs-12">
            <p />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8">{numbersBody}</div>
          <div className="col-sm-4">{pendingRemovalNumbersBody}</div>
        </div>
      </section>
    );
  },
});

ManageProductNumbers.propTypes = {
  dispatch: PropTypes.func,
  accountPermissions: PropTypes.object,
  subscriptionNumber: PropTypes.string,
  products: PropTypes.array,
  selectedAccount: PropTypes.object,
};

function mapStateToProps({ products, selectedAccount, accountPermissions }) {
  return { products, selectedAccount, accountPermissions };
}

export default connect(mapStateToProps)(ManageProductNumbers);
