import React from 'react';

function processSIPTrunkingEntitlements(entitlements) {
  if (!entitlements.productSpecificEntitlements) {
    return null;
  }

  const productEntitlements = entitlements.productSpecificEntitlements;
  const displayedEntitlements = [
    <tr key="ent-local-numbers">
      <td>Local Numbers</td>
      <td>{productEntitlements.numLocalDIDs}</td>
    </tr>,
    <tr key="ent-tollfree-numbers">
      <td>Toll Free Numbers</td>
      <td>{productEntitlements.numTollFreeDIDs}</td>
    </tr>,
    <tr key="ent-e911-numbers">
      <td>E911 Numbers</td>
      <td>{productEntitlements.numE911DIDs}</td>
    </tr>,
    <tr key="ent-extended">
      <td>Extended Area Calling</td>
      <td>{productEntitlements.extendedArea ? 'enabled' : 'disabled'}</td>
    </tr>,
    <tr key="ent-international">
      <td>International Calling</td>
      <td>{productEntitlements.international ? 'enabled' : 'disabled'}</td>
    </tr>,
    <tr key="ent-metered">
      <td>Metered</td>
      <td>{productEntitlements.channelized ? 'no' : 'yes'}</td>
    </tr>,
  ];

  if (productEntitlements.channelized) {
    displayedEntitlements.push(
      <tr key="ent-channels">
        <td>Channels</td>
        <td>{productEntitlements.numChannels}</td>
      </tr>
    );
  }

  return displayedEntitlements;
}

function processHostedSwitchvoxEntitlements(entitlements) {
  if (!entitlements.productSpecificEntitlements) {
    return null;
  }

  const productEntitlements = entitlements.productSpecificEntitlements;
  const displayedEntitlements = processSIPTrunkingEntitlements(entitlements) || [];

  if (productEntitlements.totalSeatCount > 0) {
    displayedEntitlements.push(
      <tr key="ent-seat-count">
        <td>Seats</td>
        <td>{productEntitlements.totalSeatCount}</td>
      </tr>
    );
    const seats = productEntitlements.seats.map((seat) => (
      <tr key={`ent-${seat.name}`}>
        <td>{seat.name}</td>
        <td>{seat.count}</td>
      </tr>
    ));
    const seatTable = (
      <tr key="ent-seat-table">
        <td></td>
        <td>
          <table className="table table-condensed">
            <tbody>{seats}</tbody>
          </table>
        </td>
      </tr>
    );
    displayedEntitlements.push(seatTable);
  }
  if (productEntitlements.totalPhoneRentals > 0) {
    displayedEntitlements.push(
      <tr key="ent-phone-rental-count">
        <td>Phone Rentals</td>
        <td>{productEntitlements.totalPhoneRentals}</td>
      </tr>
    );
    const phoneRentals = productEntitlements.phoneRentals.map((pr) => (
      <tr key={`ent-${pr.name}`}>
        <td>{pr.name}</td>
        <td>{pr.count}</td>
      </tr>
    ));
    const phoneRentalTable = (
      <tr key="ent-phone-rental-table">
        <td></td>
        <td>
          <table className="table table-condensed">
            <tbody>{phoneRentals}</tbody>
          </table>
        </td>
      </tr>
    );
    displayedEntitlements.push(phoneRentalTable);
  }

  return displayedEntitlements;
}

export default function Entitlements({ entitlements = {} }) {
  const sipTrunkingEntitlementTypes = ['sip-trunking', 'sip-trunking-legacy'];
  const hostedSwitchvoxEntitlementTypes = ['hosted-switchvox', 'hosted-switchvox-legacy'];
  let displayedEntitlements;

  if (sipTrunkingEntitlementTypes.includes(entitlements.type)) {
    displayedEntitlements = processSIPTrunkingEntitlements(entitlements);
  } else if (hostedSwitchvoxEntitlementTypes.includes(entitlements.type)) {
    displayedEntitlements = processHostedSwitchvoxEntitlements(entitlements);
  }

  if (!displayedEntitlements) {
    displayedEntitlements = [
      <tr key="wat">
        <td>Unknown Entitlements</td>
        <td></td>
      </tr>,
    ];
  }

  return (
    <table className="table table-condensed">
      <tbody>{displayedEntitlements}</tbody>
    </table>
  );
}
