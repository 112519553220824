/**
 * Create an object with a permissions map that is designed for ease of use when
 * rendering the UI or checking route permissions on the backend.
 *
 * When should this be updated?
 * - when the selected account props change
 * - when the user's roles change
 *
 * Somebody who can *manage* can also *view*.
 *
 * The permissions are scoped to the UI's selected account.
 *
 * Managing or viewing products is allowed on all of an account's products.
 *
 * Permissions were originally here:
 *
 *      http://wiki.digium.internal/wiki/x/aYL5AQ "Portal Permissions"
 *
 *
 * @param {Account} selectedAccount
 * @param {string} [selectedAccount.id]
 * @param {Array<string>} [selectedAccount.featureFlags]
 * @param {Array<RoleInfo>} allUserRoles of the user across accounts
 * @return {AccountPermissions}
 */
module.exports.calcPermissions = function calcPermissions(
  { id: accountId, featureFlags = [] },
  allUserRoles = []
) {
  const perms = new AccountPermissions();
  const thisAccountRoles = allUserRoles.filter((ur) => ur.accountId === accountId);
  // digium employee
  const globalAccountRoles = allUserRoles.filter((ur) => ur.accountId === '*');

  perms.tier3 = !!globalAccountRoles.find(roleFor('tier3'));
  perms.selectAnyAccount = !!globalAccountRoles.length;

  // While technically these people can give oneself any permission, we won't show them
  // everything unless they have the other permissions too.
  perms.manageUsers = !!(
    thisAccountRoles.find(roleFor('manage-users')) ||
    globalAccountRoles.find(roleFor('manage-users'))
  );

  // still not allowed to manage billing information.
  perms.manageAnyAccount = !!globalAccountRoles.find(roleFor('manage-account'));
  perms.manageAccount = !!(
    perms.manageAnyAccount || thisAccountRoles.find(roleFor('manage-account'))
  );

  perms.viewAccount = !!(
    perms.selectAnyAccount ||
    perms.manageAccount ||
    thisAccountRoles.find(roleFor('view-account')) ||
    globalAccountRoles.find(roleFor('view-account'))
  );
  perms.viewInvoices = perms.viewAccount;
  perms.viewOrders = perms.viewAccount;

  perms.purchasing = !!(
    thisAccountRoles.find(roleFor('purchasing')) || globalAccountRoles.find(roleFor('purchasing'))
  );

  // billing should be allowed to be managed from users with manageAccount and
  // purchasing permissions
  perms.manageBilling = perms.manageAccount || perms.purchasing;

  // Business rule: managing one product means you can manage all products, from a UI
  // perspective. Same with viewing products.
  perms.manageProducts = !!(
    thisAccountRoles.find(roleFor('manage-products')) ||
    globalAccountRoles.find(roleFor('manage-products'))
  );
  perms.viewProducts = !!(
    perms.manageProducts ||
    thisAccountRoles.find(roleFor('view-products')) ||
    globalAccountRoles.find(roleFor('view-products'))
  );

  perms.manageGlobalRCF = !!globalAccountRoles.find(roleFor('manage-products'));
  perms.customerViewRCFLink = perms.manageProducts && featureFlags.includes('rcf');
  perms.manageRCF = perms.manageGlobalRCF || (perms.manageProducts && featureFlags.includes('rcf'));

  // we don't want to hide feature flag stuff from digium admins
  perms.viewClickvox =
    perms.selectAnyAccount || (perms.viewProducts && featureFlags.includes('clickvox'));
  perms.viewSwitchvox =
    perms.selectAnyAccount || (perms.viewProducts && featureFlags.includes('hosted-switchvox'));
  perms.viewTrunking =
    perms.selectAnyAccount || (perms.viewProducts && featureFlags.includes('sip-trunking'));

  perms.customerReconcile =
    perms.manageProducts && featureFlags.includes('did-reconciliation-customer');

  perms.employeeReconcile = !!perms.tier3;

  return perms;
};

class AccountPermissions {
  constructor() {
    /**
     * @type {boolean}
     */
    this.tier3 = false;
    /**
     * @type {boolean}
     */
    this.manageAccount = false;
    /**
     * @type {boolean}
     */
    this.manageAnyAccount = false;
    /**
     * @type {boolean}
     */
    this.manageBilling = false;
    /**
     * @type {boolean}
     */
    this.manageGlobalRCF = false;
    /**
     * @type {boolean}
     */
    this.manageProducts = false;
    /**
     * @type {boolean}
     */
    this.manageRCF = false;
    /**
     * @type {boolean}
     */
    this.manageUsers = false;
    /**
     * @type {boolean}
     */
    this.purchasing = false;
    /**
     * @type {boolean}
     */
    this.selectAnyAccount = false;
    /**
     * @type {boolean}
     */
    this.viewAccount = false;
    /**
     * @type {boolean}
     */
    this.viewInvoices = false;
    /**
     * @type {boolean}
     */
    this.viewOrders = false;
    /**
     * @type {boolean}
     */
    this.viewProducts = false;
    /**
     * @type {boolean}
     */
    this.viewClickvox = false;
    /**
     * @type {boolean}
     */
    this.viewTrunking = false;
    /**
     * @type {boolean}
     */
    this.viewSwitchvox = false;
    /**
     * @type {boolean}
     */
    this.customerReconcile = false;
    /**
     * @type {boolean}
     */
    this.employeeReconcile = false;

    /* purely UI visibility stuff */

    /**
     * @type {boolean}
     */
    this.customerViewRCFLink = false;
  }
}

/**
 * AccountPermissions houses UI actions or things-to-be-rendered, similar to Business Roles
 * but focused on answered the questions "should the user see this right now?"
 */
module.exports.AccountPermissions = AccountPermissions;

const roleFor = (roleName) => (ur) => ur.roleName === roleName;
