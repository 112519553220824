import * as actions from '../actions/billingActions';

const initialState = {
  orders: {}, // { pagination: {}, items: [] }
  invoices: {}, // { pagination: {}, items: [] }
  creditCards: [],
};

export default function billingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case actions.SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case actions.SET_CREDIT_CARDS:
      return {
        ...state,
        creditCards: action.payload,
      };
    default:
      return state;
  }
}
