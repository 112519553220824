import React from 'react';
import { Link } from 'react-router-dom';
import ProductNameFormatter from '../../../lib/productNameFormatter';

/**
 * ProductSidebar returns an array of product nav link components.
 *
 * Even if the basics about some of the products are known (subscriptionNumber and productId)
 * this function will not necessarily return links, because feature flags may not be enabled
 * for the product, thus making something like accountPermissions.viewTrunking=false
 *
 * Note: not a true component because it returns an array. but it needs to
 */
export default function ProductSidebar({ pathname, instance, accountPermissions, products }) {
  const { accountId, subscriptionNumber, productId } = instance;
  const to = `/accounts/${accountId}/${subscriptionNumber}`;
  const active = pathname.includes(to);
  const childLinks = [];
  const product = products.find((pi) => pi.subscriptionNumber === subscriptionNumber);

  if (!product) {
    return [];
  }

  switch (productId) {
    case 'clickvox':
      if (!accountPermissions.viewClickvox) {
        return [];
      }
      break;
    case 'hosted-switchvox':
      if (!accountPermissions.viewSwitchvox) {
        return [];
      }
      break;
    case 'sip-trunking':
      if (!accountPermissions.viewTrunking) {
        return [];
      }
      break;
    default:
      return [];
  }

  if (active) {
    // Today, managing phone numbers and RCF is only active for the SIP-Truking
    // product.
    //
    // Eventually, each product should have a mechanism for exposing its children
    // links.
    if (productId === 'sip-trunking') {
      const toDids = `${to}/phone-numbers`;
      childLinks.push(
        <li
          key={`didslink-${subscriptionNumber}`}
          className={pathname === toDids ? 'indented active' : 'indented'}
        >
          <Link to={toDids}>Phone Numbers</Link>
        </li>
      );

      if (accountPermissions.customerViewRCFLink) {
        const toRcf = `${to}/rcf`;
        childLinks.push(
          <li
            key={`rcflink-${subscriptionNumber}`}
            className={pathname === toRcf ? 'indented active' : 'indented'}
          >
            <Link to={toRcf}>RCF</Link>
          </li>
        );
      }
    }
  }

  // product feature flag needs to exist
  return [
    <li key={`p-side-${subscriptionNumber}`} className={pathname === to ? 'active' : ''}>
      <Link to={to}>{ProductNameFormatter(product)}</Link>
    </li>,
  ].concat(childLinks);
}
