import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import { updateDIDNumbers } from '../../../../actions/productActions';
import E911AddressNumberChoice from './E911AddressNumberChoice';

const ManagedNumberRow = createReactClass({
  getInitialState() {
    return {
      editModal: null,
    };
  },
  addEditE911() {
    const { setProcessing, switchDid, did, dispatch, subscriptionNumber } = this.props;
    const done = () => {
      setProcessing(false);
      this.setState({ editModal: null });
    };
    this.setState({
      editModal: (
        <E911AddressNumberChoice
          onChoice={async (address) => {
            setProcessing(true);
            const numberUpdateParams = {
              number: did.number,
              e911CallerName: address.e911CallerName,
            };
            const middleOut = !!switchDid;
            delete address.e911CallerName;
            numberUpdateParams.e911Address = address;
            await dispatch(
              updateDIDNumbers([numberUpdateParams], { subscriptionNumber }, { middleOut })
            )
              .then(() => done())
              .catch(() => done());
          }}
          did={did}
          closeAction={() => this.setState({ editModal: null })}
        />
      ),
    });
  },
  render() {
    const { did, switchDid, onToggle, toggled } = this.props;
    const { editModal } = this.state;
    const { origin, provider } = did;
    const e911 = did.e911Enabled ? 'yes' : 'no';

    const mismatchedE911Enabled = switchDid && switchDid.e911_enabled !== did.e911Enabled;
    const mismatchedE911Default = switchDid && switchDid.e911_default !== did.e911Default;
    let editE911AddressButton = did.e911Enabled ? (
      <button
        className="btn btn-xs btn-primary"
        onClick={this.addEditE911}
        style={{ marginLeft: '5px' }}
      >
        {did.e911Address ? 'Edit' : 'Add'}
      </button>
    ) : null;
    // disabled until we can push the E911 info upstream
    editE911AddressButton = null;
    // add a label to the row if this DID is currently the default E911 number on the trunk
    const e911DefaultOption =
      did.e911Enabled && e911 !== 'no' && did.e911Default ? (
        <span className="label label-default">yes</span>
      ) : null;

    return (
      <tr className={switchDid ? '' : 'danger'}>
        <td className="align-center">
          <input type="checkbox" checked={toggled} onClick={onToggle} />
        </td>
        <td>
          {editModal}
          {did.number}
        </td>
        <td className="attention-text" style={{ fontWeight: 700 }}>
          {did.pendingRemovalFromTrunk ? 'Pending' : null}
        </td>
        <td>{did.type}</td>
        <td>{origin}</td>
        <td>{provider}</td>
        <td>
          {e911}
          {mismatchedE911Enabled ? (
            <span style={{ marginLeft: '5px' }} className="label label-default">
              Mismatched
            </span>
          ) : null}
          {editE911AddressButton}
        </td>
        <td>
          {e911DefaultOption}
          {mismatchedE911Default ? (
            <span style={{ marginLeft: '5px' }} className="label label-default">
              Mismatched
            </span>
          ) : null}
        </td>
      </tr>
    );
  },
});

ManagedNumberRow.propTypes = {
  dispatch: PropTypes.func,
  did: PropTypes.object,
  switchDid: PropTypes.object,
  isTrunkDefault: PropTypes.string,
  processing: PropTypes.bool,
  setProcessing: PropTypes.func,
  subscriptionNumber: PropTypes.string,
  onToggle: PropTypes.func,
  toggled: PropTypes.bool,
};

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(ManagedNumberRow);
