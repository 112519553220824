import 'whatwg-fetch';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';

/*!
 * Synchronous actions
 */

export const SET_USERS = 'SET_USERS';

export function setUsers(payload) {
  return {
    type: SET_USERS,
    payload,
  };
}

export const SET_USER = 'SET_USER';

export function setUser(payload) {
  return {
    type: SET_USER,
    payload,
  };
}

export const CLEAR_USERS = 'CLEAR_USERS';

export function clearUsers() {
  return {
    type: CLEAR_USERS,
  };
}

/*!
 * Async thunks
 */

/**
 * fetchAccountUsers gets the users for the selected account and puts
 * them on the state.users object.
 */
export function fetchAccountUsers() {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    let users;
    try {
      users = await fetch(`/api/v1/accounts/${selectedAccount.id}/users`, {
        credentials: 'include',
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    dispatch(setUsers(users));
  };
}

/**
 * Create or add a new roles registry user, or just find an existing user
 * and add the supplied permissions.
 *
 * Expects the user to have populated roles array with enough information in the
 * role object to create a UserRole.
 */
export function createUserWithPermissions(user) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    let createdUser;
    try {
      createdUser = await fetch(`/api/v1/accounts/${selectedAccount.id}/users`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(user),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    // refresh all their permissions
    dispatch(setUser(createdUser));
  };
}

export function fetchAccountRolesforUser(userId) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    let roles;
    // eslint-disable-next-line no-useless-catch
    try {
      roles = await fetch(`/api/v1/accounts/${selectedAccount.id}/users/${userId}/roles`, {
        method: 'GET',
        credentials: 'include',
      })
        .then(handleAPI)
        .catch(notifyError(dispatch));
    } catch (err) {
      throw err;
    }
    // refresh all their permissions
    dispatch(
      setUser({
        id: userId,
        roles,
      })
    );
    return roles;
  };
}

export function addUserPermission(userId, { roleName }) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    await fetch(`/api/v1/accounts/${selectedAccount.id}/users/${userId}/roles`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ roleName }),
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    // refresh all their permissions
    return dispatch(fetchAccountRolesforUser(userId));
  };
}

export function removeUserPermissions(userId, { roleName, id }) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    await fetch(`/api/v1/accounts/${selectedAccount.id}/roles/${roleName}/user-roles/${id}`, {
      method: 'DELETE',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
    // refresh all their permissions
    return dispatch(fetchAccountRolesforUser(userId));
  };
}

export function checkUserIdByEmail(email) {
  return (dispatch) =>
    fetch(`/api/v1/user-emails/${email}`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));
}
