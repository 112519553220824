import 'whatwg-fetch';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';
import translateFromKamailioProvider from '../lib/translateFromKamailioProvider';
import possible11DigitsTo10 from '../lib/possible11DigitsTo10';

/*!
 * Synchronous actions
 */

export const SET_DIDS = 'SET_DIDS';

export function setDids(payload) {
  return {
    type: SET_DIDS,
    payload,
  };
}

export const SET_DID = 'SET_DID';

export function setSingleDid(payload) {
  return {
    type: SET_DID,
    payload,
  };
}

/*!
 * Async thunks
 */

export function fetchAllDids() {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();
    const result = await fetch(`/api/v1/accounts/${selectedAccount.id}/numbers`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(handleAPI)
      .catch(notifyError(dispatch));

    dispatch(setDids(result));
  };
}

export function addFreshPhoneNumbers({
  numbers,
  e911Enabled,
  provider,
  origin,
  capabilities,
  reason,
  subscriptionNumber,
  productId,
}) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();

    try {
      await fetch(`/api/v1/accounts/${selectedAccount.id}/numbers`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          reason,
          numbers: numbers.map((number) => ({
            subscriptionId: subscriptionNumber,
            product: productId,
            number,
            e911Enabled,
            provider,
            origin,
            capabilities,
          })),
        }),
      }).then(handleAPI);
    } catch (ex) {
      notifyError(dispatch)(ex);
      throw ex;
    }
  };
}

export function addSwitchDidsToDMS(
  { reason, subscriptionNumber, productId },
  switchNumbers,
  updateProcessing
) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();

    try {
      updateProcessing('Creating numbers');
      await fetch(`/api/v1/accounts/${selectedAccount.id}/numbers`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          reason,
          numbers: switchNumbers.map((did) => ({
            subscriptionId: subscriptionNumber,
            product: productId,
            e911Enabled: did.e911_enabled,
            number: possible11DigitsTo10(did.number),
            e911Address: did.e911Address,
            e911CallerName: did.e911CallerName,
            provider: did.e911_provider
              ? translateFromKamailioProvider(did.e911_provider)
              : 'UNKNOWN',
            origin: 'Unknown',
            capabilities: {
              e911: did.e911_enabled,
              sms: false,
              cnam: false,
            },
          })),
        }),
      }).then(handleAPI);
    } catch (ex) {
      notifyError(dispatch)(ex);
      throw ex;
    }
  };
}

export function customerSetDidsForRemoval({ numbersList, pendingRemovalFromTrunk }) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();

    try {
      await fetch(`/api/v1/accounts/${selectedAccount.id}/customer-numbers`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          numbers: numbersList.map((number) => ({
            number,
            pendingRemovalFromTrunk,
          })),
          reason: 'Customer request',
        }),
      }).then(handleAPI);
    } catch (ex) {
      notifyError(dispatch)(ex);
    }
  };
}

export function removeDIDsFromTrunk({ numbers, trunkId, middleOut, reason }) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();

    if (!numbers.length) {
      return;
    }

    try {
      await fetch(`/api/v1/accounts/${selectedAccount.id}/trunks/${trunkId}/numbers`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          numbers,
          middleOut,
          reason,
        }),
      }).then(handleAPI);
    } catch (ex) {
      notifyError(dispatch)(ex);
      throw ex;
    }
  };
}

export function fixUnroutableDid({ phoneNumber, sipUsername, fixAction }) {
  return async (dispatch, getProps) => {
    const { selectedAccount } = getProps();

    try {
      await fetch(
        `/api/v1/accounts/${selectedAccount.id}/sip-switch-trunks/${sipUsername}/phone-numbers/${phoneNumber}/fix-unroutable`,
        {
          method: 'PUT',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            fixAction,
            reason: 'Fixing unroutable number',
          }),
        }
      ).then(handleAPI);
    } catch (ex) {
      notifyError(dispatch)(ex);
    }
  };
}
