import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

/**
 * Component for displaying Drop Down menus.
 *
 * Required props:
 *   - buttonText: JSX to display for the button that shows the Drop Down items
 *   - children: JSX to populate the list of items to show when the button is pressed. This comes from whatever JSX is
 *               inserted within the <DropdownUL> tags and does not need to be manually passed in to the component
 *   - minWidth: String that represents the minimum width for the Drop Down button (this helps in aligning UI).
 *
 * Notes:
 *   - children are responsible for handling an onClick event that drives the behavior of making a given choice. This
 *     component will simply close itself after a selection is made.
 *   - since we attach an onClick event to the entire body to be able to hide the Drop Down items, this component
 *     will receive another onClick event after a selection is made, thus hiding then showing the Drop Down items again.
 *     Due to this, it is necessary for the onClick action attached to the children to call event.stopPropagation() in
 *     order to stop this behavior.
 */
const DropdownUL = createReactClass({
  propTypes: {
    buttonText: PropTypes.object,
    children: PropTypes.array,
    minWidth: PropTypes.string,
  },
  getInitialState() {
    return { visible: false, width: '50px' };
  },
  show() {
    if (this.state.visible) {
      return;
    }
    // if the user clicks anywhere on the screen, hide the drop down items
    const body = document.getElementsByTagName('body')[0];
    body.addEventListener('click', this.hide);
    this.setState({ visible: true });
  },
  hide() {
    if (!this.state.visible) {
      return;
    }
    // make sure we remove the onClick event we added to the body earlier so we don't leak the handler
    this.removeBodyListener();
    this.setState({ visible: false });
  },
  removeBodyListener() {
    document.getElementsByTagName('body')[0].removeEventListener('click', this.hide);
  },
  componentWillUnmount() {
    this.removeBodyListener();
  },
  render() {
    const { buttonText, children, minWidth } = this.props;
    const { visible } = this.state;

    const button = (
      <button className="btn btn-default dropdown-toggle">
        <div style={{ minWidth }}>
          <div className="row">
            <div className="col-xs-6">{buttonText}</div>
            <div className="col-xs-6">
              <span className="caret" />
            </div>
          </div>
        </div>
      </button>
    );

    return (
      <div className={`dropdown ${visible ? 'open' : ''}`} onClick={this.show}>
        {button}
        <ul className="dropdown-menu">{children}</ul>
      </div>
    );
  },
});

export default DropdownUL;
