// modify body, glory be unto validation
function joinValidationErrors(body) {
  if (
    body &&
    body.message === 'Validation failed' &&
    body.validationErrors &&
    body.validationErrors.join
  ) {
    body.message += ` (${body.validationErrors.join(', ')})`;
  }
}
/**
 * Handle errors or parse a JSON response from a fetch() request.
 * @param {fetch.Response} httpRes
 * @return {Promise.<object>}
 */
module.exports = function handleInternalAPI(httpRes) {
  if (!httpRes.ok) {
    let contentHeader = '';

    if (httpRes.headers) {
      if (httpRes.headers.has('content-type')) {
        contentHeader = httpRes.headers.get('content-type');
      } else if (httpRes.headers.has('Content-Type')) {
        contentHeader = httpRes.headers.get('Content-Type');
      }
    }

    if (contentHeader.includes('json')) {
      return httpRes.json().then((body) => {
        joinValidationErrors(body);
        const err = new Error(body.message || 'Server error');
        Object.keys(body).forEach((k) => {
          err[k] = body[k];
        });
        err.status = httpRes.status;
        err.statusCode = httpRes.status;
        throw err;
      });
    }
    return httpRes.text().then((text = '') => {
      try {
        text = JSON.parse(text);
        joinValidationErrors(text);
      } catch (ignored) {}
      const err = new Error(text.message || text.error || text);
      err.status = httpRes.status;
      err.statusCode = httpRes.status;
      throw err;
    });
  }
  if (httpRes.status === 204) {
    return Promise.resolve();
  }
  return httpRes.json().then((body) => {
    joinValidationErrors(body);
    return body;
  });
};
