import { createBrowserHistory, createMemoryHistory } from 'history';
import { isBrowser, isNode } from 'browser-or-node';

let history;

if (isBrowser) {
  // Create a history of your choosing (we're using a browser history in this case for react)
  history = createBrowserHistory();
} else if (isNode) {
  // Create a history of your choosing (we're using a memory history in this case for testing)
  history = createMemoryHistory();
}

/**
 * Used to access history.push for dispatching throughout the application
 */
export default history;
