import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import createReactClass from 'create-react-class';
import EditRule from './EditRule';
import { fetchAllRcfRules, removeRcfRule, updateRcfRule } from '../../../../actions/rcfActions';
import { fetchOneProductInstance } from '../../../../actions/productActions';
import { clearNotifs } from '../../../../actions/notifActions';
import ConfirmWithText from '../../../../components/ConfirmWithText/ConfirmWithText';
import ConfirmRuleset from './ConfirmRuleset';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import phone from '../../../../lib/phone';
import ProductNameFormatter from '../../../../lib/productNameFormatter';
import RuleNumbersDisplay from './RuleNumbersDisplay';
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';

const loadingScreen = (
  <section>
    <h1>Remote Call Forwarding (RCF)</h1>
    <p>Loading RCF data...</p>
  </section>
);
const loadingFailureScreen = (
  <section>
    <h1>Remote Call Forwarding (RCF) Error</h1>
    <p>We failed to retrieve RCF and product data.</p>
  </section>
);
const noTrunkScreen = (
  <section>
    <h1>Remote Call Forwarding (RCF)</h1>
    <p>
      Not enough information is available yet to manage RCF. Most of the time this means the service
      is still being setup. If this seems like an error, contact &nbsp;
      <Link to="/help">support</Link>.
    </p>
  </section>
);
const noTrunkNumbersYet = (
  <section>
    <h1>Remote Call Forwarding (RCF)</h1>
    <p>
      We are not seeing any phone numbers linked with this service currently. Please check back
      later.
    </p>
  </section>
);
const noPermissionsScreen = (
  <section>
    <h1>Remote Call Forwarding (RCF)</h1>
    <p>You do not have permission to manage RCF for this account.</p>
  </section>
);

const labelStyle = {
  fontSize: '119%',
  padding: '2px 8px',
};

const RcfRoute = createReactClass({
  componentWillMount() {
    const { subscriptionNumber } = this.props;
    this.loadProduct(subscriptionNumber);
  },
  getInitialState() {
    const { products, subscriptionNumber } = this.props;

    const product = products.find((pi) => pi.subscriptionNumber === subscriptionNumber);
    return {
      // If coming from another page, there might already be a product available
      // so we can display stuff immediately, then it will automatically update
      // if anything changed.
      preloading: !product,
      loadingError: false,
      editModal: null,
      allEnabled: false,
      processing: false,
      numberListVisible: {
        // rule.id: boolean
      },
    };
  },
  loadProduct(subscriptionNumber) {
    Promise.all([
      this.props.dispatch(
        fetchOneProductInstance({
          subscriptionNumber,
          checkSwitchState: true,
        })
      ),
      this.props.dispatch(fetchAllRcfRules()),
    ])
      .then(() =>
        this.setState({
          loadingError: false,
          preloading: false,
          processing: false,
        })
      )
      .catch(() =>
        this.setState({
          preloading: false,
          processing: false,
          loadingError: true,
        })
      );
  },
  killModal() {
    this.setState({
      editModal: null,
    });
    this.setState({ processing: true });
    this.props
      .dispatch(fetchAllRcfRules())
      .then(() => this.setState({ processing: false }))
      .catch(() => this.setState({ processing: false }));
  },
  editRule(rule, product) {
    this.setState({ processing: true });
    this.props
      .dispatch(fetchAllRcfRules())
      .then(() => this.setState({ processing: false }))
      .catch(() => this.setState({ processing: false }));
    const ruleId = rule ? rule.id : null;
    this.setState({
      editModal: <EditRule closeAction={this.killModal} ruleId={ruleId} product={product} />,
    });
  },
  toggleAllOn() {
    const { rcf, subscriptionNumber } = this.props;

    this.setState({
      editModal: (
        <ConfirmRuleset
          rulesToUpdate={rcf
            .filter((r) => r.subscriptionId === subscriptionNumber)
            .map((r) => r.id)}
          closeAction={this.killModal}
        />
      ),
    });
  },
  toggleAllOff() {
    const { dispatch, rcf, subscriptionNumber } = this.props;

    // we don't want possible confusion if there is a notif that shows X rules enabled
    dispatch(clearNotifs());
    this.setState({ processing: true });
    Promise.all(
      rcf
        .filter((r) => r.subscriptionId === subscriptionNumber)
        .map((rule) => {
          rule.enabled = true;
          return dispatch(
            updateRcfRule({
              enabled: false,
              id: rule.id,
            })
          );
        })
    )
      .then(() => this.setState({ processing: false }))
      .catch(() => this.setState({ processing: false }));
  },
  toggleRuleOn(rule) {
    this.setState({
      editModal: <ConfirmRuleset rulesToUpdate={[rule.id]} closeAction={this.killModal} />,
    });
  },
  toggleRuleOff(rule) {
    const { dispatch } = this.props;
    const rcf = [...this.props.rcf];

    this.setState({
      rcf,
      processing: true,
    });
    dispatch(
      updateRcfRule({
        enabled: false,
        id: rule.id,
      })
    )
      .then(() => {
        this.setState({ processing: false });
        rcf.every((r) => {
          if (r.id === rule.id) {
            r.enabled = false;
            return false;
          }
          return true;
        });
        dispatch(clearNotifs());
      })
      .catch(() => this.setState({ processing: false }));
  },
  confirmBeforeDelete(ruleId) {
    this.setState({
      editModal: (
        <ConfirmWithText
          title="Confirm delete"
          text="Do you want to remove this forwarding rule?"
          okAction={() => this.deleteRule(ruleId)}
          closeAction={this.killModal}
        />
      ),
    });
  },
  deleteRule(ruleId) {
    const { dispatch } = this.props;

    this.setState({ processing: true });
    return dispatch(removeRcfRule(ruleId))
      .then(() => this.setState({ processing: false }))
      .catch(() => this.setState({ processing: false }));
  },
  render() {
    const { products, subscriptionNumber, accountPermissions } = this.props;
    const { preloading, loadingError, editModal, processing } = this.state;

    if (preloading || !this.props.rcf) {
      return loadingScreen;
    }

    if (loadingError) {
      return loadingFailureScreen;
    }

    let howManyForwardedInfo = null;
    let howManyEnabledInfo = null;
    const rcf = this.props.rcf.filter((rule) => rule.subscriptionId === subscriptionNumber);
    const product = products.find((pi) => pi.subscriptionNumber === subscriptionNumber);
    // There will always be a trunk property after we loaded product details. There will not
    // be a trunk property before that (was just the /subscription-numbers route).
    // We know we are waiting on the trunk info when the trunk is not there, and we know
    // the trunk is being setup when it is an empty object.
    const productListLoadedInfoWaiting = product && !product.trunk;
    if (productListLoadedInfoWaiting) {
      return loadingScreen;
    }
    // no product means either it is is a 404 / bad request, or the trunk is not setup
    const trunkLoadedNotSetup = product && product.trunk && !product.trunk.id;
    if (!product || trunkLoadedNotSetup) {
      return noTrunkScreen;
    }

    if (!accountPermissions.manageRCF) {
      return noPermissionsScreen;
    }

    // from now on we have a loaded product and trunk
    const noNumbers = !product.trunk.numbers || !product.trunk.numbers.length;
    // In case numbers were put back in inventory, do NOT hide any rules that lingered,
    // even if those rules do not have any numbers attached at the moment.
    const noRules = !rcf || !rcf.length;
    if (noNumbers && noRules) {
      return noTrunkNumbersYet;
    }
    const dids = product.trunk.numbers;
    const howManyForwarded = rcf.reduce((last, next) => last.concat(next.numbers), []).length;
    const allForwarded = howManyForwarded === dids.length;
    const allEnabled = rcf.filter((rule) => rule.enabled).length === rcf.length;
    const addRuleButton = (
      <button
        className="btn btn-primary btn-sm"
        disabled={allForwarded || processing}
        onClick={() => this.editRule(null, product)}
      >
        Add Forwarding Rule
      </button>
    );

    if (!dids.length) {
      howManyForwardedInfo = 'No phone numbers were found, so creating rules is disabled.';
    } else if (allForwarded) {
      howManyForwardedInfo = (
        <p>
          All phone numbers <strong>configured</strong> for remote call forwarding
        </p>
      );
    } else {
      howManyForwardedInfo = (
        <p>
          <span className="label label-primary" style={labelStyle}>
            {howManyForwarded}
          </span>
          &nbsp;phone number(s) <strong>configured</strong> for remote call forwarding
        </p>
      );
    }
    const onToggleAll = allEnabled ? this.toggleAllOff : this.toggleAllOn;

    let ruleList;
    let totalEnabledRuleNumbers = 0;
    if (rcf.length) {
      ruleList = (
        <table className="table">
          <thead>
            <tr className="active">
              <th>
                <span className="help-block">Name</span>
              </th>
              <th>
                <span className="help-block">Phone Numbers</span>
              </th>
              <th>
                <span className="help-block">Destination</span>
              </th>
              <th />
              <th>
                <span className="help-block">Disable / Enable</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {rcf.map((rule) => (
              <tr key={rule.id}>
                <td className="col-xs-3">{rule.name}</td>
                <td className="col-xs-2">
                  <RuleNumbersDisplay rule={rule} />
                </td>
                <td className="col-xs-2">
                  <div className="help-block">{phone(rule.destination)}</div>
                </td>
                <td className="col-xs-3 align-center" style={{ lineHeight: '2em' }}>
                  <button
                    className="btn btn-xs btn-warning"
                    disabled={processing}
                    onClick={() => this.confirmBeforeDelete(rule.id)}
                  >
                    Delete
                  </button>
                  <button
                    className="btn btn-xs btn-primary"
                    disabled={processing}
                    onClick={() => this.editRule(rule, product)}
                    style={{ marginLeft: '5px' }}
                  >
                    Edit
                  </button>
                </td>
                <th className="col-xs-3 text-muted">
                  <ToggleSwitch
                    onChange={() =>
                      rule.enabled ? this.toggleRuleOff(rule) : this.toggleRuleOn(rule)
                    }
                    checked={rule.enabled}
                    disabled={processing}
                  />
                  {rule.enabled ? (
                    <strong className="help-inline pull-right">Enabled&nbsp;</strong>
                  ) : null}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      );

      totalEnabledRuleNumbers = rcf
        .filter((rule) => rule.subscriptionId === subscriptionNumber && rule.enabled)
        .reduce((last, next) => last + (next.numbers || []).length, 0);
      if (!totalEnabledRuleNumbers) {
        howManyEnabledInfo = (
          <div className="alert alert-default">
            <p>
              No phone numbers currently <strong>forwarded</strong>
            </p>
          </div>
        );
      } else {
        howManyEnabledInfo = (
          <div className="alert alert-warning animated flipInX">
            <p>
              <span style={labelStyle} className="label label-primary">
                {totalEnabledRuleNumbers}
              </span>
              &nbsp;phone number(s) currently being <strong>forwarded</strong>
            </p>
          </div>
        );
      }
    } else if (dids.length) {
      ruleList = (
        <p>
          <br />
          You have no RCF rules. Start by adding one.
        </p>
      );
    }
    // TODO: Remove by public launch (should be re-added later...)
    const disableForwardingInfo = false;
    return (
      <section className="animated fadeIn">
        {editModal}
        <h1>Remote Call Forwarding (RCF)</h1>
        <p>
          <strong>Selected service: </strong>
          {ProductNameFormatter(product)}
        </p>
        <br />
        {howManyEnabledInfo}
        {disableForwardingInfo ? (
          <div className="alert alert-default">{howManyForwardedInfo}</div>
        ) : null}
        <br />
        <h4>
          <strong>RCF Rules</strong>
        </h4>
        {addRuleButton}
        <button
          className="btn btn-primary btn-sm pull-right"
          style={{ marginTop: '3px' }}
          onClick={onToggleAll}
          disabled={processing}
        >
          {allEnabled ? 'Disable' : 'Enable'} All Rules
        </button>
        &nbsp;
        <HelpIcon text="Forwarding rules define how calls to your phone numbers are forwarded to external numbers when RCF is enabled." />
        {ruleList}
      </section>
    );
  },
});

RcfRoute.propTypes = {
  dispatch: PropTypes.func,
  rcf: PropTypes.array,
  dids: PropTypes.array,
  products: PropTypes.array,
  subscriptionNumber: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    rcf: state.rcf,
    dids: state.dids,
    products: state.products,
    accountPermissions: state.accountPermissions,
  };
}

export default connect(mapStateToProps)(RcfRoute);
