import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import md5 from 'blueimp-md5';
import createReactClass from 'create-react-class';
import Modal from '../../../../components/Modal/Modal';
import { enableTrunk } from '../../../../actions/productActions';

const EnableTrunkModal = createReactClass({
  propTypes: {
    email: PropTypes.string, // person in the UI enabling the trunk
    subscriptionNumber: PropTypes.string,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
  },
  getInitialState() {
    return {
      processing: false,
      reason: '',
    };
  },
  onPropChange(propName) {
    return (event) => {
      const newState = {
        [propName]: event.target.value,
      };
      this.setState(newState);
    };
  },
  enableTrunk() {
    const conf = window.confirm(
      "Enabling the trunk will restore the customer's old SIP credentials. Continue?"
    ); // eslint-disable-line no-alert
    if (!conf) {
      return;
    }
    const doneProcessing = () =>
      this.setState({
        processing: false,
      });
    const { subscriptionNumber } = this.props;
    const { reason } = this.state;

    this.setState({ processing: true });

    this.props
      .dispatch(enableTrunk({ subscriptionNumber, reason }))
      .then(() => {
        doneProcessing();
        this.props.closeAction();
      })
      .catch(doneProcessing);
  },
  render() {
    const { processing, reason } = this.state;
    const { closeAction, email } = this.props;
    const gravatarImg = `https://gravatar.com/avatar/${md5(email)}s=100&f=blank`;

    const header = <h3>Enable SIP Trunk</h3>;
    const body = (
      <div className="form-horizontal">
        <div className="form-group">
          <div className="col-xs-11">
            <p>
              Enabling the SIP Trunk will{' '}
              <strong>restore the customer&apos;s SIP credentials and allow registrations</strong>.
            </p>
            <p>
              <strong>Your name</strong> will be logged as the person who enabled the trunk.
            </p>
          </div>
        </div>
        <br />
        <div className="form-group">
          <label className="col-xs-4">Reason for enabling trunk</label>
          <div className="col-xs-6">
            <input
              className="form-control"
              value={reason}
              type="text"
              required={true}
              onChange={this.onPropChange('reason')}
            />
          </div>
          <div className="col-xs-1">
            <img
              className="img-circle"
              style={{ height: '33px', width: '33px' }}
              src={gravatarImg}
            />
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={processing} onClick={this.enableTrunk}>
          {processing ? 'Enabling...' : 'Enable'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(EnableTrunkModal);
