import React from 'react';
import { Route, Switch } from 'react-router';

import Layout from './Layout';
import Help from './routes/help/Help';

export default function PortalUnauthorized() {
  return (
    <Layout>
      <Switch>
        <Route path="/help" component={Help} />
        <Route
          render={() => (
            <section className="animated fadeIn">
              <h1>Not Authorized</h1>
              <br />
              <div className="row">
                <div className="col-sm-8">
                  <p>
                    We’re sorry, but you are not authorized to access the Digium Cloud Services
                    Cloud Customer Portal. Please ensure you are a Digium Cloud Services customer
                    and you have at least one permission in the Cloud Customer Portal. If you do not
                    have permissions, your company administrator can add permissions for you which
                    will allow you to access the portal. Please log out and retry your login after
                    permissions have been granted by your account administrator.
                  </p>
                  <br />
                  <p>
                    Administrators can review our Knowledge Base article for instructions on how to
                    add permissions and users:
                  </p>
                  <p>
                    <strong>
                      <a href="https://support.digium.com/community/s/article/How-Do-I-Add-Users-to-the-Cloud-Customer-Portal">
                        KB - Add Users to the Cloud Customer Portal
                      </a>
                    </strong>
                  </p>
                </div>
              </div>
            </section>
          )}
        ></Route>
      </Switch>
    </Layout>
  );
}
