export class UINotificationError extends Error {
  /**
   * Create a new UINotificationError instance.
   *
   * @param {string} message The message to tie to the error.
   * @constructor
   */
  constructor(message) {
    super(message);
    this.message = message;
    this.name = 'UINotificationError';
  }
}
