import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import set from 'lodash/set';
import Modal from '../../../../components/Modal/Modal';
import { fixUnroutableDid } from '../../../../actions/didsActions';
import { fetchOneProductInstance } from '../../../../actions/productActions';
import phone from '../../../../lib/phone';

const FixMisconfiguredSwitchNumberModal = createReactClass({
  propTypes: {
    username: PropTypes.string,
    misconfiguredNumberToFix: PropTypes.object,
    subscriptionNumber: PropTypes.string,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
  },
  getInitialState() {
    return {
      processing: false,
      processingText: '',
    };
  },
  onPropChange(propName) {
    return (event) => {
      const newState = { ...this.state };
      const newValue = event.target.value;
      set(newState, propName, newValue);
      this.setState(newState);
    };
  },
  fixPhoneNumberRouting() {
    const { dispatch, misconfiguredNumberToFix, username, subscriptionNumber } = this.props;

    const doneProcessing = () => {
      this.setState({
        processing: false,
        processingText: '',
      });
    };
    this.setState({ processing: true, processingText: '' });

    this.setState({ processingText: 'Fixing number' });
    dispatch(
      fixUnroutableDid({
        phoneNumber: misconfiguredNumberToFix.number,
        sipUsername: username,
        fixAction: 'make-routable',
      })
    )
      .then(() => {
        this.setState({ processingText: 'Refreshing data' });
        return dispatch(fetchOneProductInstance({ subscriptionNumber, checkSwitchState: true }));
      })
      .then(() => {
        doneProcessing();
        this.props.closeAction();
      })
      .catch(doneProcessing);
  },
  removeUnroutablePhoneNumber() {
    const { dispatch, misconfiguredNumberToFix, username, subscriptionNumber } = this.props;
    const conf = window.confirm(
      'Removing from the E911 table will drop this number from the switch altogether. Continue?'
    ); // eslint-disable-line no-alert
    if (!conf) {
      return;
    }
    const doneProcessing = () => {
      this.setState({
        processing: false,
        processingText: '',
      });
    };
    this.setState({ processing: true, processingText: '' });

    this.setState({ processingText: 'Removing number' });
    dispatch(
      fixUnroutableDid({
        phoneNumber: misconfiguredNumberToFix.number,
        sipUsername: username,
        fixAction: 'cleanup',
      })
    )
      .then(() => {
        this.setState({ processingText: 'Refreshing data' });
        return dispatch(fetchOneProductInstance({ subscriptionNumber, checkSwitchState: true }));
      })
      .then(() => {
        doneProcessing();
        this.props.closeAction();
      })
      .catch(doneProcessing);
  },
  render() {
    const { processing, processingText } = this.state;
    const { closeAction, misconfiguredNumberToFix } = this.props;

    const header = <h3>Switch Misconfiguration</h3>;

    const body = (
      <div className="form-horizontal">
        <div className="form-group">
          <p className="col-xs-12">
            The number is configured in the e911_numbers table, but not in the dbaliases inbound
            routing table.
          </p>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Unroutable Phone Number</label>
          <div className="col-xs-7">
            <p className="col-xs-12">{phone(misconfiguredNumberToFix.number)}</p>
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Cancel
        </button>
        <button
          className="btn btn-primary"
          disabled={processing}
          onClick={this.fixPhoneNumberRouting}
        >
          {processing ? processingText || 'Fixing...' : 'Make Routable'}
        </button>
        <button
          className="btn btn-primary"
          disabled={processing}
          onClick={this.removeUnroutablePhoneNumber}
        >
          {processing ? processingText || 'Fixing...' : 'Remove from E911 Table'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(FixMisconfiguredSwitchNumberModal);
