export default function translateFromKamailioProvider(provider) {
  switch (provider) {
    case 'Bandwidth':
      return 'BANDWIDTH';
    case 'Level3 ELS':
      return 'LEVEL3';
    case 'Paetec':
      return 'PAETEC';
    case 'Vitelity':
      return 'VITELITY';
    case 'Glbx':
      return 'GLBX';
    default:
      return undefined;
  }
}
