import get from 'lodash/get';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import * as actions from '../actions/productActions';

const initialState = [];

function overwriteEntireArray(objValue, srcValue) {
  if (isArray(srcValue)) {
    return srcValue;
  }
}

export default (state = initialState, action) => {
  let existing;
  let newState;

  switch (action.type) {
    case actions.CLEAR_PRODUCT_INSTANCES:
      return [];
    case actions.SUBSCRIPTION_ERROR:
      // this action will put the error onto the subscription
      existing = state.findIndex(
        (instance) => instance.subscriptionNumber === action.payload.subscriptionNumber
      );
      newState = [...state];
      if (existing !== -1) {
        newState[existing] = merge(newState[existing], { subscription: action.payload });
        newState[existing].subscription = newState[existing].subscription || {};
        newState[existing].subscription.error = action.payload.error;
      } else {
        newState.push({
          subscriptionNumber: action.payload.subscriptionNumber,
          subscription: { error: action.payload.error },
        });
      }
      return newState;
    case actions.SET_PRODUCT_INSTANCE: // singular
      // does it exist yet?
      existing = state.findIndex(
        (instance) => instance.subscriptionNumber === action.payload.subscriptionNumber
      );
      newState = [...state];
      if (existing !== -1) {
        // update properties at index, preferring new ones
        const existingSubscription = newState[existing].subscription;
        newState[existing] = mergeWith(newState[existing], action.payload, overwriteEntireArray);
        if (get(newState[existing], 'sipSwitch.channels')) {
          // when we change from a channelized to an unlimited trunk, the channel property is no
          // longer returned by the switch. Since the property is now undefined, it cannot replace
          // a numeric value in the existing state so we have to explicitly overwrite it here
          newState[existing].sipSwitch.channels = get(action.payload, 'sipSwitch.channels', null);
        }
        // due to the cost of running extra APIs we don't always update part of the subscription, make sure we do
        // not blow away those properties if they exist
        if (
          get(existingSubscription, 'contractTerms') &&
          !get(action.payload, 'subscription.contractTerms')
        ) {
          newState[existing].subscription.contractTerms = existingSubscription.contractTerms;
        }
        if (
          get(existingSubscription, 'entitlements') &&
          !get(action.payload, 'subscription.entitlements')
        ) {
          newState[existing].subscription.entitlements = existingSubscription.entitlements;
        }
      } else {
        newState.push(action.payload);
      }
      return newState;
    default:
      return state;
  }
};
