import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import Modal from '../../../../components/Modal/Modal';
import { getUserAccountsAndRoles } from '../../../../actions/adminUserSearchActions';
import constants from '../../../../lib/constants';

const AdminUserInfoPreview = createReactClass({
  propTypes: {
    user: PropTypes.object,
    dispatch: PropTypes.func,
    closeAction: PropTypes.func,
  },
  componentWillMount() {
    this.props
      .dispatch(getUserAccountsAndRoles(this.props.user.id))
      .then((accounts) => this.setState({ accounts }));
  },
  getInitialState() {
    return {
      user: this.props.user,
      accounts: [],
    };
  },
  render() {
    const { closeAction } = this.props;
    const { user, accounts } = this.state;
    const header = (
      <h3>
        {user.firstName} {user.lastName}
      </h3>
    );
    const body = (
      <div>
        <h3>User</h3>
        <div className="well">
          <p>
            <label>Biz Objects ID:</label> {user.id}
          </p>
          <p>
            <label>Email:</label> {user.email}
          </p>
          <p>
            <label>Salesforce Contact ID:</label>{' '}
            <a href={`${constants.SALESFORCE_URL}/${user.sfContactId}`} target="_blank">
              {user.sfContactId}
            </a>
          </p>
          <p>
            <label>Webadmin Username:</label> {user.username}
          </p>
        </div>

        <h3>Account Permissions</h3>
        {accounts.map((a) => (
          <div className="well" key={a.id}>
            <h4>
              {a.id !== '*' ? <Link to={`/accounts/${a.id}`}>{a.name || '(none)'}</Link> : '*'}
            </h4>
            <label>Biz Objects ID:</label> {a.id}
            <br />
            <label>Salesforce Account ID:</label>{' '}
            <a href={`${constants.SALESFORCE_URL}/${a.sfAccountId}`} target="_blank">
              {a.sfAccountId}
            </a>
            <br />
            <label>Zuora ID:</label>{' '}
            <a
              href={`${constants.ZUORA_URL}/apps/CustomerAccount.do?method=view&id=${a.zuoraAccountId}`}
              target="_blank"
            >
              {a.zuoraAccountId}
            </a>
            <br />
            <label>Roles:</label>
            <ul>
              {a.userRoles
                ? a.userRoles.map((r) => (
                    <li id={r.id} key={r.id}>
                      <strong>{r.roleName}</strong> (productId={r.productId}, id={r.id})
                    </li>
                  ))
                : null}
            </ul>
          </div>
        ))}
      </div>
    );

    const footer = (
      <div>
        <button className="btn btn-default" onClick={closeAction}>
          Done
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps({ rcf }) {
  return {
    rcf,
  };
}

export default withRouter(connect(mapStateToProps)(AdminUserInfoPreview));
