import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import DisplayCreditCard from './DisplayCreditCard';

const DisplayPaymentMethod = createReactClass({
  propTypes: {
    // 'type' value should correspond to a Zuora PaymentMethod type
    type: PropTypes.string,
    paymentMethod: PropTypes.object,
  },

  render() {
    const { type, paymentMethod } = this.props;
    let display = (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-xs-5 control-label">Payment Method</label>
          <div className="col-xs-7">{type}</div>
        </div>
      </div>
    );

    if (type === 'CreditCard') {
      display = <DisplayCreditCard card={paymentMethod} />;
    }

    return <div className="form-display-only">{display}</div>;
  },
});

export default DisplayPaymentMethod;
