import * as actions from '../actions/dealActions';

const initialState = {
  creationData: null,
  deals: {},
  preview: null,
  dealCreated: false,
};

export default function dealReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DEAL_CREATION_DATA:
      return { ...state, creationData: action.payload };
    case actions.SET_DEAL_MASTER_AGENTS:
      return { ...state, masterAgents: action.payload };
    case actions.SET_DEAL:
      return {
        ...state,
        deals: {
          ...state.deals,
          // deal may or may not have a quote, but has to have an opportunity
          [action.payload.opportunity.sfId]: action.payload,
        },
      };
    case actions.SET_DEAL_CREATED:
      return {
        ...state,
        dealCreated: action.payload,
      };
    default:
      return state;
  }
}
