import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import phone from '../../../../lib/phone';
import Modal from '../../../../components/Modal/Modal';

const E911AddressNumberChoice = createReactClass({
  getInitialState() {
    const { did } = this.props;
    if (!did.e911Address) {
      did.e911Address = {};
    }
    // null is not allowed on managed react components
    return {
      e911CallerName: did.e911CallerName || '',

      addressLine1: did.e911Address.addressLine1 || '',
      addressLine2: did.e911Address.addressLine2 || '',
      city: did.e911Address.city || '',
      stateCode: did.e911Address.stateCode || '',
      zip: did.e911Address.zip || '',
    };
  },
  setE911Address(updateProps) {
    const newAddress = { ...this.state };
    // due to the way the DMS keeps addressLine2 as null, but the way portal swagger needs
    // addressLine2 as an empty string and NOT `null`, we must not copy a null prop over
    // addressLine2 (or any prop, really).
    Object.keys(updateProps).forEach((prop) => {
      if (updateProps[prop] && typeof newAddress[prop] !== 'undefined') {
        newAddress[prop] = updateProps[prop];
      }
    });
    this.setState(newAddress);
  },
  onPropChange(propName) {
    return (event) => {
      this.setState({ [propName]: event.target.value });
    };
  },
  ok() {
    this.props.onChoice(this.state);
    this.props.closeAction();
  },
  render() {
    const { closeAction, dids, did } = this.props;

    const header = (
      <div>
        <h1>Enter E911 Info</h1>
        <h3>{phone(did.number)}</h3>
      </div>
    );
    const body = (
      <div>
        <div className="row form-horizontal">
          <div className="col-xs-5">
            <label>Existing options</label>
            {uniq(dids.filter((d) => d.e911CallerName).map((d) => d.e911CallerName)).map((cn) => (
              <button
                key={cn}
                className="btn btn-block btn-default"
                onClick={() => this.setState({ e911CallerName: cn })}
              >
                {cn}
              </button>
            ))}

            {uniqBy(
              dids.filter((d) => d.e911Address),
              'e911Address.addressLine1'
            ).map((d) => (
              <button
                key={d.e911Address.addressLine1}
                className="btn btn-block btn-default"
                onClick={() => this.setE911Address(d.e911Address)}
              >
                <span className="help-block">{d.e911Address.addressLine1}</span>
                <span className="help-block">{d.e911Address.addressLine2}</span>
                <span className="help-block">
                  {d.e911Address.city}, {d.e911Address.stateCode} {d.e911Address.zip}
                </span>
              </button>
            ))}
          </div>
          <div className="col-xs-7">
            <div className="row form-group">
              <label className="col-xs-5 control-label">Caller ID</label>
              <div className="col-xs-7">
                <input
                  className="form-control"
                  type="text"
                  onChange={this.onPropChange('e911CallerName')}
                  value={this.state.e911CallerName}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-xs-5 control-label">Address 1</label>
              <div className="col-xs-7">
                <input
                  className="form-control"
                  type="text"
                  onChange={this.onPropChange('addressLine1')}
                  value={this.state.addressLine1}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-xs-5 control-label">Address 2</label>
              <div className="col-xs-7">
                <input
                  className="form-control"
                  type="text"
                  onChange={this.onPropChange('addressLine2')}
                  value={this.state.addressLine2}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-xs-5 control-label">City</label>
              <div className="col-xs-7">
                <input
                  className="form-control"
                  type="text"
                  onChange={this.onPropChange('city')}
                  value={this.state.city}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-xs-5 control-label">State Code</label>
              <div className="col-xs-7">
                <input
                  className="form-control"
                  type="text"
                  maxLength="2"
                  onChange={this.onPropChange('stateCode')}
                  value={this.state.stateCode}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-xs-5 control-label">ZIP Code</label>
              <div className="col-xs-7">
                <input
                  className="form-control"
                  type="text"
                  onChange={this.onPropChange('zip')}
                  value={this.state.zip}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <button onClick={closeAction} className="btn btn-default">
          Cancel
        </button>
        <button onClick={this.ok} className="btn btn-primary">
          Continue
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

E911AddressNumberChoice.propTypes = {
  dispatch: PropTypes.func,
  onChoice: PropTypes.func,
  closeAction: PropTypes.func,
  dids: PropTypes.array,
  did: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    dids: state.dids,
  };
}

export default connect(mapStateToProps)(E911AddressNumberChoice);
