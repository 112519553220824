export default function productNameFormatter(product, full = false) {
  let name;
  if (!product) {
    return '';
  }

  const productId = product.productId || '';

  if (product.subscription && product.subscription.SubscriptionNickname__c) {
    name = product.subscription.SubscriptionNickname__c;
    if (full) {
      name = `${name} (#${product.subscriptionNumber})`;
    }
  } else {
    switch (productId) {
      case 'clickvox':
        name = 'Clickvox';
        break;
      case 'hosted-switchvox':
        name = 'Hosted Switchvox';
        break;
      case 'sip-trunking':
        name = 'SIP Trunk';
        break;
      default:
        name = productId.replace('-', '');
    }

    name = `${name} #${product.subscriptionNumber}`;
  }

  return name;
}
