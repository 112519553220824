import 'whatwg-fetch';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';
import { setAccount } from './accountsActions';
/*!
 * Synchronous actions
 */

export const SET_RESULTS = 'SET_RESULTS';

export function setResults(payload) {
  return {
    type: SET_RESULTS,
    payload,
  };
}

/*!
 * Async thunks
 */

export function accountSearch(searchBy, searchTerm, page) {
  return async (dispatch) => {
    // depending on how we search, we will use different routes
    const term = encodeURIComponent(searchTerm);
    let results;
    try {
      switch (searchBy) {
        case '':
          results = await fetch(`/api/v1/accounts?pageSize=10&page=${page}`, {
            method: 'GET',
            credentials: 'include',
          }).then(handleAPI);
          break;
        case 'name':
        case 'phoneNumber':
        case 'sfAccountId':
        case 'sipUsername':
        case 'zuoraAccountId':
          results = await fetch(`/api/v1/accounts?pageSize=10&page=${page}&${searchBy}=${term}`, {
            method: 'GET',
            credentials: 'include',
          }).then(handleAPI);
          break;
        case 'accountId':
          results = {
            pagination: {},
            items: [
              await fetch(`/api/v1/accounts/${term}`, {
                method: 'GET',
                credentials: 'include',
              }).then(handleAPI),
            ],
          };
          break;
        default:
          throw new Error('Invalid search');
      }
    } catch (err) {
      notifyError(dispatch)(err);
      return;
    }

    results.items.map((acct) => dispatch(setAccount(acct)));
    dispatch(setResults(results));
  };
}
