import React from 'react';
import { Route, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ViewAccount from './ViewAccount';
import { getLastSelectedAccountId } from '../../../../actions/selectedAccountActions';

const Accounts = createReactClass({
  render() {
    const { match, session, accounts } = this.props;

    if (!match.isExact) {
      return <Route path={'/accounts/:accountId'} component={ViewAccount} />;
    }

    const lastAccountId = getLastSelectedAccountId();
    const accountIds = session.accounts.filter((a) => a !== '*');
    // It is possible for accounts to have entries that aren't fully loaded
    // and will never fully load. Filter these out.
    const loadedAccountIds = Object.keys(accounts)
      .filter((key) => accounts[key].id)
      .map((key) => accounts[key].id);

    // Redirect the user to their last account OR if they only have access
    // to a single account
    if (accountIds.length === 1 || lastAccountId) {
      if (
        lastAccountId &&
        ((session.accounts || []).includes(lastAccountId) ||
          (session.permissions &&
            session.permissions['*'] &&
            session.permissions['*'].selectAnyAccount))
      ) {
        return <Redirect to={`/accounts/${lastAccountId}`}></Redirect>;
      }

      return <Redirect to={`/accounts/${accountIds[0]}`}></Redirect>;
    }

    let accountList = null;
    if (!accountIds.length || accountIds.length !== loadedAccountIds.length) {
      accountList = <p>Loading portal data...</p>;
    } else {
      accountList = (
        <div>
          <h2>Accounts</h2>
          <ul>
            {accountIds.map((accountId) => (
              <li key={accountId}>
                <Link to={`/accounts/${accountId}`}>{accounts[accountId].name}</Link>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return (
      <section className="animated fadeIn">
        <h1>Digium Cloud Services</h1>
        <br />
        {accountList}
        <Route path={'/accounts/:accountId'} component={ViewAccount} />
      </section>
    );
  },
});

Accounts.propTypes = {
  match: PropTypes.object,
  accounts: PropTypes.object,
  session: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    accounts: state.accounts,
    session: state.session,
    products: state.products,
  };
}

export default withRouter(connect(mapStateToProps)(Accounts));
