import tollFreePrefixes from './tollFreePrefixes';

export const DID_TOLL_FREE = 'Toll Free';
export const DID_LOCAL = 'Local';

/**
 * @returns {DIDEntitlements}
 */
export function calcUsedEntitlements(didNumbers = []) {
  const ent = new DIDEntitlements();
  didNumbers.forEach((did) => {
    if (intuitTollFree(did) === DID_TOLL_FREE) {
      ent.numTollFreeDIDs++;
    } else if (did.e911Enabled || did.e911_enabled) {
      // consider DMS or Kamailio properties
      ent.numE911DIDs++;
    } else {
      ent.numLocalDIDs++;
    }
  });
  return ent;
}

/**
 * @param {object} did
 * @return {string}
 */
export function intuitTollFree(did) {
  if (did.type) {
    return did.type;
  }
  // normalize the number to 10 digits just in case this is a GLBX number with a leading 1
  if (tollFreePrefixes.includes(did.number.substr(-10, 10).substring(0, 3))) {
    return DID_TOLL_FREE;
  }
  return DID_LOCAL;
}

class DIDEntitlements {
  constructor() {
    this.numLocalDIDs = 0;
    this.numTollFreeDIDs = 0;
    this.numE911DIDs = 0;
  }
}
