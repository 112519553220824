import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { fetchOrders } from '../../../../../actions/billingActions';
import { trackingLink, carrier } from '../../../../../lib/generateOrderShippingDetails';

// word can be spelled with 1 or 2 Ls, british typically prefer 2 Ls...
const ORDER_STATUS_CANCEL = 'cancelled';
const ORDER_TYPE_CANCEL = 'cancellation';
const ORDER_TYPE_AMEND = 'amendment';
const ORDER_TYPE_SUB = 'subscription';

function orderTypeText(orderType) {
  switch (orderType) {
    case ORDER_TYPE_AMEND:
      return 'Change to';
    case ORDER_TYPE_CANCEL:
      return 'Cancelled';
    case ORDER_TYPE_SUB:
      return 'New';
    default:
      return '';
  }
}

const OrdersRoute = createReactClass({
  componentDidMount() {
    if (this.props.selectedAccount.id) {
      this.props.dispatch(fetchOrders());
    }
  },
  prevPage() {
    // pagination may not exist yet
    const {
      orders: { pagination },
    } = this.props;
    if (pagination && typeof pagination.page !== 'undefined') {
      const prev = pagination.page - 1;
      this.props.dispatch(fetchOrders(Math.max(0, prev)));
    }
  },
  nextPage() {
    // pagination may not exist yet
    const {
      orders: { pagination },
    } = this.props;
    if (pagination && typeof pagination.page !== 'undefined') {
      const next = pagination.page + 1;
      this.props.dispatch(fetchOrders(next));
    }
  },
  render() {
    const {
      selectedAccount,
      orders: { items = [], pagination = {} },
    } = this.props;
    const showPrev = typeof pagination.page !== 'undefined' && pagination.page > 1;
    const showNext = pagination.pageCount > pagination.page;
    const paginationUI = (
      <p>
        {showPrev ? (
          <button className="btn btn-success" onClick={this.prevPage}>
            Prev
          </button>
        ) : null}
        &nbsp;
        {showPrev || showNext ? pagination.page : null}
        &nbsp;
        {showNext ? (
          <button className="btn btn-success" onClick={this.nextPage}>
            Next
          </button>
        ) : null}
      </p>
    );

    let body;
    if (items && items.length) {
      body = (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Date</th>
              <th>Service</th>
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {items.map((o) => {
              const showPDF = o.status !== ORDER_STATUS_CANCEL && o.type !== ORDER_TYPE_CANCEL;
              return (
                <tr key={o.orderNumber}>
                  <td>
                    {o.orderNumber}
                    &nbsp;&nbsp;
                    {showPDF ? (
                      <a
                        href={`/redir?pdf=/api/v1/accounts/${selectedAccount.id}/orders/${o.id}/order.pdf`}
                        target="_blank"
                      >
                        PDF &#8599;
                      </a>
                    ) : null}
                  </td>
                  <td>{new Date(o.created).toLocaleDateString()}</td>
                  <td>
                    {orderTypeText(o.type)} {o.subscriptionType}
                  </td>
                  <td>{o.status}</td>
                  <td>
                    {o.setupUrl ? (
                      <a
                        className="btn btn-xs btn-primary"
                        href={o.setupUrl}
                        target="_blank"
                        style={{ marginLeft: '8px' }}
                      >
                        Setup &#8599;
                      </a>
                    ) : null}
                    {o.shipTrackingNum && o.shipTrackingNum.length ? (
                      <div>
                        {carrier(o.shipTrackingNum, o.shipDesc)}
                        {o.shipTrackingNum.map((trackingNumber) => {
                          const shippingLink = trackingLink(trackingNumber);
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <p>
                              {trackingNumber}
                              &nbsp;&nbsp;
                              {shippingLink ? (
                                <a href={shippingLink} target="_blank">
                                  Track
                                </a>
                              ) : null}
                            </p>
                          );
                        })}
                      </div>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      body = <p>No orders were retrieved for this account.</p>;
    }
    return (
      <div>
        <h1>Orders</h1>
        <br />
        {paginationUI}
        {body}
      </div>
    );
  },
});

OrdersRoute.propTypes = {
  dispatch: PropTypes.func,
  selectedAccount: PropTypes.object,
  orders: PropTypes.object,
};

function mapStateToProps({ selectedAccount, billing }) {
  return {
    selectedAccount,
    orders: billing.orders,
  };
}

export default connect(mapStateToProps)(OrdersRoute);
