import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/Modal/Modal';
import phone from '../../../../lib/phone';

export default function DisplaySwitchNumberTrunkMismatchModal({
  username,
  misconfiguredNumberToDisplay,
  closeAction,
}) {
  const header = <h3>Switch Misconfiguration</h3>;

  const body = (
    <div className="form-horizontal">
      <div className="form-group">
        <p className="col-xs-12">
          The number is associated with a different trunk in the e911_numbers vs. dbaliases database
          tables. This must be manually resolved before import.
        </p>
      </div>
      <div className="form-group">
        <label className="col-xs-4">Number</label>
        <div className="col-xs-7">
          <p className="col-xs-12">{phone(misconfiguredNumberToDisplay.number)}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-xs-4">dbaliases trunk</label>
        <div className="col-xs-7">
          <p className="col-xs-12">{username}</p>
        </div>
      </div>
      <div className="form-group">
        <label className="col-xs-4">e911_numbers trunk</label>
        <div className="col-xs-7">
          <p className="col-xs-12">{misconfiguredNumberToDisplay.e911_misconfigured_trunk}</p>
        </div>
      </div>
    </div>
  );
  const footer = (
    <div>
      <button className="btn btn-default" onClick={closeAction}>
        Dismiss
      </button>
    </div>
  );
  return <Modal header={header} body={body} footer={footer} />;
}

DisplaySwitchNumberTrunkMismatchModal.propTypes = {
  username: PropTypes.string,
  misconfiguredNumberToDisplay: PropTypes.shape({
    number: PropTypes.string,
    e911_misconfigured_trunk: PropTypes.string,
  }),
};
