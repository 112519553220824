import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Icon from '../Icon/Icon';
import { ICONS } from '../../lib/constants';

/**
 * A label that can be toggled into an edit mode.
 *
 * Callbacks are used to inform parent components of changes in the text state.
 */
const EditLabel = createReactClass({
  propTypes: {
    // Text displayed while a label
    text: PropTypes.string,
    // Text displayed once in edit mode
    editText: PropTypes.string,
    // How the EditLabel should be styled. See EditLabel.styles
    editStyle: PropTypes.number,
    // Whether or not the editLabel can be toggled to Edit mode
    editable: PropTypes.bool,
    // Callback called when the text in the label has changed due to an edit
    onTextChanged: PropTypes.func,
    // Whether or not we should display a processing cursor
    processing: PropTypes.bool,
  },
  getInitialState() {
    const { editText } = this.props;

    return {
      editing: false,
      inputValue: editText || '',
    };
  },
  handleCancel() {
    this.setState({ editing: false });
  },
  handleAccept() {
    const { onTextChanged, editText } = this.props;
    const { inputValue } = this.state;

    this.setState({ editing: false });
    if (!onTextChanged || inputValue === editText) {
      return;
    }

    onTextChanged(inputValue);
  },
  onInputKeyDown(event) {
    if (event.keyCode === 13) {
      this.handleAccept();
    } else if (event.keyCode === 27) {
      this.handleCancel();
    }
  },
  onInputChange(event) {
    this.setState({ inputValue: event.target.value });
  },
  onStartEdit() {
    const { processing, editable } = this.props;
    const { editing } = this.state;

    if (processing || editing || !editable) {
      return;
    }

    this.setState({ editing: true });
  },
  render() {
    const { text, editStyle, editable, processing } = this.props;
    const { editing, inputValue } = this.state;

    let label;
    const color = processing ? 'gray' : 'orange';
    const style = processing ? { cursor: 'wait' } : {};
    const iconSize = editStyle === EditLabel.styles.SMALL ? 12 : 16;
    const editIcon = editable ? (
      <Icon onClick={this.onStartEdit} icon={ICONS.EDIT} color={color} size={iconSize}></Icon>
    ) : null;

    if (editing) {
      if (editStyle === EditLabel.styles.HEADING) {
        style.margin = '.67em 0';
        style.marginBottom = '33px';
        style.fontSize = '2em';
      }

      label = (
        <div className="form-group" style={style}>
          <input
            onKeyDown={this.onInputKeyDown}
            onChange={this.onInputChange}
            type="text"
            className="col-xs-10"
            value={inputValue}
          ></input>
          <Icon
            onClick={this.handleAccept}
            icon={ICONS.ACCEPT}
            color="green"
            size={iconSize}
          ></Icon>
          <Icon onClick={this.handleCancel} icon={ICONS.CANCEL} color="red" size={iconSize}></Icon>
        </div>
      );
    } else {
      if (!text) {
        return <div></div>;
      }

      if (editStyle === EditLabel.styles.SMALL) {
        label = (
          <div style={style}>
            <p style={{ display: 'inline-block' }}>{text}</p>
            {editIcon}
          </div>
        );
      } else if (editStyle === EditLabel.styles.HEADING) {
        label = (
          <div style={style}>
            <h1 style={{ display: 'inline-block' }}>{text}</h1>
            {editIcon}
          </div>
        );
      } else {
        label = <div style={style}></div>;
      }
    }

    return label;
  },
});

EditLabel.styles = {
  SMALL: 0,
  HEADING: 1,
};

EditLabel.defaultProps = {
  processing: false,
  editStyle: EditLabel.styles.SMALL,
};

export default EditLabel;
