import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Modal from '../Modal/Modal';

const ConfirmWithText = createReactClass({
  propTypes: {
    title: PropTypes.string,
    text: PropTypes.any, // should be text or JSX
    okAction: PropTypes.func,
    closeAction: PropTypes.func,
  },
  getInitialState() {
    return {
      didClick: false,
    };
  },
  clickOk() {
    this.setState({
      didClick: true,
    });
    Promise.resolve()
      .then(() => this.props.okAction())
      .then(() => this.props.closeAction())
      .catch((err) => {
        this.setState({ didClick: false }); // prevent being stuck here
        throw err;
      });
  },
  render() {
    const { closeAction, title, text } = this.props;
    const { didClick } = this.state; // object, not array
    const header = <h3>{title}</h3>;
    const body = <div>{text}</div>;

    const footer = (
      <div>
        <button className="btn btn-default" disabled={didClick} onClick={closeAction}>
          Cancel
        </button>
        <button className="btn btn-primary" disabled={didClick} onClick={this.clickOk}>
          {didClick ? 'Processing...' : 'OK'}
        </button>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

export default ConfirmWithText;
