import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import { Switch, Route, Link, withRouter } from 'react-router-dom';

import {
  fetchOneProductInstance,
  updateProductSubscription,
} from '../../../../actions/productActions';
import ProductNameFormatter from '../../../../lib/productNameFormatter';
import ProductDetails from '../../../../components/ProductDetails/ProductDetails';
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';
import EditLabel from '../../../../components/EditLabel/EditLabel';
import RCF from '../rcf/RCF';
import Reconciliation from '../reconciliation/Reconciliation';
import ManageProductNumbers from '../phone-numbers/ManageProductNumbers';

const rcfHelpText = `Remote Call Forwarding allows you to forward incoming calls to external
    numbers in cases where you are not able to receive calls due to internet outage, network
    issues, power outage, or PBX issues`;

const SwitchvoxRoute = createReactClass({
  displayName: 'SwitchvoxRoute',
  getInitialState() {
    return {
      processing: false,
    };
  },
  componentWillMount() {
    this.loadProduct(this.props.match.params.subscriptionNumber);
  },
  // handle clicking from one product to another without full component reload
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.subscriptionNumber !== this.props.match.params.subscriptionNumber) {
      this.loadProduct(nextProps.match.params.subscriptionNumber);
    }
  },
  loadProduct(subscriptionNumber) {
    this.props.dispatch(
      fetchOneProductInstance({
        subscriptionNumber,
        checkSubscriptionState: true,
      })
    );
  },
  setProcessing(processing) {
    this.setState({ processing });
  },
  render() {
    const { products, selectedAccount, match, accountPermissions } = this.props;
    const { processing } = this.state;
    const {
      params: { subscriptionNumber },
    } = match;
    const featureFlags =
      selectedAccount && selectedAccount.featureFlags ? selectedAccount.featureFlags : [];
    const hasFlag = featureFlags.includes('rcf');
    const product = products.find((pi) => pi.subscriptionNumber === subscriptionNumber);
    let body;
    let rcfLink = null;
    let internalOnly = null;
    const internalOnlyLinks = [];
    const routes = [];

    if (subscriptionNumber && product) {
      if (accountPermissions.manageGlobalRCF) {
        // this admin may always see RCF link; customer may not always see it.
        // but we do not want to show the link twice, so check if the customer would
        // be able to see it

        if (!hasFlag) {
          routes.push(
            <Route
              key={`${subscriptionNumber}-rcf`}
              path={`${match.url}/rcf`}
              render={() => <RCF subscriptionNumber={subscriptionNumber} />}
            />
          );
          internalOnlyLinks.push(
            <p key="employee-manage-rcf-link">
              <Link className="btn btn-info" to={`${match.url}/rcf`}>
                Manage
              </Link>
              &nbsp; Remote Call Forwarding (RCF)
            </p>
          );
        }
        if (accountPermissions.employeeReconcile) {
          routes.push(
            <Route
              key={`${subscriptionNumber}-admin-trunk-management`}
              path={`${match.url}/admin/trunk-management`}
              render={() => <Reconciliation subscriptionNumber={subscriptionNumber} />}
            />
          );

          internalOnlyLinks.push(
            <p key="employee-did-reconcile-link">
              <Link className="btn btn-info" to={`${match.url}/admin/trunk-management`}>
                Manage
              </Link>
              &nbsp; DID Reconciliation
            </p>
          );
        }

        if (internalOnlyLinks.length) {
          internalOnly = (
            <div className="well internal-only">
              <h4>Internal Only</h4>
              {internalOnlyLinks}
            </div>
          );
        }
      }

      routes.push(
        <Route
          key={`${subscriptionNumber}-phone-numbers`}
          path={`${match.url}/phone-numbers`}
          render={() => <ManageProductNumbers subscriptionNumber={subscriptionNumber} />}
        />
      );
      // the first check hasFlag is so a digium employee does not see two links
      // when the employee has manageGlobalRCF
      if (hasFlag && accountPermissions.manageRCF) {
        routes.push(
          <Route
            key={`${subscriptionNumber}-rcf`}
            path={`${match.url}/rcf`}
            render={() => <RCF subscriptionNumber={subscriptionNumber} />}
          />
        );
        rcfLink = (
          <p>
            <Link className="btn btn-primary" to={`${match.url}/rcf`}>
              Manage
            </Link>
            &nbsp; Remote Call Forwarding (RCF) &nbsp;
            <HelpIcon text={rcfHelpText} />
          </p>
        );
      }

      // the first check hasFlag is so a digium employee does not see two links
      // when the employee has manageGlobalRCF
      if (hasFlag && accountPermissions.manageRCF) {
        routes.push(
          <Route
            key={`${subscriptionNumber}-rcf`}
            path={`${match.url}/rcf`}
            render={() => <RCF subscriptionNumber={subscriptionNumber} />}
          />
        );
        rcfLink = (
          <p>
            <Link className="btn btn-primary" to={`${match.url}/rcf`}>
              Manage
            </Link>
            &nbsp; Remote Call Forwarding (RCF) &nbsp;
            <HelpIcon text={rcfHelpText} />
          </p>
        );
      }

      const disabledTrunk = product.trunk && product.trunk.enabled === false;
      const disabledTrunkAlert = disabledTrunk ? (
        <p className="alert alert-danger" role="alert">
          TRUNK DISABLED - Please{' '}
          <a href="https://support.digium.com/community/s/contactsupport">
            contact Technical Support
          </a>{' '}
          for assistance.
        </p>
      ) : null;

      body = (
        <div className="row">
          {disabledTrunkAlert}
          <div className="col-md-6">
            <ProductDetails title="" product={product} sub={product.subscription} />
          </div>
          <div className="col-md-6">
            <p>
              <Link className="btn btn-primary" to={`${match.url}/phone-numbers`}>
                Manage
              </Link>
              &nbsp; Phone Numbers &nbsp;
              <HelpIcon text="View a list of the phone numbers on your account." />
            </p>
            {rcfLink}
            {internalOnly}
          </div>
        </div>
      );
    } else {
      body = (
        <div>
          <p>This Hosted Switchvox instance has no data yet. Please check back later.</p>
          <br />
          {internalOnly}
        </div>
      );
    }

    if (!match.isExact) {
      return (
        <section className="animated fadeIn">
          <Switch>{routes}</Switch>
        </section>
      );
    }

    const productName = ProductNameFormatter(product, true);
    return (
      <section className="animated fadeIn">
        <EditLabel
          text={productName}
          editable={accountPermissions.manageProducts}
          editText={
            product && product.subscription ? product.subscription.SubscriptionNickname__c : ''
          }
          processing={processing}
          onTextChanged={(text) => {
            this.setProcessing(true);
            this.props
              .dispatch(
                updateProductSubscription({
                  subscriptionNumber: product.subscriptionNumber,
                  updateBody: { SubscriptionNickname__c: text },
                })
              )
              .then(() => this.setProcessing(false))
              .catch(() => this.setProcessing(false));
          }}
          editStyle={EditLabel.styles.HEADING}
        ></EditLabel>
        <br />
        {body}
        <Switch>{routes}</Switch>
      </section>
    );
  },
});

SwitchvoxRoute.propTypes = {
  products: PropTypes.array,
  selectedAccount: PropTypes.object,
  accountPermissions: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object, // react router
};

function mapStateToProps(state) {
  return {
    products: state.products,
    selectedAccount: state.selectedAccount,
    accountPermissions: state.accountPermissions,
  };
}

export default withRouter(connect(mapStateToProps)(SwitchvoxRoute));
