import * as actions from '../actions/accountsActions';
import * as selectedActions from '../actions/selectedAccountActions';

// initialState is an object where the keys are the account.id
const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case selectedActions.SET_SELECTED_ACCOUNT:
    case actions.SET_ACCOUNT: {
      const accountsMap = { ...state };
      // merge or set account by ID
      if (accountsMap[action.payload.id]) {
        accountsMap[action.payload.id] = {
          ...accountsMap[action.payload.id],
          ...action.payload,
        };
      } else {
        accountsMap[action.payload.id] = action.payload;
      }
      return accountsMap;
    }
    default:
      return state;
  }
};
