import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from '../../../../actions/sessionActions';
import { userSearch } from '../../../../actions/adminUserSearchActions';
import { clearNotifs } from '../../../../actions/notifActions';
import AdminUserInfoPreview from './AdminUserInfoPreview';
import constants from '../../../../lib/constants';

const UserSearch = createReactClass({
  getInitialState() {
    return {
      modal: null,

      searchBy: 'email',
      searchTerm: '',
    };
  },
  onTextChange(event) {
    this.setState({
      searchTerm: event.target.value,
      page: 1,
    });
  },
  setSearchBy(searchBy) {
    this.setState({ searchBy });
    // react needs one round to turn off the disabled attribute before we can focus it
    setTimeout(() => this.searchTermInput.focus(), 50);
  },
  previewResult(user) {
    this.setState({
      modal: (
        <AdminUserInfoPreview user={user} closeAction={() => this.setState({ modal: null })} />
      ),
    });
  },
  handleSearchEnter(e) {
    if (e.key === 'Enter') {
      this.search();
    }
  },
  search() {
    const { searchBy, searchTerm, page } = this.state;
    if (!searchTerm) {
      return;
    }
    this.props.dispatch(clearNotifs());
    this.props.dispatch(userSearch(searchBy, searchTerm, page));
  },
  logout() {
    this.props.dispatch(logout());
  },
  render() {
    const {
      /**
       * @type {AccountPermissions}
       */
      accountPermissions,
      results: { items },
    } = this.props;
    const { searchBy, searchTerm, modal } = this.state;
    if (!accountPermissions.selectAnyAccount) {
      return <p>No permission to search for users.</p>;
    }

    return (
      <div className="row">
        {modal}
        <div className="col-xs-3">
          <h4>Users</h4>
          <p>
            <button
              className="btn btn-success btn-block"
              disabled={!searchTerm}
              onClick={this.search}
            >
              Find User
            </button>
          </p>
          <h5>Search Filters</h5>
          <p className="animated fadeIn">
            <input
              className="form-control input-sm"
              placeholder={!searchBy ? 'Select a search filter' : ''}
              disabled={!searchBy}
              onKeyUp={this.handleSearchEnter}
              ref={(input) => {
                this.searchTermInput = input;
              }}
              onChange={this.onTextChange}
              value={searchTerm}
            />
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'email' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('email')}
            >
              Email
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'sfContactId' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('sfContactId')}
            >
              Salesforce Contact ID
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'userId' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('userId')}
            >
              Biz Objects ID
            </button>
          </p>
          <p>
            <button
              className={`btn btn-sm btn-block ${
                searchBy === 'username' ? 'btn-primary' : 'btn-default'
              }`}
              onClick={() => this.setSearchBy('username')}
            >
              Username
            </button>
          </p>
        </div>
        <div className="col-xs-9">
          <table className="table table-condensed table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Salesforce Contact ID</th>
                <th>Biz Objects ID</th>
              </tr>
            </thead>
            <tbody>
              {!items || !items.length ? (
                <tr>
                  <td colSpan="5">Pick a filter then search for a user</td>
                </tr>
              ) : null}
              {items &&
                items.map((u) => (
                  <tr key={u.id}>
                    <td>
                      <a onClick={() => this.previewResult(u)}>
                        {u.firstName} {u.lastName}
                        {!u.firstName && !u.lastName ? '(none)' : ''}
                      </a>
                    </td>
                    <td>{u.email}</td>
                    <td>
                      <a href={`${constants.SALESFORCE_URL}/${u.sfContactId}`} target="_blank">
                        {u.sfContactId}
                      </a>
                    </td>
                    <td>{u.id}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
});

UserSearch.propTypes = {
  dispatch: PropTypes.func,
  accountPermissions: PropTypes.object,
  results: PropTypes.object,
  accounts: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    accountPermissions: state.accountPermissions,
    results: state.adminUserSearch,
  };
}

export default withRouter(connect(mapStateToProps)(UserSearch));
