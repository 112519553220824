import React from 'react';
import PropTypes from 'prop-types';

require('./toggleSwitch.less');

function ToggleSwitch({ onChange, checked, disabled }) {
  if (disabled) {
    if (checked) {
      return (
        <label className="toggle-switch">
          <input
            type="checkbox"
            disabled={disabled}
            defaultChecked={checked}
            className="ios-switch disabled"
          />
          <div>
            <div></div>
          </div>
        </label>
      );
    }
    return (
      <label className="toggle-switch">
        <input
          type="checkbox"
          disabled={disabled}
          defaultChecked={checked}
          className="ios-switch disabled"
        />
        <div>
          <div></div>
        </div>
      </label>
    );
  }
  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        disabled={disabled}
        defaultChecked={checked}
        className="ios-switch"
        onChange={onChange}
      />
      <div>
        <div></div>
      </div>
    </label>
  );
}

ToggleSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ToggleSwitch;
