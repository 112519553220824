import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const DisplayCreditCard = createReactClass({
  propTypes: {
    card: PropTypes.object,
  },
  render() {
    const {
      // MAY OR MAY NOT BE PREFIXED, depending on the zuora route.
      creditCardType,
      creditCardNumber,
      cardType,
      cardNumber,
      expirationMonth,
      expirationYear,
      cardHolderInfo,
    } = this.props.card;
    return (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-xs-5 control-label">Credit Card</label>
          <div className="col-xs-7">
            {creditCardType || cardType}
            &nbsp;
            {creditCardNumber || cardNumber}
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-5 control-label">Expiration</label>
          <div className="col-xs-7">
            {expirationMonth}/{expirationYear}
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-5 control-label">Card Holder Name</label>
          <div className="col-xs-7">{cardHolderInfo.cardHolderName}</div>
        </div>
        <div className="form-group" style={cardHolderInfo.addressLine1 ? {} : { display: 'none' }}>
          <label className="col-xs-5 control-label">Street 1</label>
          <div className="col-xs-7">{cardHolderInfo.addressLine1}</div>
        </div>
        <div className="form-group" style={cardHolderInfo.addressLine2 ? {} : { display: 'none' }}>
          <label className="col-xs-5 control-label">Street 2</label>
          <div className="col-xs-7">{cardHolderInfo.addressLine2}</div>
        </div>
        <div className="form-group" style={cardHolderInfo.city ? {} : { display: 'none' }}>
          <label className="col-xs-5 control-label">City</label>
          <div className="col-xs-7">{cardHolderInfo.city}</div>
        </div>
        <div className="form-group" style={cardHolderInfo.state ? {} : { display: 'none' }}>
          <label className="col-xs-5 control-label">State</label>
          <div className="col-xs-7">{cardHolderInfo.state}</div>
        </div>
        <div className="form-group" style={cardHolderInfo.zipCode ? {} : { display: 'none' }}>
          <label className="col-xs-5 control-label">Postal Code</label>
          <div className="col-xs-7">{cardHolderInfo.zipCode}</div>
        </div>
        <div className="form-group" style={cardHolderInfo.country ? {} : { display: 'none' }}>
          <label className="col-xs-5 control-label">Country</label>
          <div className="col-xs-7">{cardHolderInfo.country}</div>
        </div>
      </div>
    );
  },
});

export default DisplayCreditCard;
