// initialize the app and render it
import { render } from '../sharedAppRender';
import PortalApp from './PortalApp';

render(PortalApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./PortalApp.js', () => {
    const ReimportedApp = require(`./PortalApp.js`).default;
    render(ReimportedApp);
  });
}
