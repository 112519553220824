import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import EditContact from '../../../../../components/EditContact/EditContact';
import DisplayContact from '../../../../../components/DisplayContact/DisplayContact';
import { updateCompanyContact } from '../../../../../actions/billingActions';

const CompanyContactRoute = createReactClass({
  getInitialState() {
    return {
      editModal: null,
    };
  },
  killModal() {
    this.setState({
      editModal: null,
    });
  },
  showEditModal() {
    const { dispatch } = this.props;
    const save = (contact) => dispatch(updateCompanyContact(contact));

    this.setState({
      editModal: (
        <EditContact
          closeAction={this.killModal}
          title="Edit Company Contact"
          save={save}
          contact={this.props.soldToContact}
        />
      ),
    });
  },
  render() {
    const { soldToContact, accountPermissions } = this.props;
    const { editModal } = this.state;
    let body;
    let editBtn;

    // ensure user has permission to view company contact
    if (accountPermissions.viewAccount) {
      if (soldToContact) {
        body = <DisplayContact contact={soldToContact} />;
        editBtn = accountPermissions.manageAccount ? (
          <button className="btn btn-primary" onClick={this.showEditModal}>
            Edit
          </button>
        ) : null;
      } else {
        body = <p>Loading...</p>;
      }
    } else {
      body = <span className="alert alert-danger">You do not have access to this page</span>;
    }

    return (
      <div>
        {editModal}
        <h1>
          Company Contact &nbsp;
          {editBtn}
        </h1>
        <br />
        <div className="row">
          <div className="col-md-6">
            <div className="panel">
              <div className="panel-body">{body}</div>
            </div>
          </div>
        </div>
      </div>
    );
  },
});

CompanyContactRoute.propTypes = {
  dispatch: PropTypes.func,
  soldToContact: PropTypes.object,
  uiPermission: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    soldToContact: state.selectedAccount.zuoraAccount
      ? state.selectedAccount.zuoraAccount.soldToContact
      : null,
    accountPermissions: state.accountPermissions,
  };
}

export default connect(mapStateToProps)(CompanyContactRoute);
