import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createReactClass from 'create-react-class';
import Modal from '../../../../components/Modal/Modal';
import phone from '../../../../lib/phone';
import possible11DigitsTo10 from '../../../../lib/possible11DigitsTo10';
import { removeDIDsFromTrunk } from '../../../../actions/didsActions';

const DeleteNumbersModal = createReactClass({
  propTypes: {
    numbers: PropTypes.array,
    dispatch: PropTypes.func,
    product: PropTypes.object,
    cancelAction: PropTypes.func,
    continueAction: PropTypes.func,
  },
  getInitialState() {
    return {
      reason: 'Trunk reconciliation',
      showError: false,
    };
  },
  deleteNumbers() {
    const {
      product: { trunk, sipSwitch },
      dispatch,
      numbers,
      continueAction,
    } = this.props;
    const { reason } = this.state;
    if (!reason) {
      this.setState({ showError: true });
      return;
    }
    let foundOnSwitch;
    const onSwitch = [];
    const offSwitch = [];
    numbers.forEach((number) => {
      foundOnSwitch = sipSwitch.numbers.find((n) => possible11DigitsTo10(n.number) === number); // eslint-disable-line no-loop-func
      if (foundOnSwitch) {
        onSwitch.push(number);
        return;
      }
      offSwitch.push(number);
    });
    Promise.all([
      dispatch(
        removeDIDsFromTrunk({
          numbers: onSwitch,
          trunkId: trunk.id,
          middleOut: true,
          reason,
        })
      ),
      dispatch(
        removeDIDsFromTrunk({
          numbers: offSwitch,
          trunkId: trunk.id,
          middleOut: false,
          reason,
        })
      ),
    ])
      .then(continueAction)
      .catch(continueAction);
  },
  onPropChange(propName) {
    return (event) => {
      const newState = {
        [propName]: event.target.value,
      };
      this.setState(newState);
    };
  },
  render() {
    const { reason, showError } = this.state;
    const { cancelAction, numbers } = this.props;
    const header = <h3>Delete Numbers</h3>;
    const body = (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-xs-4">Phone Numbers</label>
          <div className="col-xs-7">
            {numbers.map((n) => (
              <p className="col-xs-12" key={`import-${n}`}>
                {phone(n)}
              </p>
            ))}
          </div>
        </div>
        <div className="form-group">
          <label className="col-xs-4">Reason for removal</label>
          <div className={`col-xs-8 ${showError ? 'has-error' : ''}`}>
            <input
              className="form-control"
              value={reason}
              type="text"
              required={true}
              onChange={this.onPropChange('reason')}
            />
            <span className={`help-block animated fadeIn ${showError ? '' : 'hidden'}`}>
              This is a required field
            </span>
          </div>
        </div>
      </div>
    );
    const footer = (
      <div>
        <div className="form-text">
          <div className="col-xs-12">
            <label>
              Put {numbers.length} numbers back in inventory now and remove from the switch? <br />
              <span className="text-danger">THIS IS IRREVERSIBLE!</span>
            </label>
          </div>
        </div>
        <div>
          <button className="btn btn-default" onClick={cancelAction}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={this.deleteNumbers}>
            Remove
          </button>
        </div>
      </div>
    );

    return <Modal header={header} body={body} footer={footer} />;
  },
});

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(DeleteNumbersModal);
