module.exports = {
  byNumber(a, b) {
    if (a.number > b.number) {
      return 1;
    }
    if (a.number < b.number) {
      return -1;
    }
    return 0;
  },
  byInvoiceDate(a, b) {
    if (a.invoiceDate < b.invoiceDate) {
      return 1;
    }
    if (a.invoiceDate > b.invoiceDate) {
      return -1;
    }
    return 0;
  },
  /**
   * Sort an array of managed numbers.
   *
   * Note: we pass in the sortBy function as a way around the fact that we can't use
   * ES modules within Node still without a flag. Starting with Node 13 we'll be able to make
   * this an ES module to allow us to import lodash/sortBy in both Node and the browser.
   *
   * @param {Array.<DID>} unsortedNumbers
   * @param {String} sortField
   * @param {Boolean} sortAscending
   * @param {Function} sortBy Function to sort by field(s)
   * @returns {Array.<DID>}
   */
  byManagedNumbers(unsortedNumbers, sortField, sortAscending, sortBy) {
    let numbers = [...unsortedNumbers];
    switch (sortField) {
      case 'e911_default':
        numbers = sortBy(numbers, 'e911Default');
        break;
      case 'e911_enabled':
        numbers = sortBy(numbers, 'e911Enabled');
        break;
      default:
        numbers = sortBy(numbers, sortField);
        break;
    }
    if (!sortAscending) {
      numbers.reverse();
    }
    return numbers;
  },
};
