import 'whatwg-fetch';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';
import { UINotificationError } from '../lib/errors';
import history from '../history';

/*!
 * Synchronous actions
 */

export const SET_QUOTE_CREATION_DATA = 'SET_QUOTE_CREATION_DATA';
export const SET_QUOTE_ERROR = 'SET_QUOTE_ERROR';
export const SET_QUOTE = 'SET_QUOTE';
export const SET_QUOTE_PREVIEW = 'SET_QUOTE_PREVIEW';

export function setQuoteCreationData(payload) {
  return {
    type: SET_QUOTE_CREATION_DATA,
    payload,
  };
}

export function setQuoteCreationError(payload) {
  return {
    type: SET_QUOTE_ERROR,
    payload,
  };
}

export function setQuote(payload) {
  return {
    type: SET_QUOTE,
    payload,
  };
}

export function setQuotePreview(payload) {
  return {
    type: SET_QUOTE_PREVIEW,
    payload,
  };
}

/*!
 * Async thunks
 */
export function fetchSubscriptionQuoteCreationData(subscriptionNumber) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/subscriptions/${subscriptionNumber}/quote-creation-data`;
      const response = await fetch(url, { credentials: 'include' });
      const quoteCreationData = await handleAPI(response);
      dispatch(setQuoteCreationData(quoteCreationData));
    } catch (err) {
      switch (err.statusCode) {
        case 400:
          dispatch(
            setQuoteCreationError({
              heading: 'Subscription unable to be quoted',
              message: err.message,
            })
          );
          break;
        case 404:
          dispatch(
            setQuoteCreationError({
              heading: 'Subscription not found',
              message: 'Check your subscription number and try again',
            })
          );
          break;
        default:
          notifyError(dispatch)(err);
          break;
      }
    }
  };
}

export function fetchQuoteEditCreationData(quoteId) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/quotes/${quoteId}/quote-creation-data`;
      const response = await fetch(url, { credentials: 'include' });
      const quoteCreationData = await handleAPI(response);
      dispatch(setQuoteCreationData(quoteCreationData));
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

export function fetchNewServiceCreationData(opportunityId) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/opportunities/${opportunityId}/quote-creation-data`;
      const response = await fetch(url, { credentials: 'include' });
      const quoteCreationData = await handleAPI(response);
      dispatch(setQuoteCreationData(quoteCreationData));
    } catch (err) {
      switch (err.statusCode) {
        case 400:
          dispatch(
            setQuoteCreationError({
              heading: 'Opportunity invalid',
              message: err.message,
            })
          );
          break;
        case 404:
          dispatch(
            setQuoteCreationError({
              heading: 'Opportunity not found',
              message: 'Check your opportunity id and try again',
            })
          );
          break;
        default:
          notifyError(dispatch)(err);
          break;
      }
    }
  };
}

export function createQuote(quote) {
  return async (dispatch) => {
    try {
      const url = '/api/v1/quotes';
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(quote),
      });
      const createdQuote = await handleAPI(response);
      dispatch(setQuote(createdQuote));
      history.push(`/quotes/view/${createdQuote.id}`);
    } catch (err) {
      notifyError(dispatch)(new UINotificationError(err.message));
      throw err;
    }
  };
}

export function getQuote(quoteId) {
  return async (dispatch) => {
    try {
      const url = `/api/v1/quotes/${quoteId}`;
      const response = await fetch(url, { credentials: 'include' });
      const quote = await handleAPI(response);
      dispatch(setQuote(quote));
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}
