import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

require('./selectAccount.less');

/**
 * SelectAccount implements UI to change accounts to a different one the user has
 * permission to access. This is not admin stuff (see AccountSearch).
 *
 * It will be blank / hidden if there are no other accounts.
 */
const SelectAccount = createReactClass({
  getInitialState() {
    return { chooserVisible: false };
  },
  render() {
    const { chooserVisible } = this.state;
    const {
      location: { pathname },
      selectedAccount,
      accounts,
      session: { accounts: accountIds = [] },
    } = this.props;

    const className = pathname === '/account' ? 'active' : '';
    let chooserButton = null;
    let selectOthers = null;
    let selectedAccountName = 'Select an account';
    if (selectedAccount.name) {
      selectedAccountName = selectedAccount.name;
    } else if (selectedAccount.zuoraAccount && selectedAccount.zuoraAccount.name) {
      selectedAccountName = selectedAccount.zuoraAccount.name;
    } else if (accounts[selectedAccount.id] && accounts[selectedAccount.id].name) {
      selectedAccountName = accounts[selectedAccount.id].name;
    } else if (selectedAccount.id) {
      selectedAccountName = selectedAccount.id;
    }

    // should it show the option to change accounts?
    if (accountIds.length > 1) {
      chooserButton = (
        <span
          className="btn btn-account-selection clickable"
          onClick={() => this.setState({ chooserVisible: !chooserVisible })}
        >
          <span className="caret" />
        </span>
      );
      selectOthers = (
        <div className={`account-selection-dropdown ${chooserVisible ? '' : 'no-select'}`}>
          <div
            style={{
              padding: '8px 0 0 33px',
              fontWeight: 'bold',
            }}
          >
            Switch Accounts
          </div>
          {accountIds
            .filter((id) => id !== selectedAccount.id)
            .map((accountId) => {
              if (accountId === '*') {
                return null;
              }
              const account = accounts[accountId];
              if (!account) {
                // still loading
                return null;
              }
              return (
                <Link to={`/accounts/${accountId}`} key={accountId}>
                  <span className="clickable btn btn-primary">{account.name}</span>
                </Link>
              );
            })}
        </div>
      );
    }

    return (
      <li className={className}>
        <Link to={`/accounts/${selectedAccount.id}`}>
          <span
            style={{
              overflow: 'hidden',
              maxWidth: '158px',
              textOverflow: 'ellipsis',
              display: 'block',
              whiteSpace: 'nowrap',
            }}
          >
            {selectedAccountName}
          </span>
        </Link>
        {chooserButton}
        {selectOthers}
      </li>
    );
  },
});

SelectAccount.propTypes = {
  selectedAccount: PropTypes.object,
  accounts: PropTypes.object,
  session: PropTypes.object,
  location: PropTypes.object,
};

function mapStateToProps({ selectedAccount, accounts, session }) {
  return { selectedAccount, accounts, session };
}

export default withRouter(connect(mapStateToProps)(SelectAccount));
