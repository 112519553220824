import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import availableFeatureFlags from '../../../../../featureFlags';
import { updateSelectedAccount } from '../../../../actions/selectedAccountActions';

const AdminEditAccount = createReactClass({
  getInitialState() {
    return {
      featureFlags: this.props.selectedAccount.featureFlags || [],
      zuoraAccountId: this.props.selectedAccount.zuoraAccountId,
      showEditing: false,
      processing: false,
    };
  },
  onTextChange(event) {
    this.setState({
      zuoraAccountId: event.target.value,
    });
  },
  addFeatureFlag(flag) {
    const newFlags = [...this.state.featureFlags];
    newFlags.push(flag);
    newFlags.sort();
    this.setState({ featureFlags: newFlags });
  },
  removeFeatureFlag(flag) {
    const ix = this.state.featureFlags.indexOf(flag);
    const newFlags = [...this.state.featureFlags];
    newFlags.splice(ix, 1);
    this.setState({ featureFlags: newFlags });
  },
  save() {
    this.setState({ processing: true });
    this.props
      .dispatch(updateSelectedAccount(this.state))
      .then(() => this.setState({ processing: false, showEditing: false }))
      .catch(() => this.setState({ processing: false }));
  },
  toggleShowEditAccount() {
    this.setState({
      showEditing: !this.state.showEditing,
      // reset props
      zuoraAccountId: this.props.selectedAccount.zuoraAccountId,
      featureFlags: this.props.selectedAccount.featureFlags,
    });
  },
  render() {
    const { selectedAccount } = this.props;
    const { featureFlags = [], zuoraAccountId, showEditing, processing } = this.state;
    const titleButton = (
      <a
        className={showEditing ? 'btn btn-default' : 'btn btn-info'}
        onClick={this.toggleShowEditAccount}
      >
        {showEditing ? 'Cancel Editing' : 'Edit Account'}
      </a>
    );

    if (!showEditing) {
      return (
        <div className="row">
          <div className="col-sm-6">{titleButton}</div>
        </div>
      );
    }

    const flagsNotHad = availableFeatureFlags.filter((flag) => !featureFlags.includes(flag));

    return (
      <div className="well">
        <h3>Edit {selectedAccount.name} Details</h3>
        <br />
        <div className="form-horizontal">
          <div className="form-group">
            <div className="col-sm-6">
              {flagsNotHad.length ? <label>Available Feature Flags</label> : null}
              {!flagsNotHad.length ? (
                <p>
                  <em>Account has all flags.</em>
                </p>
              ) : null}
              {flagsNotHad.sort().map((flag) => (
                <p key={`add-flag-${flag}`} className="animated fadeIn">
                  <button
                    onClick={() => this.addFeatureFlag(flag)}
                    className="btn btn-xs btn-default"
                  >
                    {flag} &rarr;
                  </button>
                </p>
              ))}
            </div>
            <div className="col-sm-6">
              <label>Current Feature Flags</label>
              {!featureFlags.length ? (
                <p>
                  <em>No feature flags yet.</em>
                </p>
              ) : null}
              {featureFlags.sort().map((flag) => (
                <p key={flag} className="animated fadeIn">
                  <button
                    onClick={() => this.removeFeatureFlag(flag)}
                    className="btn btn-xs btn-primary bold"
                  >
                    &larr; {flag}
                  </button>
                </p>
              ))}
            </div>
          </div>
          <br />
          <div className="form-group">
            <div className="col-sm-6">
              <label>Zuora Account ID</label>
              <span className="help-block">Break this, and everything will get screwed up.</span>
              <span className="help-block">
                Name, account number, and salesforce ID are cached from Zuora.
              </span>
            </div>
            <div className="col-sm-6">
              <span className="help-block">Original: {selectedAccount.zuoraAccountId}</span>
              <input
                className="form-control"
                type="text"
                value={zuoraAccountId}
                onChange={this.onTextChange}
              />
            </div>
          </div>
        </div>
        <hr />
        <p className="align-right">
          {titleButton}
          &nbsp;
          <button onClick={this.save} className="btn btn-primary" disabled={processing}>
            {processing ? 'Saving...' : 'Save'}
          </button>
        </p>
      </div>
    );
  },
});

AdminEditAccount.propTypes = {
  selectedAccount: PropTypes.object,
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    selectedAccount: state.selectedAccount,
  };
}

export default withRouter(connect(mapStateToProps)(AdminEditAccount));
