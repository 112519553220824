import 'whatwg-fetch';
import notifyError from '../lib/notifyError';
import handleAPI from '../lib/handleInternalAPI';

/*!
 * Synchronous actions
 */

export const SET_PRODUCT_INSTANCE = 'SET_PRODUCT_INSTANCE';

export function setProductInstance(payload) {
  return {
    type: SET_PRODUCT_INSTANCE,
    payload,
  };
}

export const CLEAR_PRODUCT_INSTANCES = 'CLEAR_PRODUCT_INSTANCES';

export function clearProductInstances() {
  return {
    type: CLEAR_PRODUCT_INSTANCES,
  };
}

export const SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR';
export function subscriptionError(subscriptionNumber, error) {
  return {
    type: SUBSCRIPTION_ERROR,
    payload: { subscriptionNumber, error },
  };
}

/*!
 * Async thunks
 */
export function fetchProductInstances() {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();

    // While we would like to have our API combine both products and subscriptions
    // for those products into a single call, Subscription objects are really, really
    // large. Hence, the backing API streams the results from the upstream provider,
    // while product mappings are both small and cached locally in the portal backend.
    //
    // Because of the different access pattern, we combine the results in the front-end
    // code here.
    try {
      const [products, subscriptions] = await Promise.all([
        fetch(`/api/v1/accounts/${selectedAccount.id}/products`, {
          credentials: 'include',
        }).then(handleAPI),
        fetch(`/api/v1/accounts/${selectedAccount.id}/subscriptions`, {
          credentials: 'include',
        }).then(handleAPI),
      ]);

      products.forEach((p) => {
        p.subscription = subscriptions.find(
          (sub) => sub.subscriptionNumber === p.subscriptionNumber
        );
        dispatch(setProductInstance(p));
      });
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

export function fetchOneProductInstance({
  subscriptionNumber,
  checkSwitchState = false,
  checkSubscriptionState = false,
}) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    try {
      const promises = [
        fetch(
          `/api/v1/accounts/${selectedAccount.id}/subscriptions/${subscriptionNumber}/product?checkSwitchState=${checkSwitchState}`,
          { credentials: 'include' }
        ).then(handleAPI),
      ];

      if (checkSubscriptionState) {
        promises.push(
          fetch(`/api/v1/accounts/${selectedAccount.id}/subscriptions/${subscriptionNumber}`, {
            credentials: 'include',
          })
            .then(handleAPI)
            .catch((err) => {
              dispatch(subscriptionError(subscriptionNumber, err));
            })
        );
      }

      const results = await Promise.all(promises);
      const product = results[0];
      if (results.length === 2) {
        product.subscription = results[1];
      }

      dispatch(setProductInstance(product));
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

export function updateProductSubscription({ subscriptionNumber, updateBody }) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();

    try {
      await fetch(`/api/v1/accounts/${selectedAccount.id}/subscriptions/${subscriptionNumber}`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(updateBody),
      })
        .then(handleAPI)
        .catch((err) => {
          dispatch(subscriptionError(subscriptionNumber, err));
        });
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    return dispatch(fetchOneProductInstance({ subscriptionNumber, checkSubscriptionState: true }));
  };
}

export function updateTrunk({ accountId, subscriptionNumber, trunkId, trunkUpdateBody }) {
  return async (dispatch) => {
    try {
      await fetch(`/api/v1/accounts/${accountId}/trunks/${trunkId}`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(trunkUpdateBody),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    const checkSwitchState = trunkUpdateBody.middleOut;
    return dispatch(fetchOneProductInstance({ subscriptionNumber, checkSwitchState }));
  };
}

export function deleteTrunk({ subscriptionNumber, reason }) {
  return async (dispatch) => {
    try {
      await fetch(`/api/v1/subscriptions/${subscriptionNumber}/trunk`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ reason }),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    return dispatch(fetchOneProductInstance({ subscriptionNumber }));
  };
}

export function disableTrunk({ subscriptionNumber, reason }) {
  return async (dispatch) => {
    try {
      await fetch(`/api/v1/subscriptions/${subscriptionNumber}/trunk/disable`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ reason }),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    return dispatch(fetchOneProductInstance({ subscriptionNumber }));
  };
}

export function enableTrunk({ subscriptionNumber, reason }) {
  return async (dispatch) => {
    try {
      await fetch(`/api/v1/subscriptions/${subscriptionNumber}/trunk/enable`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ reason }),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    return dispatch(fetchOneProductInstance({ subscriptionNumber }));
  };
}

export function addDidsToTrunk(trunkId, { numbers, reason, middleOut = false }) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();

    try {
      await fetch(`/api/v1/accounts/${selectedAccount.id}/trunks/${trunkId}/numbers`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          numbers,
          middleOut, // they are already on the switch presumably!
          reason,
        }),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
  };
}

export function importTrunk({ subscriptionNumber, dmsTrunkBody }) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();

    dmsTrunkBody.customerId = selectedAccount.zuoraAccount.sfAccountId;
    try {
      await fetch('/api/v1/trunks/', {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(dmsTrunkBody),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    return dispatch(fetchOneProductInstance({ subscriptionNumber }));
  };
}

export function setDefaultE911Number(number, { subscriptionNumber }, { trunkId }) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    try {
      await fetch(`/api/v1/accounts/${selectedAccount.id}/numbers/${number}/makeDefaultE911`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          trunkId,
          reason: 'Reconciliation',
        }),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    return dispatch(fetchOneProductInstance({ subscriptionNumber }));
  };
}

/**
 * Bulk update metadata for phone numbres in the DID Manager, then refetch the product.
 * @param {Array<DID>} numbers
 * @param {String} subscriptionNumber
 * @param {Boolean} middleOut
 * @param {String} reason
 * @return {Promise<ProductInstance>}
 */
export function updateDIDNumbers(
  numbers,
  { subscriptionNumber },
  { middleOut, reason = 'Reconciliation' }
) {
  return async (dispatch, getState) => {
    const { selectedAccount } = getState();
    try {
      await fetch(`/api/v1/accounts/${selectedAccount.id}/numbers`, {
        credentials: 'include',
        method: 'PUT',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          numbers,
          middleOut,
          reason,
        }),
      }).then(handleAPI);
    } catch (err) {
      notifyError(dispatch)(err);
      throw err;
    }
    return dispatch(fetchOneProductInstance({ subscriptionNumber }));
  };
}
